import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent } from '../../../components/ui/Card';
import { Button } from '../../../components/ui/Button';
import { CalendarIcon, ClockIcon, UserIcon } from 'lucide-react';
import { BlogPost } from '../types';

interface BlogCardProps {
    post: BlogPost;
}

export const BlogCard: React.FC<BlogCardProps> = ({ post }) => {
    return (
        <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
            <div className="aspect-video relative overflow-hidden">
                <img 
                    src={post.imageUrl} 
                    alt={post.title}
                    className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                />
            </div>
            <CardContent className="p-6">
                <div className="flex items-center gap-4 text-sm text-gray-600 mb-3">
                    <div className="flex items-center gap-1">
                        <CalendarIcon className="w-4 h-4" />
                        <span>{new Date(post.date).toLocaleDateString()}</span>
                    </div>
                    <div className="flex items-center gap-1">
                        <ClockIcon className="w-4 h-4" />
                        <span>{post.readTime}</span>
                    </div>
                </div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">{post.title}</h3>
                <div className="flex items-center gap-2 mb-3">
                    <UserIcon className="w-4 h-4 text-gray-600" />
                    <span className="text-sm text-gray-600">{post.author}</span>
                </div>
                <p className="text-gray-600 mb-4">{post.summary}</p>
                <Button 
                    as={Link} 
                    to={`/blog/${post.slug}`} 
                    variant="outline"
                    className="w-full justify-center"
                >
                    Read More
                </Button>
            </CardContent>
        </Card>
    );
};
