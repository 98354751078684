import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader } from "../../../components/ui/Card";
import { format } from 'date-fns';
import { collection, query, where, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Event } from '../../../types/Event';
import { Badge } from '../../../components/ui/Badge';
import { Skeleton } from '../../../components/ui/Skeleton';
import classNames from 'classnames';

interface Message {
  id: string;
  content: string;
  type: string;
  urgency: 'normal' | 'urgent';
  createdAt: Date;
  senderId: string;
}

interface EventMessagesProps {
  event: Event;
  highlightedMessageId?: string;
}

export const EventMessages: React.FC<EventMessagesProps> = ({ event, highlightedMessageId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesRef = collection(db, 'events', event.id, 'messages');
        const q = query(
          messagesRef,
          where('type', '==', 'UPDATE'),
          orderBy('createdAt', 'desc')
        );
        
        const querySnapshot = await getDocs(q);
        const fetchedMessages = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate()
        })) as Message[];
        
        setMessages(fetchedMessages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, [event.id]);

  if (loading) {
    return (
      <div className="space-y-4">
        <Skeleton className="h-32 w-full" />
        <Skeleton className="h-32 w-full" />
        <Skeleton className="h-32 w-full" />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {messages.map((message) => (
        <Card 
          key={message.id}
          className={classNames(
            "transition-all duration-300",
            { "ring-2 ring-purple-500 shadow-lg": message.id === highlightedMessageId }
          )}
        >
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <div className="flex items-center space-x-2">
              <Badge variant={message.urgency === 'urgent' ? 'destructive' : 'default'}>
                {message.urgency === 'urgent' ? 'Urgent Update' : 'Update'}
              </Badge>
              <span className="text-sm text-muted-foreground">
                {format(message.createdAt, 'PPp')}
              </span>
            </div>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-gray-700 whitespace-pre-wrap">{message.content}</p>
          </CardContent>
        </Card>
      ))}
      {messages.length === 0 && (
        <div className="text-center py-8 text-muted-foreground">
          No updates have been sent for this event yet.
        </div>
      )}
    </div>
  );
};
