import React from 'react';
import { BookmarkIcon, AlertCircle, Search, X } from 'lucide-react';
import { Input } from "../Input";
import { Button } from "../Button";
import { DateDialog } from './DateDialog';
import { MultiSelectDialog } from './MultiSelectDialog';
import { EnhancedFilterBadge } from './EnhancedFilterBadge';
import { useSearchBarLogic } from './useSearchBarLogic';
import { createSearchBarHandlers } from './searchBarHandlers';
import { CATEGORIES, CITIES } from './constants';
import { formatDateRange } from '../../../utils/dateUtils';
import { SearchState, DialogState, SetSearchState, SetDialogState } from './types';
import { useSearchBarSpacing } from './hooks/useSearchBarSpacing';

export const SearchBar: React.FC = () => {
  const spacing = useSearchBarSpacing();
  const {
    state,
    setState,
    dialogState,
    setDialogState,
    cityLoading,
    cityError,
    city,
    tempLocations,
    setTempLocations,
    tempCategories,
    setTempCategories,
    setUserInteractedWithLocation,
    setUserInteractedWithCategory,
  } = useSearchBarLogic();

  const handlers = createSearchBarHandlers(
    setState as SetSearchState,
    setDialogState as SetDialogState,
    setUserInteractedWithLocation,
    setUserInteractedWithCategory,
    setTempLocations,
    setTempCategories,
    tempLocations,
    tempCategories
  );

  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className={`w-full mx-auto ${!isExpanded ? 'max-w-2xl px-4' : ''}`} 
         style={{ maxWidth: isExpanded ? spacing.container.maxWidth : undefined }}>
      <div className={`w-full bg-white shadow-lg border border-purple-100 transition-all duration-300 ease-in-out overflow-hidden`}
           style={{ 
             borderRadius: '16px',
             padding: isExpanded ? spacing.container.padding : '8px 16px',
             maxHeight: isExpanded ? '1000px' : `calc(${spacing.searchInput.height} + 16px)`,
           }}>
        <form onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}>
          {/* Search Bar Section */}
          <div className={`flex items-center ${isExpanded ? 'mb-6' : 'mb-0'}`}>
            <div className="relative flex-1 group">
              <Input
                type="text"
                placeholder="Search events..."
                value={state.searchTerm}
                onChange={handlers.handleSearchTermChange}
                onFocus={() => setIsExpanded(true)}
                onBlur={(e) => {
                  // Only collapse if we're not clicking within the search bar container
                  const container = e.currentTarget.closest('form');
                  const relatedTarget = e.relatedTarget as HTMLElement;
                  if (!container?.contains(relatedTarget)) {
                    setIsExpanded(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                style={{
                  height: spacing.searchInput.height,
                  fontSize: spacing.searchInput.fontSize,
                  paddingLeft: spacing.searchInput.paddingLeft,
                  paddingRight: spacing.searchInput.paddingRight,
                }}
                className="w-full border-2 border-purple-200 rounded-xl
                         focus:border-purple-500 focus:ring-2 focus:ring-purple-200/50
                         hover:border-purple-300 transition-all duration-300"
              />
              <Search 
                style={{ 
                  width: spacing.searchInput.iconSize,
                  height: spacing.searchInput.iconSize,
                }}
                className="absolute left-4 top-1/2 -translate-y-1/2 
                         text-purple-400 group-hover:text-purple-500 transition-colors duration-300" 
              />
              {state.searchTerm && (
                <button
                  onClick={handlers.handleClearSearch}
                  type="button"
                  style={{
                    width: spacing.clearButton.size,
                    height: spacing.clearButton.size,
                    right: spacing.clearButton.offset,
                  }}
                  className="absolute top-1/2 -translate-y-1/2 text-gray-400 
                           hover:text-purple-600 transition-colors duration-300
                           focus:outline-none focus:text-purple-500 p-2"
                >
                  <X className="w-full h-full" />
                </button>
              )}
            </div>
          </div>

          {/* Filter Badges Section */}
          <div className={`transition-opacity duration-300 ${isExpanded ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
            {/* Filter Badges */}
            <div className="flex flex-wrap gap-2" 
                 style={{ 
                   gap: spacing.filterBadges.gap,
                   marginTop: spacing.filterBadges.marginTop 
                 }}>
              <EnhancedFilterBadge
                spacing={spacing.filterBadges}
                label="Bookmarked"
                value={state.showBookmarked ? 'Only Bookmarked' : ''}
                onClick={handlers.handleBookmarkToggle}
                onClear={() => setState(prev => ({ ...prev, showBookmarked: false }))}
                isActive={state.showBookmarked}
              />
              <EnhancedFilterBadge
                spacing={spacing.filterBadges}
                label="Date"
                value={state.dateRange ? formatDateRange(state.dateRange) : ''}
                onClick={() => setDialogState(prev => ({ ...prev, isDateDialogOpen: true }))}
                onClear={() => handlers.clearFilter('date')}
                isActive={!!state.dateRange}
              />
              <EnhancedFilterBadge
                spacing={spacing.filterBadges}
                label="Category"
                value={state.selectedCategories.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isCategoryDialogOpen: true }))}
                onClear={() => handlers.clearFilter('category')}
                isActive={state.selectedCategories.length > 0}
              />
              <EnhancedFilterBadge
                spacing={spacing.filterBadges}
                label="Price"
                value={state.selectedPrices.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isPriceDialogOpen: true }))}
                onClear={() => handlers.clearFilter('price')}
                isActive={state.selectedPrices.length > 0}
              />
              <EnhancedFilterBadge
                spacing={spacing.filterBadges}
                label="Location"
                value={state.selectedLocations.join(', ')}
                onClick={() => setDialogState(prev => ({ ...prev, isLocationDialogOpen: true }))}
                onClear={() => handlers.clearFilter('location')}
                isActive={state.selectedLocations.length > 0}
                loading={cityLoading}
                error={cityError}
              />
            </div>
  
            {/* Error Message */}
            {cityError && (
              <div className="text-red-500 flex items-center mb-4">
                <AlertCircle className="w-5 h-5 mr-2" />
                <span>{cityError}</span>
              </div>
            )}
  
            {/* Dialogs */}
            <DateDialog
              isOpen={dialogState.isDateDialogOpen}
              onOpenChange={(open) => setDialogState(prev => ({ ...prev, isDateDialogOpen: open }))}
              onDateSelect={handlers.handleDateSelect}
            />
            <MultiSelectDialog
              isOpen={dialogState.isCategoryDialogOpen}
              onOpenChange={(open) => {
                if (!open) {
                  handlers.handleCategoryDialogClose(false);
                } else {
                  setDialogState(prev => ({ ...prev, isCategoryDialogOpen: open }));
                  setTempCategories(state.selectedCategories);
                }
              }}
              title="Select Categories"
              options={CATEGORIES}
              selectedValues={tempCategories}
              onChange={handlers.handleCategoryChange}
              onConfirm={() => handlers.handleCategoryDialogClose(true)}
            />
            <MultiSelectDialog
              isOpen={dialogState.isPriceDialogOpen}
              onOpenChange={(open) => setDialogState(prev => ({ ...prev, isPriceDialogOpen: open }))}
              title="Select Price Ranges"
              options={['free', 'paid']}
              selectedValues={state.selectedPrices}
              onChange={(values) => {
                setState(prev => ({ ...prev, selectedPrices: values }));
              }}
            />
            <MultiSelectDialog
              isOpen={dialogState.isLocationDialogOpen}
              onOpenChange={(open) => {
                if (!open) {
                  handlers.handleLocationDialogClose(false);
                } else {
                  setDialogState(prev => ({ ...prev, isLocationDialogOpen: open }));
                  setTempLocations(state.selectedLocations);
                }
              }}
              title="Select Locations"
              options={CITIES}
              selectedValues={tempLocations}
              onChange={handlers.handleLocationChange}
              defaultValue={city || undefined}
              onConfirm={() => handlers.handleLocationDialogClose(true)}
              error={cityError}
            />
          </div>
        </form>
      </div>
    </div>
  );
};