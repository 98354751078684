import { Card, CardContent, CardFooter } from "../../../components/ui/Card";


// Skeleton loader components
export const SkeletonLoader = () => (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-3xl mx-auto overflow-hidden" data-testid="event-skeleton">
        {/* Image skeleton */}
        <div className="w-full h-64 bg-gray-200 animate-pulse" />
        
        {/* Title skeleton */}
        <div className="p-6">
          <div className="h-8 w-3/4 bg-gray-200 rounded animate-pulse mb-4" />
        </div>
  
        {/* Badges skeleton */}
        <div className="bg-purple-50 p-4 flex justify-center space-x-4">
          <div className="h-6 w-20 bg-gray-200 rounded animate-pulse" />
          <div className="h-6 w-20 bg-gray-200 rounded animate-pulse" />
        </div>
  
        <CardContent className="p-6 space-y-6">
          {/* Location and Date Info skeleton */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="space-y-2">
              <div className="h-4 w-1/4 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="space-y-2">
              <div className="h-4 w-1/4 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
  
          {/* Description and Attendees skeleton */}
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex-grow space-y-2">
              <div className="h-4 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="w-full md:w-1/3 space-y-2">
              <div className="h-4 w-1/2 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-3/4 bg-gray-200 rounded animate-pulse" />
            </div>
          </div>
        </CardContent>
  
        {/* Footer skeleton */}
        <CardFooter className="bg-purple-50 p-6 flex justify-end">
          <div className="h-10 w-32 bg-gray-200 rounded animate-pulse" />
        </CardFooter>
      </Card>
    </div>
  );