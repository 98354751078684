import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/Alert';
import { Label } from '../../components/ui/Label';
import { Loader2, CheckCircle2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { verificationService } from '../../services/verificationService';

const VerificationPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [aadharNumber, setAadharNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [otpSent, setOtpSent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleRequestOTP = async () => {
    if (!user) return;
    setLoading(true);
    setError(null);
    
    try {
      const result = await verificationService.requestAadharOTP({
        aadharNumber,
        name: user.displayName || ''
      });
      
      if (result.success && result.requestId) {
        setRequestId(result.requestId);
        setOtpSent(true);
        setSuccess('OTP sent successfully');
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to send OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    if (!user || !requestId) return;
    setLoading(true);
    setError(null);
    
    try {
      const result = await verificationService.verifyAadharOTP({
        requestId,
        otp,
        userId: user.uid
      });
      
      if (result.success) {
        setSuccess('Aadhar verification successful!');
        setIsVerified(true);
        // Redirect after 2 seconds
        setTimeout(() => {
          navigate('/organize');
        }, 2000);
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('Failed to verify OTP. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (isVerified) {
    return (
      <div className="min-h-screen bg-purple-50 py-12 px-4 flex items-center justify-center">
        <div className="text-center">
          <CheckCircle2 className="w-24 h-24 text-green-500 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-green-700 mb-2">Verification Successful!</h2>
          <p className="text-gray-600">Redirecting to organizer dashboard...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-purple-50 py-12 px-4">
      <div className="max-w-2xl mx-auto">
        <Card>
          <CardHeader className="text-center border-b border-gray-100 bg-gradient-to-r from-purple-50 to-pink-50">
            <CardTitle className="text-2xl font-bold text-purple-800 mb-2">
              AADHAR OKYC Verification
            </CardTitle>
            <CardDescription className="text-gray-600">
              Complete your AADHAR verification to unlock organizer features
            </CardDescription>
          </CardHeader>

          <CardContent className="pt-6">
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            {success && !isVerified && (
              <Alert variant="default" className="mb-4">
                <AlertTitle>Success</AlertTitle>
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}

            <div className="space-y-6">
              {!otpSent ? (
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label>Aadhar Number</Label>
                    <Input
                      type="text"
                      value={aadharNumber}
                      onChange={(e) => setAadharNumber(e.target.value)}
                      placeholder="Enter your 12-digit Aadhar number"
                      disabled={loading}
                    />
                  </div>
                  <Button
                    className="w-full"
                    onClick={handleRequestOTP}
                    disabled={loading || !aadharNumber}
                  >
                    {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                    Request OTP
                  </Button>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="space-y-2">
                    <Label>Enter OTP</Label>
                    <Input
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter the OTP sent to your Aadhar linked number"
                      disabled={loading}
                    />
                  </div>
                  <Button
                    className="w-full"
                    onClick={handleVerifyOTP}
                    disabled={loading || !otp}
                  >
                    {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                    Verify OTP
                  </Button>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default VerificationPage;
