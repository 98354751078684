import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event, SkillLevel } from '../../types/Event';
import { useAuth } from '../../contexts/AuthContext';
import { isVerifiedOrganizer } from '../../types/User';
import { Button } from "../../components/ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytesResumable, getDownloadURL, uploadString } from 'firebase/storage';
import { storage } from '../../config/firebase';
import { FirebaseError } from 'firebase/app';
import { Timestamp, addDoc, collection, updateDoc, doc, arrayUnion, setDoc } from 'firebase/firestore';
import { CalendarService } from '../../services/calendarService';
import { db } from '../../config/firebase';

import { EventBasicInfo } from '../EventBasicInfo';
import { EventTypeSelection } from '../EventTypeSelection';
import { EventVisibilitySelection } from '../EventVisibilitySelection';
import { EventLocationInput } from '../EventLocationInput';
import { EventLinkInput } from '../common/EventLinkInput'; // Import the new component
import { EventDurationSelection } from '../EventDurationSelection';
import { EventDateTimeInputs } from '../EventDateTimeInputs';
import { EventFrequencySelection } from '../EventFrequencySelection';
import { EventPriceSelection } from '../EventPriceSelection';
import { EventCategorySelection } from '../EventCategorySelection';
import { EventImageUpload } from '../EventImageUpload';
import { useEventValidation } from '../../hooks/useEventValidation';
import { useModalMessage } from '../../hooks/useModalMessage';
import { ConfirmationModal } from '../common/ConfirmationModal';
import { EventDailySchedules } from '../EventDailySchedules';

// Update Event type to include googleCalendarEventId
type UpdatedEvent = Event & { googleCalendarEventId?: string; imageData?: string };

export const CreateEventForm: React.FC = () => {
  const [event, setEvent] = useState<Partial<UpdatedEvent>>({
    title: '',
    description: '',
    isOnline: false,
    isSingleDay: true,
    isRepeatedEvent: false,
    isFree: true,
    category: 'Entertainment',
    isPublic: true,
    date: null,
    startTime: '',
    endTime: '',
    status: 'upcoming',
    attendeeCount: 0,
    attendees: [],
    organizerId: '',
    tags: [],
    categories: [],
    maxCapacity: 100,
    feedback: [],
    imageData: undefined, // Add this line
    dailySchedules: [], // Add this line to initialize dailySchedules
    price: 0,
    registrationTypes: [], // Initialize registration types array
    stats: { totalEarnings: 0, platformEarnings: 0, transactionCount: 0 }
  });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCalendarAuthenticated, setIsCalendarAuthenticated] = useState(false);
  const [isAuthenticatingCalendar, setIsAuthenticatingCalendar] = useState(false);
  const [showRetryAuth, setShowRetryAuth] = useState(false);
  const { user, customUser } = useAuth();
  const navigate = useNavigate();
  const { validateEvent } = useEventValidation();
  const { modalMessage, isModalOpen, showModal, closeModal } = useModalMessage();

  const handleChange = (field: string, value: any) => {
    if (field === 'imageFile') {
      setImageFile(value);
    } else {
      setEvent((prevEvent) => ({ ...prevEvent, [field]: value }));
    }
  };

  const handleImageUpload = (fileOrData: File | string) => {
    if (typeof fileOrData === 'string') {
      // Handle base64 image data
      setEvent(prevEvent => ({ ...prevEvent, imageData: fileOrData }));
    } else {
      // Handle File object
      setImageFile(fileOrData);
    }
  };

  const authenticateCalendar = async () => {
    try {
      setIsAuthenticatingCalendar(true);
      setShowRetryAuth(false);
      await CalendarService.getAccessToken(true); // Force new token
      setIsCalendarAuthenticated(true);
      return true;
    } catch (error: unknown) {
      console.error('Calendar authentication failed:', error);
      
      // Type guard for Firebase Auth Error
      const isFirebaseError = (err: unknown): err is { code: string } => {
        return typeof err === 'object' && err !== null && 'code' in err;
      };

      setShowRetryAuth(true);
      
      if (isFirebaseError(error)) {
        if (error.code === 'auth/popup-closed-by-user') {
          showModal('Calendar authentication was cancelled. Calendar integration is required to create an event. Please click "Retry Authentication" to try again.');
        } else if (error.code === 'auth/popup-blocked') {
          showModal('Pop-up was blocked by your browser. Please allow pop-ups for this site and click "Retry Authentication".');
        } else {
          showModal('Calendar authentication failed. Please try again.');
        }
      } else {
        showModal('Calendar authentication failed. Please try again.');
      }
      return false;
    } finally {
      setIsAuthenticatingCalendar(false);
    }
  };

  const handleRetryAuth = async (e: React.MouseEvent) => {
    e.preventDefault();
    await authenticateCalendar();
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Check if organizer is verified for paid events
    if (!event.isFree && !isVerifiedOrganizer(customUser)) {
      showModal('You must be a verified organizer to create paid events. Please complete the verification process first.');
      return;
    }

    try {
      setIsSubmitting(true);
      if (!user) {
        console.log('User not logged in');
        showModal('You must be logged in to create an event.');
        setIsSubmitting(false);
        return;
      }

      // Validate the event data
      const validationErrors = validateEvent(event);
      if (validationErrors.length > 0) {
        // Get the first error message to show to the user
        const firstError = validationErrors[0];
        let errorMessage = firstError.message;
        
        // Add field context to the error message if needed
        if (firstError.field.startsWith('registrationTypes[')) {
          errorMessage = `Registration Type: ${errorMessage}`;
        }
        
        showModal(errorMessage);
        setIsSubmitting(false);
        return;
      }

      // Require calendar authentication before proceeding
      if (!isCalendarAuthenticated) {
        const authenticated = await authenticateCalendar();
        if (!authenticated) {
          setIsSubmitting(false);
          return;
        }
      }

      console.log('Starting event creation process');
      const eventId = uuidv4();
      console.log('Generated eventId:', eventId);

      let imageUrl = '';
      if (imageFile) {
        console.log('Uploading image file');
        imageUrl = await uploadImage(imageFile);
        console.log('Image uploaded successfully, URL:', imageUrl);
      } else if (event.imageData) {
        console.log('Uploading base64 image data');
        imageUrl = await uploadImage(event.imageData);
        console.log('Base64 image uploaded successfully, URL:', imageUrl);
      }

      const now = Timestamp.now();
      console.log('Current timestamp:', now.toDate());

      const newEvent: Partial<UpdatedEvent> = {
        id: eventId,
        urlFriendlyId: eventId,
        title: event.title || '',
        description: event.description || '',
        isOnline: event.isOnline || false,
        isSingleDay: event.isSingleDay ?? true,
        isRepeatedEvent: event.isRepeatedEvent || false,
        isFree: event.isFree ?? (event.price === 0 || !event.price),
        category: event.category || 'Entertainment',
        isPublic: event.isPublic,
        status: 'upcoming',
        attendeeCount: 0,
        attendees: [],
        organizerId: user.uid,
        createdAt: now,
        updatedAt: now,
        tags: [],
        categories: [],
        maxCapacity: event.maxCapacity || 100,
        feedback: [],
        imageUrl,
        isCancelled: false,
        startTime: event.startTime || now.toDate().toISOString(),
        endTime: event.endTime || now.toDate().toISOString(),
        dailySchedules: event.dailySchedules || [],
        date: event.isSingleDay ? (event.date || now) : undefined,
        fromDate: !event.isSingleDay ? (event.fromDate || now) : undefined,
        toDate: !event.isSingleDay ? (event.toDate || now) : undefined,
        price: event.isFree ? 0 : Number(event.price || 0),
        registrationTypes: event.registrationTypes || [], // Add registration types
        stats: { totalEarnings: 0, platformEarnings: 0, transactionCount: 0 }
      };

      if (event.location) newEvent.location = event.location;
      if (event.locationLink) newEvent.locationLink = event.locationLink;
      if (event.fromDate) newEvent.fromDate = event.fromDate;
      if (event.toDate) newEvent.toDate = event.toDate;
      if (event.repeatedDaysOfWeek) newEvent.repeatedDaysOfWeek = event.repeatedDaysOfWeek;
      if (event.price) newEvent.price = event.price;

      console.log('New event data:', JSON.stringify({
        ...newEvent,
        createdAt: newEvent.createdAt?.toDate(),
        updatedAt: newEvent.updatedAt?.toDate(),
        date: newEvent.date?.toDate(),
        fromDate: newEvent.fromDate?.toDate(),
        toDate: newEvent.toDate?.toDate(),
      }, null, 2));

      console.log('Creating Google Calendar event');
      try {
        const calendarResult = await CalendarService.createEvent({
          title: newEvent.title || '',
          description: newEvent.description || '',
          startTime: newEvent.isSingleDay 
            ? combineDateTime(newEvent.date?.toDate() || new Date(), newEvent.startTime || '00:00')
            : combineDateTime(newEvent.fromDate?.toDate() || new Date(), newEvent.startTime || '00:00'),
          endTime: newEvent.isSingleDay 
            ? combineDateTime(newEvent.date?.toDate() || new Date(), newEvent.endTime || '23:59')
            : combineDateTime(newEvent.toDate?.toDate() || new Date(), newEvent.endTime || '23:59'),
          attendees: [], // Add attendees if needed
        });
        
        console.log("Google Calendar event created:", calendarResult);

        if (calendarResult.meetLink) {
          newEvent.meetingLink = calendarResult.meetLink;
          console.log('Meet link added to event:', calendarResult.meetLink);
        }
        if (calendarResult.eventId) {
          newEvent.googleCalendarEventId = calendarResult.eventId;
          console.log('Google Calendar Event ID added to event:', calendarResult.eventId);
        }
      } catch (error) {
        console.error('Error creating Google Calendar event:', error);
        // Continue with Firestore save even if Calendar creation fails
        showModal('Event will be created without Google Calendar integration.');
      }

      const eventToAdd: Partial<UpdatedEvent> = Object.entries(newEvent).reduce((acc, [key, value]) => {
        if (value !== undefined) {
          (acc as any)[key] = value;
        }
        return acc;
      }, {} as Partial<UpdatedEvent>);
      console.log('Final event object to be added to Firestore:', eventToAdd);

      try {
        console.log('Adding event to Firestore');
        const docRef = doc(db, 'events', eventId);
        console.log('Document reference created:', docRef.path);

        await setDoc(docRef, eventToAdd);
        console.log('Event added successfully to Firestore');
        
        console.log('Updating user document');
        await updateDoc(doc(db, 'users', user.uid), {
          eventsCreated: arrayUnion(eventId)
        });
        console.log('User document updated successfully');

        showModal('Event created successfully!');
        navigate('/organizer/dashboard');
      } catch (error) {
        console.error('Error adding event to Firestore:', error);
        if (error instanceof FirebaseError) {
          console.error('Firebase error code:', error.code);
          console.error('Firebase error message:', error.message);
          showModal(`Failed to save event: ${error.message}`);
        } else {
          console.error('Non-Firebase error:', error);
          showModal('An unexpected error occurred while saving the event. Please try again.');
        }
        throw error;
      }
    } catch (error) {
      console.error("Detailed error in onSubmit:", error);
      if (error instanceof FirebaseError) {
        console.error("Firebase error details:", error.code, error.message);
        showModal(`Failed to create event: ${error.message}`);
      } else if (error instanceof Error) {
        showModal(`Failed to create event: ${error.message}`);
      } else {
        showModal('Failed to create event. Please try again.');
      }
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const uploadImage = async (fileOrData: File | string): Promise<string> => {
    const uniqueId = uuidv4();
    const storageRef = ref(storage, `images/${uniqueId}.jpg`);
    
    if (typeof fileOrData === 'string') {
      // It's base64 data
      await uploadString(storageRef, fileOrData, 'base64', { contentType: 'image/jpeg' });
    } else {
      // It's a File object
      await uploadBytesResumable(storageRef, fileOrData);
    }
    
    return getDownloadURL(storageRef);
  };

  const combineDateTime = (date: Date, time: string): string => {
    const [hours, minutes] = time.split(':').map(Number);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date.toISOString();
  };

  return (
    <Card className="max-w-2xl mx-auto bg-purple-50">
      <CardHeader>
        <CardTitle className="text-2xl font-bold text-center text-purple-800">Create New Event</CardTitle>
      </CardHeader>
      <CardContent>
        <form 
          onSubmit={onSubmit} 
          className="space-y-8" 
          role="form"
          data-testid="event-form"
        >
          <div data-testid="event-form-state" style={{ display: 'none' }}>
            {event.date ? event.date.toDate().toISOString().split('T')[0] : ''}
          </div>
          <EventBasicInfo event={event} onChange={handleChange} />
          <EventTypeSelection event={event} onChange={handleChange} />
          <EventVisibilitySelection event={event} onChange={handleChange} />
          {event.isOnline ? (
            <EventLinkInput event={event} onChange={handleChange} />
          ) : (
            <EventLocationInput event={event} onChange={handleChange} />
          )}
          <EventDurationSelection event={event} onChange={handleChange} />
          <EventDateTimeInputs event={event} onChange={handleChange} />
          <EventDailySchedules event={event} onChange={handleChange} />
          <EventFrequencySelection event={event} onChange={handleChange} />
          <EventPriceSelection event={event} onChange={handleChange} />
          <EventCategorySelection event={event} onChange={handleChange} />
          <EventImageUpload 
            onUpload={handleImageUpload} 
            eventTitle={event.title || ''} 
            eventDescription={event.description || ''}
          />

          <div className="flex flex-col space-y-4">
            {showRetryAuth && (
              <button
                type="button"
                onClick={handleRetryAuth}
                className="w-full px-4 py-2 bg-yellow-600 text-white rounded-md hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 disabled:opacity-50"
                disabled={isAuthenticatingCalendar}
              >
                {isAuthenticatingCalendar ? 'Authenticating...' : 'Retry Authentication'}
              </button>
            )}
            <Button 
              type="submit" 
              className="w-full bg-purple-600 hover:bg-purple-700 text-white" 
              loading={loading || isSubmitting || isAuthenticatingCalendar}
              loadingText={isAuthenticatingCalendar ? 'Waiting for Calendar Authentication...' : 
               isSubmitting ? 'Creating Event...' : 'Create Event'}
              disabled={isSubmitting || isAuthenticatingCalendar}
            >
              {isAuthenticatingCalendar ? 'Waiting for Calendar Authentication...' : 
               isSubmitting ? 'Creating Event...' : 'Create Event'}
            </Button>
          </div>
        </form>
      </CardContent>
      {isModalOpen && modalMessage && <ConfirmationModal message={modalMessage} onClose={closeModal} />}
    </Card>
  );
};
