import * as React from "react"
import { DayPicker } from "react-day-picker"
import "react-day-picker/dist/style.css"

type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  className?: string;
}

export function Calendar({
  className = "",
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      className={`p-3 ${className}`}
      modifiersClassNames={{
        selected: "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        disabled: "text-muted-foreground opacity-50",
      }}
      {...props}
    />
  )
}
