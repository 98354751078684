import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "../components/ui/Card";
import { Avatar, AvatarImage } from "../components/ui/Avatar";
import { Button } from "../components/ui/Button";
import { Input } from "../components/ui/Input";
import { Label } from "../components/ui/Label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../components/ui/Select";
import { Switch } from "../components/ui/Switch";
import { CameraIcon, CheckCircleIcon } from "lucide-react";
import { auth, db, storage } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { User } from '../models/User';
import { Timestamp } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  gender: string;
  dateOfBirth: string;
  eventsAttended: string[];
  eventsCreated: string[];
  bankAccountDetails: {
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isVerified: boolean;
  };
  upiDetails: string;
  upiVerified: boolean;
  subscribers: string[];
  subscribedTo: string[];
  isOrganizer: boolean;
  profilePicture: string;
  phone: string;
  phoneVerified: boolean;
  createdAt?: Date;
  lastLogin?: Date;
  bookmarkedEvents: string[];
  razorpayContactCreated: boolean;
  razorpayContactId?: string;
}

export const EditProfilePage: React.FC = () => {
  const [editedUser, setEditedUser] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState('personal');
  const [paymentMethod, setPaymentMethod] = useState<'upi' | 'bank'>('upi');
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error("No authenticated user");
        }

        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        
        if (!userDocSnap.exists()) {
          throw new Error("No user document found");
        }

        const userData = userDocSnap.data();
        if (!userData) {
          throw new Error("User data is empty");
        }

        // Helper function to safely convert Firestore timestamp to Date
        const convertTimestamp = (timestamp: any): Date => {
          if (!timestamp) return new Date();
          if (timestamp.seconds) return new Date(timestamp.seconds * 1000);
          if (timestamp instanceof Date) return timestamp;
          return new Date(timestamp);
        };

        // Helper function to format date for input
        const formatDate = (date: Date): string => {
          return date.toISOString().split('T')[0];
        };

        const userProfile: UserProfile = {
          id: currentUser.uid,
          name: userData.name || currentUser.displayName || '',
          email: userData.email || currentUser.email || '',
          gender: userData.gender || '',
          dateOfBirth: userData.dateOfBirth ? formatDate(convertTimestamp(userData.dateOfBirth)) : '',
          eventsAttended: userData.eventsAttended || [],
          eventsCreated: userData.eventsCreated || [],
          bankAccountDetails: userData.bankAccountDetails || {
            accountNumber: '',
            bankName: '',
            ifscCode: '',
            isVerified: false,
          },
          upiDetails: userData.upiDetails || '',
          upiVerified: userData.upiVerified || false,
          subscribers: userData.subscribers || [],
          subscribedTo: userData.subscribedTo || [],
          isOrganizer: userData.isOrganizer || false,
          profilePicture: userData.profilePicture || '',
          phone: userData.phone || '',
          phoneVerified: userData.phoneVerified || false,
          createdAt: convertTimestamp(userData.createdAt),
          lastLogin: convertTimestamp(userData.lastLogin),
          bookmarkedEvents: userData.bookmarkedEvents || [],
          razorpayContactCreated: userData.razorpayContactCreated || false,
          razorpayContactId: userData.razorpayContactId || '',
        };

        setEditedUser(userProfile);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(error instanceof Error ? error.message : "Failed to load user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({ ...prev, [name]: value }) : null);
  };

  const handleBankDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, [name]: value }
    }) : null);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editedUser || !auth.currentUser) {
      console.error("No user data or not authenticated");
      return;
    }

    try {
      let profilePictureURL = editedUser.profilePicture;

      // If a new file was selected, upload it
      if (selectedFile) {
        try {
          profilePictureURL = await uploadProfilePicture(selectedFile);
        } catch (error) {
          console.error("Error uploading profile picture:", error);
          throw new Error("Failed to upload profile picture");
        }
      }

      // Create Razorpay contact if becoming an organizer for the first time
      if (editedUser.isOrganizer && !editedUser.razorpayContactCreated) {
        try {
          const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
          const result = await createContact();
          console.log('Razorpay contact created:', result.data);
        
          // Update the editedUser with Razorpay contact info
          editedUser.razorpayContactCreated = true;
          if (result.data && (result.data as any).contactId) {
            editedUser.razorpayContactId = (result.data as any).contactId;
          }
        } catch (error) {
          console.error("Error creating Razorpay contact:", error);
          throw new Error("Failed to create Razorpay contact");
        }
      }

      // Create User object and save
      try {
        const userToUpdate = new User(
          auth.currentUser.uid,
          editedUser.name || '',
          editedUser.email || '',
          editedUser.gender,
          new Date(editedUser.dateOfBirth),
          editedUser.eventsAttended,
          editedUser.eventsCreated,
          editedUser.bankAccountDetails,
          editedUser.upiDetails,
          editedUser.upiVerified,
          editedUser.subscribers,
          editedUser.subscribedTo || [],
          editedUser.isOrganizer,
          profilePictureURL,
          editedUser.phone,
          editedUser.phoneVerified,
          editedUser.createdAt instanceof Date ? editedUser.createdAt : new Date(editedUser.createdAt || Date.now()),
          new Date(),
          editedUser.bookmarkedEvents,
          editedUser.razorpayContactCreated,
          editedUser.razorpayContactId
        );

        await userToUpdate.save();
        console.log('User profile updated successfully');
        navigate('/profile');
      } catch (error) {
        console.error("Error saving user data:", error);
        throw new Error("Failed to save user data");
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
      // Here you would typically show an error message to the user
      // For example, using a toast notification or error state
      throw error;
    }
  };

  const handleVerifyUPI = () => {
    setEditedUser(prev => prev ? ({ ...prev, upiVerified: true }) : null);
  };

  const handleVerifyBank = () => {
    setEditedUser(prev => prev ? ({
      ...prev,
      bankAccountDetails: { ...prev.bankAccountDetails, isVerified: true }
    }) : null);
  };

  const handleGenderChange = (value: string) => {
    setEditedUser(prev => prev ? { ...prev, gender: value } : null);
  };

  const handlePictureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      // Show a preview of the selected image
      const reader = new FileReader();
      reader.onload = (e) => {
        setEditedUser(prev => prev ? { ...prev, profilePicture: e.target?.result as string } : null);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadProfilePicture = async (file: File): Promise<string> => {
    if (!auth.currentUser) throw new Error("User not authenticated");

    const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      
      return downloadURL;
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      throw error;
    }
  };

  const handleChangePictureClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent form submission
    fileInputRef.current?.click();
  };

  const handleOrganizerToggle = (checked: boolean) => {
    // Simply update the UI state, actual changes will be made on form submission
    setEditedUser(prev => prev ? { 
      ...prev, 
      isOrganizer: checked,
    } : null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg text-red-600">{error}</div>
      </div>
    );
  }

  if (!editedUser) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-lg text-red-600">No user data found</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-8">
      <Card>
        <CardHeader>
          <CardTitle>Edit Profile</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex space-x-4 mb-6">
            <Button
              variant={activeSection === 'personal' ? 'default' : 'outline'}
              onClick={() => setActiveSection('personal')}
            >
              Personal Information
            </Button>
            <Button
              variant={activeSection === 'account' ? 'default' : 'outline'}
              onClick={() => setActiveSection('account')}
            >
              Account Settings
            </Button>
            {editedUser.isOrganizer && (
              <Button
                variant={activeSection === 'financial' ? 'default' : 'outline'}
                onClick={() => setActiveSection('financial')}
              >
                Financial Information
              </Button>
            )}
          </div>

          <form onSubmit={handleSubmit}>
            {activeSection === 'personal' && (
              <div className="space-y-6">
                <div className="flex items-center space-x-4">
                  <Avatar className="w-24 h-24">
                    <AvatarImage src={editedUser.profilePicture} alt={editedUser.name} />
                  </Avatar>
                  <div>
                    <Label htmlFor="profilePicture">Profile Picture Upload</Label>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handlePictureChange}
                      accept="image/*"
                      className="hidden"
                      id="profilePicture"
                      aria-label="Profile Picture Upload"
                    />
                    <Button type="button" variant="outline" onClick={handleChangePictureClick}>
                      <CameraIcon className="w-4 h-4 mr-2" />
                      Change Picture
                    </Button>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="name">Name</Label>
                    <Input 
                      id="name"
                      name="name"
                      value={editedUser.name}
                      onChange={handleInputChange}
                      aria-label="Name"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="email">Email</Label>
                    <Input 
                      id="email"
                      name="email"
                      type="email"
                      value={editedUser.email}
                      onChange={handleInputChange}
                      aria-label="Email"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="phone">Phone</Label>
                    <Input 
                      id="phone"
                      name="phone"
                      value={editedUser.phone}
                      onChange={handleInputChange}
                      aria-label="Phone"
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="gender">Gender</Label>
                    <Select 
                      value={editedUser.gender} 
                      onValueChange={handleGenderChange}
                    >
                      <SelectTrigger id="gender">
                        <SelectValue placeholder="Select gender" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="male">Male</SelectItem>
                        <SelectItem value="female">Female</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="dateOfBirth">Date of Birth</Label>
                    <Input 
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      value={editedUser.dateOfBirth}
                      onChange={(e) => {
                        setEditedUser(prev => prev ? { ...prev, dateOfBirth: e.target.value } : null);
                      }}
                      aria-label="Date of Birth"
                    />
                  </div>
                </div>
              </div>
            )}

            {activeSection === 'account' && (
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Label htmlFor="organizer-toggle">I want to organize events</Label>
                  <Switch
                    checked={editedUser.isOrganizer}
                    onCheckedChange={handleOrganizerToggle}
                  />
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-end space-x-4">
              <Button type="button" variant="outline" onClick={() => navigate(-1)}>
                Cancel
              </Button>
              <Button type="submit">Save Changes</Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
