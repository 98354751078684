import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Card, CardContent, CardHeader } from "../../components/ui/Card";
import { useEventDetail } from '../../hooks/useEventDetail';
import { EventMessages } from './eventDetailPage/EventMessages';
import { Skeleton } from '../../components/ui/Skeleton';
import { useOrganizerDetails } from '../../hooks/useOrganizerDetails';

export const EventMessagesPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const messageId = searchParams.get('highlight');
  const { event, isLoading } = useEventDetail(id || '');
  const { organizer } = useOrganizerDetails(event?.organizerId);

  if (isLoading || !event) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Card className="max-w-3xl mx-auto">
          <CardHeader>
            <Skeleton className="h-8 w-3/4" />
          </CardHeader>
          <CardContent className="space-y-4">
            <Skeleton className="h-32 w-full" />
            <Skeleton className="h-32 w-full" />
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-3xl mx-auto">
        <CardHeader className="border-b">
          <div className="flex flex-col space-y-2">
            <h1 className="text-2xl font-bold text-purple-800">{event.title}</h1>
            {organizer && (
              <p className="text-gray-600">Organized by {organizer.name}</p>
            )}
          </div>
        </CardHeader>
        <CardContent className="pt-6">
          <h2 className="text-2xl font-semibold text-purple-800 mb-6">Event Updates</h2>
          <EventMessages event={event} highlightedMessageId={messageId || undefined} />
        </CardContent>
      </Card>
    </div>
  );
};
