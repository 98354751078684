import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ManageAttendeesPage } from './ManageAttendeesPage';
import { Event } from '../../models/Event';
import { Attendee } from '../../types/Attendee';
import { getEventAttendees } from '../../services/eventService';

export const ManageAttendeesPageWrapper: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [eventTitle, setEventTitle] = useState('');
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [event, setEvent] = useState<Event | null>(null);

  useEffect(() => {
    const fetchEventAndAttendees = async () => {
      if (id) {
        const eventData = await Event.findById(id);
        if (eventData) {
          setEvent(eventData);
          setEventTitle(eventData.title);
          const attendeesData = await getEventAttendees(eventData.id);
          setAttendees(attendeesData);
        }
      }
    };
    fetchEventAndAttendees();
  }, [id]);

  if (!event) {
    return null;
  }

  return (
    <ManageAttendeesPage 
      eventTitle={eventTitle} 
      attendees={attendees} 
      event={event}
      organizerId={event.organizerId || ''} 
    />
  );
};
