import React, { useState } from 'react';
import { useNotifications } from '../contexts/NotificationContext';
import { 
  CalendarIcon, 
  BellIcon, 
  CreditCardIcon, 
  UserPlusIcon, 
  MessageSquareIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
  ClockIcon,
  Loader2Icon
} from 'lucide-react';
import { Notification } from '../types/Notification';

interface NotificationsProps {
  onClose?: () => void;
}

export const Notifications: React.FC<NotificationsProps> = ({ onClose }) => {
  const { notifications, markAsRead, navigateToNotification } = useNotifications();
  const [loading, setLoading] = useState<string | null>(null);

  const handleNotificationClick = async (notification: Notification, e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!notification || !notification.id) return;

    try {
      setLoading(notification.id);

      // Mark as read if:
      // 1. isRead is missing or false
      // 2. markedAsUnread is missing or true
      // This ensures we handle both old and new notifications correctly
      if (
        !('isRead' in notification) || 
        !notification.isRead || 
        !('markedAsUnread' in notification) || 
        notification.markedAsUnread
      ) {
        await markAsRead(notification.id);
      }

      // Always navigate on click
      if (onClose) onClose();
      navigateToNotification(notification);
    } catch (error) {
      console.error("Error handling notification click:", error);
    } finally {
      setLoading(null);
    }
  };

  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'event_start':
      case 'event_reminder':
        return <ClockIcon className="w-5 h-5 text-purple-600" />;
      case 'event_update':
        return <CalendarIcon className="w-5 h-5 text-blue-600" />;
      case 'payment_confirmation':
        return <CreditCardIcon className="w-5 h-5 text-green-600" />;
      case 'new_event_invitation':
        return <UserPlusIcon className="w-5 h-5 text-indigo-600" />;
      case 'organizer_message':
        return <MessageSquareIcon className="w-5 h-5 text-yellow-600" />;
      case 'event_cancellation':
        return <AlertTriangleIcon className="w-5 h-5 text-red-600" />;
      case 'registration_confirmation':
        return <CheckCircleIcon className="w-5 h-5 text-green-600" />;
      default:
        return <BellIcon className="w-5 h-5 text-gray-600" />;
    }
  };

  const formatDate = (date: Date) => {
    if (!date) return '';
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 7) {
      return date.toLocaleDateString();
    } else if (days > 0) {
      return `${days}d ago`;
    } else if (hours > 0) {
      return `${hours}h ago`;
    } else if (minutes > 0) {
      return `${minutes}m ago`;
    } else {
      return 'Just now';
    }
  };

  const isUnread = (notification: Notification): boolean => {
    // A notification is unread if:
    // 1. isRead is missing or false
    // 2. markedAsUnread is true
    return (
      !('isRead' in notification) || 
      !notification.isRead || 
      (notification.markedAsUnread === true)
    );
  };

  return (
    <div className="w-[calc(100vw-2rem)] md:w-96 max-h-[80vh] overflow-hidden bg-white shadow-2xl rounded-lg border border-gray-200">
      <div className="sticky top-0 py-2 px-4 bg-purple-100 border-b border-purple-200">
        <h3 className="font-semibold text-purple-800">Notifications</h3>
      </div>
      <div className="overflow-y-auto max-h-[calc(80vh-3rem)] divide-y divide-gray-100">
        {notifications.length === 0 ? (
          <p className="p-4 text-sm text-gray-500 text-center">No notifications yet</p>
        ) : (
          notifications.map((notification) => (
            <div
              key={notification.id}
              onClick={(e) => handleNotificationClick(notification, e)}
              className={`relative p-4 cursor-pointer transition-all duration-200 ${
                isUnread(notification)
                  ? 'bg-purple-50 hover:bg-purple-100' 
                  : 'bg-white hover:bg-gray-50'
              }`}
            >
              {isUnread(notification) && (
                <div className="absolute top-4 right-4 w-2 h-2 rounded-full bg-purple-500" />
              )}
              <div className="flex items-start gap-3">
                <div className="flex-shrink-0">
                  {loading === notification.id ? (
                    <Loader2Icon className="w-5 h-5 text-purple-600 animate-spin" />
                  ) : (
                    getNotificationIcon(notification.type)
                  )}
                </div>
                <div className="flex-grow min-w-0">
                  <h4 className="font-semibold text-sm truncate">
                    {notification.title || 'Notification'}
                    {isUnread(notification) && (
                      <span className="ml-2 text-xs text-purple-600">
                        New
                      </span>
                    )}
                  </h4>
                  <p className="text-sm text-gray-600 line-clamp-2">{notification.message}</p>
                  <span className="text-xs text-gray-400 block mt-1">
                    {formatDate(notification.createdAt)}
                  </span>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};