import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../config/firebase'; // Adjust the import path as needed

const functions = getFunctions(app);
const togetherAIProxy = httpsCallable(functions, 'togetherAIProxy');

// Type definitions for Together AI API responses
interface ChatMessage {
  role: string;
  content: string;
}

interface ChatChoice {
  message: ChatMessage;
  index: number;
  finish_reason: string;
}

interface ChatResponse {
  id: string;
  choices: ChatChoice[];
  created: number;
  model: string;
}

interface ImageData {
  b64_json: string;
}

interface ImageResponse {
  data: ImageData[];
  created: number;
}

// Type for the wrapped Firebase function response
interface FirebaseFunctionResult<T> {
  data: T;
}

export async function enhanceTextWithAI(title: string, description: string): Promise<string> {
  const prompt = `
    Given the following event details:
    Title: "${title}"
    Description: "${description}"

    Enhance the event description to make it more engaging and informative. 
    Keep the core information intact but improve the language, add more details if necessary, 
    and make it more appealing to potential attendees. 
    The enhanced description should be concise (max 500 words) and maintain the original tone.

    Respond with only the enhanced description, without any quotation marks.
  `;

  try {
    const response = await togetherAIProxy({
      endpoint: '/chat/completions',
      method: 'POST',
      payload: {
        model: "meta-llama/Llama-Vision-Free",
        messages: [
          { role: "system", content: "You are an AI assistant that enhances event descriptions to make them more engaging. Do not use quotation marks in your response." },
          { role: "user", content: prompt }
        ],
        max_tokens: 200,
        temperature: 0.7
      }
    }) as FirebaseFunctionResult<ChatResponse>;

    const result = response.data;
    let enhancedDescription = result.choices[0]?.message?.content?.trim() || description;
    enhancedDescription = enhancedDescription.replace(/^["']|["']$/g, '');
    return enhancedDescription;
  } catch (error) {
    console.error("Error enhancing description:", error);
    return description;
  }
}

export async function generateImagePrompt(title: string, description: string): Promise<string> {
  const prompt = `
    Given the following event details:
    Title: "${title}"
    Description: "${description}"

    Create a detailed prompt for generating an elegant and intuitive event poster image.
    The prompt should describe:
    1. The main visual elements that represent the event's theme
    2. The overall style and mood of the image
    3. Any specific colors or lighting that would be appropriate

    Focus solely on the visual aspects without mentioning any text or typographic elements.
    The prompt should be concise (max 40 words) but descriptive enough to generate a compelling event poster image.

    Respond with only the image generation prompt.
  `;

  try {
    const response = await togetherAIProxy({
      endpoint: '/chat/completions',
      method: 'POST',
      payload: {
        model: "meta-llama/Llama-Vision-Free",
        messages: [
          { role: "system", content: "You are an AI assistant that creates prompts for generating event poster images. Do not use quotation marks in your response." },
          { role: "user", content: prompt }
        ],
        max_tokens: 100,
        temperature: 0.7
      }
    }) as FirebaseFunctionResult<ChatResponse>;

    const result = response.data;
    let imagePrompt = result.choices[0]?.message?.content?.trim() || '';
    imagePrompt = imagePrompt.replace(/^["']|["']$/g, '');
    return imagePrompt;
  } catch (error) {
    console.error("Error generating image prompt:", error);
    return '';
  }
}

export async function generateEventImage(prompt: string): Promise<string | null> {
  try {
    const response = await togetherAIProxy({
      endpoint: '/images/generations',
      method: 'POST',
      payload: {
        model: "black-forest-labs/FLUX.1-schnell-Free",
        prompt: prompt,
        width: 1024,
        height: 768,
        steps: 1,
        n: 1,
        response_format: "b64_json"
      }
    }) as FirebaseFunctionResult<ImageResponse>;

    const result = response.data;
    if (result.data?.[0]?.b64_json) {
      return result.data[0].b64_json;
    }
    
    console.error("Unexpected response format from image generation API");
    return null;
  } catch (error) {
    console.error("Error generating image:", error);
    return null;
  }
}

export async function checkContentAppropriateness(content: string): Promise<boolean> {
  const profanityRegex = /\b(fuck|shit|bullshit|ass|bitch|cunt|dick|pussy|cock|whore|slut|nigger|faggot)\b/i;
  if (profanityRegex.test(content)) {
    return false;
  }

  const prompt = `
    Analyze the following content for a public event review platform and determine if it is appropriate.
    The content should be rejected (return false) if it contains any of the following:
    1. Profanity or offensive language
    2. Hate speech or discriminatory content
    3. Sexual content or innuendos
    4. Personal attacks or harassment
    5. Spam or irrelevant content
    6. Threatening language
    7. Excessive use of capital letters (shouting)
    8. Drug references
    9. Contact information or personal data
    10. Promotional content or advertising

    Content to analyze: "${content}"
    
    Respond with ONLY the word "false" if ANY of the above criteria are met, or "true" if the content is completely appropriate.
    Do not explain your reasoning, just respond with "true" or "false".
  `;

  try {
    const response = await togetherAIProxy({
      endpoint: '/chat/completions',
      method: 'POST',
      payload: {
        model: "meta-llama/Llama-2-70b-chat",
        messages: [
          { 
            role: "system", 
            content: "You are a strict content moderator. You must respond with ONLY 'true' or 'false'. Be very conservative - if there's any doubt, respond with 'false'." 
          },
          { role: "user", content: prompt }
        ],
        max_tokens: 10,
        temperature: 0.1,
        top_p: 0.1
      }
    }) as FirebaseFunctionResult<ChatResponse>;

    const result = response.data;
    const appropriateContent = result.choices[0]?.message?.content?.trim().toLowerCase() === 'true';
    console.log('Content moderation result:', { content, result: appropriateContent });
    return appropriateContent;
  } catch (error) {
    console.error("Error checking content:", error);
    return false;
  }
}