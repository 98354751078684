import React from 'react';
import { User } from '@firebase/auth';
import { useSearchContext } from '../contexts/SearchContext';

interface GreetingProps {
  user: User | null;
}

export const Greeting: React.FC<GreetingProps> = ({ user }) => {
  const { state } = useSearchContext();

  if (state.isLocationChanged) {
    return null;
  }

  return (
    <div className="text-2xl font-bold mb-4 text-purple-600 text-center">
      {user ? `Welcome, ${user.displayName || 'User'}!` : 'Welcome to EventAtEase!'}
    </div>
  );
};