import { db } from '../config/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { Notification } from '../types/Notification';

export const createNotification = async (
  userId: string,
  type: string,
  message: string,
  title: string,
  eventId?: string,
  metadata: any = {}
): Promise<void> => {
  try {
    const notificationsRef = collection(db, 'notifications');
    const notification: Omit<Notification, 'id'> = {
      userId,
      type,
      message,
      title,
      eventId,
      isRead: false,
      markedAsUnread: false,
      createdAt: new Date(),
      metadata: {
        ...metadata,
        lastInteractionAt: null
      }
    };

    await addDoc(notificationsRef, notification);
  } catch (error) {
    console.error('Error creating notification:', error);
    throw error;
  }
};

export const createRegistrationNotification = async (
  userId: string,
  eventId: string,
  eventTitle: string,
  registrationType: string = 'regular'
): Promise<void> => {
  const title = 'Registration Successful';
  const message = `You have successfully registered for ${eventTitle}. We look forward to seeing you at the event!`;
  
  await createNotification(
    userId,
    'registration_confirmation',
    message,
    title,
    eventId,
    {
      registrationType,
      urgency: 'normal',
      notificationMethods: ['in_app']
    }
  );
};

export const createPaymentNotification = async (
  userId: string,
  eventId: string,
  eventTitle: string,
  amount: number
): Promise<void> => {
  const title = 'Payment Successful';
  const message = `Your payment of ₹${amount} for ${eventTitle} has been successfully processed.`;
  
  await createNotification(
    userId,
    'payment_confirmation',
    message,
    title,
    eventId,
    {
      amount,
      urgency: 'normal',
      notificationMethods: ['in_app']
    }
  );
};

export const createEventUpdateNotification = async (
  userId: string,
  eventId: string,
  eventTitle: string,
  updateType: string,
  updateDetails: string
): Promise<void> => {
  const title = `Event Update: ${eventTitle}`;
  const message = updateDetails;
  
  await createNotification(
    userId,
    'event_update',
    message,
    title,
    eventId,
    {
      updateType,
      urgency: 'normal',
      notificationMethods: ['in_app']
    }
  );
};

export const createEventReminderNotification = async (
  userId: string,
  eventId: string,
  eventTitle: string,
  eventDateTime: Date
): Promise<void> => {
  const title = `Upcoming Event Reminder`;
  const message = `Don't forget! ${eventTitle} is starting soon.`;
  
  await createNotification(
    userId,
    'event_reminder',
    message,
    title,
    eventId,
    {
      eventDateTime,
      urgency: 'urgent',
      notificationMethods: ['in_app', 'email']
    }
  );
};

export const createOrganizerMessageNotification = async (
  userId: string,
  eventId: string,
  eventTitle: string,
  messageId: string,
  messagePreview: string
): Promise<void> => {
  const title = `New Message from Organizer`;
  const message = `The organizer of ${eventTitle} has sent a message: "${messagePreview.substring(0, 100)}${messagePreview.length > 100 ? '...' : ''}"`;
  
  await createNotification(
    userId,
    'organizer_message',
    message,
    title,
    eventId,
    {
      messageId,
      urgency: 'normal',
      notificationMethods: ['in_app']
    }
  );
};
