import { useState, useEffect } from 'react';
import { Event } from '../models/Event';
import { getImageUrl } from '../utils/firebaseUtils';
import { getUserName } from '../services/userService';
import { Event as EventType } from '../types/Event';


interface Attendee {
  id: string;
  name: string;
  avatar: string;
}

interface EventAttendee {
  userId: string;
  // Add any other properties that might be in the attendee object
}

export const useEventDetail = (id: string | undefined) => {
  const [event, setEvent] = useState<Event | null>(null);
  const [imageUrl, setImageUrl] = useState<string>('/default-event-image.jpg');
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const fetchEvent = async () => {
      if (id) {
        setIsLoading(true);
        console.log('useEventDetail: Fetching event with id:', id);
        try {
          const fetchedEvent = await Event.findById(id);
          console.log('useEventDetail: Fetched event:', fetchedEvent);
          if (fetchedEvent) {
            setEvent(fetchedEvent);
            if (fetchedEvent.imageUrl) {
              try {
                const url = await getImageUrl(fetchedEvent.imageUrl);
                setImageUrl(url);
              } catch (error) {
                console.error('useEventDetail: Error fetching image URL:', error);
                setImageUrl('/default-event-image.jpg');
              }
            }
            // Fetch attendee names
            if (fetchedEvent.attendees && fetchedEvent.attendees.length > 0) {
              const attendeePromises = fetchedEvent.attendees.map(async (attendee: EventAttendee) => {
                const name = await getUserName(attendee.userId);
                return {
                  id: attendee.userId,
                  name: name,
                  avatar: '', // You can add avatar fetching logic here if needed
                };
              });
              const fetchedAttendees = await Promise.all(attendeePromises);
              setAttendees(fetchedAttendees);
            }
          } else {
            console.error('useEventDetail: Event not found for id:', id);
          }
        } catch (error) {
          console.error('useEventDetail: Error fetching event:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('useEventDetail: No event id provided');
      }
    };

    fetchEvent();
  }, [id]);

  return {
    event,
    imageUrl,
    attendees,
    isLoading,
    isDialogOpen,
    setIsDialogOpen,
    googleCalendarEventId: (event as EventType)?.googleCalendarEventId
  };
};
