import { User as FirebaseUser } from '@firebase/auth';

// Base interface for our custom user properties
export interface CustomUser {
  name: string;
  email: string;
  gender: string;
  dateOfBirth: Date;
  eventsAttended: string[];
  eventsCreated: string[];
  bankAccountDetails: {
    accountNumber: string;
    bankName: string;
    ifscCode: string;
    isVerified: boolean;
  };
  upiDetails: string;
  upiVerified: boolean;
  subscribers: string[];  // Users who subscribe to this organizer
  subscribedTo: string[]; // Organizers this user subscribes to
  isOrganizer: boolean;
  _isVerifiedOrganizer?: boolean;
  profilePicture: string;
  phone: string;
  phoneVerified: boolean;
  createdAt: Date;
  lastLogin: Date;
  bookmarkedEvents: string[];
  razorpayContactCreated: boolean;
  razorpayContactId?: string;
  fundAccounts: FundAccount[];
  maxFundAccounts: number;
  aadharVerification?: {
    isVerified: boolean;
    verificationDate?: Date;
    aadharNumber?: string;
    fullName?: string;
    address?: {
      country?: string;
      state?: string;
      district?: string;
      pincode?: string;
      fullAddress?: string;
    };
    gender?: string;
    dateOfBirth?: string;
    profileImage?: string;
  };
}

// Helper function to check if organizer is verified
export const isVerifiedOrganizer = (user: CustomUser | null): boolean => {
  if (!user) return false;
  return !!user._isVerifiedOrganizer;
};

// Combine Firebase User with our custom properties
export type User = FirebaseUser & CustomUser;

export interface BankAccountDetails {
  name: string;
  ifsc: string;
  account_number: string;
}

export interface VPADetails {
  address: string;
}

export interface FundAccount {
  id: string;
  type: 'bank_account' | 'vpa';
  active: boolean;
  details: BankAccountDetails | VPADetails;
}

// Add these type guard functions
export function isBankAccountDetails(
  details: BankAccountDetails | VPADetails
): details is BankAccountDetails {
  return 'account_number' in details;
}

export function isVPADetails(
  details: BankAccountDetails | VPADetails
): details is VPADetails {
  return 'address' in details;
}
