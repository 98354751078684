import { Event } from '../types/Event';

export interface DisplayRule {
  field: keyof Event | string;
  display: string;
  maxLength?: number;
  required?: boolean;
  format?: string;
  conditions?: Record<string, Record<string, string>>;
}

export interface DisplayVariant {
  showAll?: boolean;
  showPriority?: string[];
  imageSize: 'small' | 'large';
  showDescription: boolean;
}

export interface DisplaySection {
  name: string;
  fields: (keyof Event | string)[];
  layout?: string;
  prominence?: string;
  visibility?: string;
  conditions?: Record<string, Record<string, string>>;
}

export interface CommonRules {
  dateHandling: {
    singleDay: string;
    multiDay: string | {
      shortTerm: string;
      longTerm: string;
    };
    repeated: string;
  };
  statusIndicators: {
    upcoming: string;
    cancelled: string;
    completed: string;
  };
  interactiveElements: {
    location: string | {
      online: string;
      physical: string;
      link: string;
    };
    booking: string | {
      enabled: string;
      disabled: string;
    };
    rating: string | {
      enabled: string;
      disabled: string;
    };
  };
}

export const eventDisplayRules = {
  displayRules: {
    EventCard: {
      priority: {
        high: [
          {
            field: "title",
            display: "large heading",
            maxLength: 50,
            required: true
          },
          {
            field: "date",
            display: "formatted date with day",
            format: "EEEE, MMMM d, yyyy",
            required: true
          },
          {
            field: "time",
            display: "startTime - endTime",
            required: true
          }
        ],
        medium: [
          {
            field: "location",
            display: "icon + text",
            conditions: {
              isOnline: {
                true: "show globe icon + 'Online Event'",
                false: "show pin icon + location"
              }
            }
          },
          {
            field: "price",
            display: "badge",
            conditions: {
              isFree: {
                true: "Free Event (green)",
                false: "Price in $ (purple)"
              }
            }
          }
        ],
        low: [
          {
            field: "skill",
            display: "badge",
            optional: true
          },
          {
            field: "description",
            display: "truncated text",
            maxLength: 3000,
            optional: true
          }
        ]
      },
      variants: {
        default: {
          showAll: true,
          imageSize: "large" as const,
          showDescription: true
        },
        compact: {
          showPriority: ["high", "medium"],
          imageSize: "small" as const,
          showDescription: false
        }
      }
    },
    SmallEventCard: {
      displayFields: [
        {
          field: "title",
          display: "medium heading",
          maxLength: 30
        },
        {
          field: "date",
          display: "short format",
          format: "MMM d, yyyy"
        },
        {
          field: "time",
          display: "compact",
          format: "HH:mm"
        },
        {
          field: "location",
          display: "icon only if online, truncated text"
        }
      ],
      styling: {
        imageHeight: "32px",
        maxWidth: "300px"
      }
    },
    EventDetailPage: {
      sections: [
        {
          name: "header",
          fields: ["title", "imageUrl", "status", "bookmarkButton", "shareButton"],
          prominence: "hero"
        },
        {
          name: "essentialInfo",
          fields: [
            "date",
            "time",
            "location",
            "isOnline",
            "price",
            "maxCapacity",
            "meetingLink"
          ],
          layout: "grid",
          conditions: {
            meetingLink: {
              isOnline: "true"
            }
          }
        },
        {
          name: "details",
          fields: [
            "description",
            "skill",
            "categories",
            "tags",
            "organizer"
          ],
          layout: "vertical"
        },
        {
          name: "attendance",
          fields: [
            "attendeeCount",
            "attendees",
            "feedback",
            "registrationButton"
          ],
          visibility: "conditional",
          conditions: {
            registrationButton: {
              isCancelled: "false",
              isCapacityAvailable: "true",
              isNotAttendee: "true"
            }
          }
        }
      ]
    },
    commonRules: {
      dateHandling: {
        singleDay: "show full date with time range",
        multiDay: {
          shortTerm: "show daily schedule accordion if <= 7 days",
          longTerm: "show date range with recurring pattern if > 7 days"
        },
        repeated: "show frequency pattern with days of week"
      },
      statusIndicators: {
        upcoming: "default",
        cancelled: "show warning banner with icon",
        completed: "show completion status with feedback option"
      },
      interactiveElements: {
        location: {
          online: "show globe icon with 'Online Event'",
          physical: "show pin icon with location text",
          link: "clickable if locationLink exists"
        },
        booking: {
          enabled: "maxCapacity > attendeeCount && !isCancelled && !isAttendee",
          disabled: "maxCapacity <= attendeeCount || isCancelled || isAttendee"
        },
        rating: {
          enabled: "hasAttended && !hasRated",
          disabled: "!hasAttended || hasRated"
        }
      }
    }
  }
} as const;

// Helper functions to access display rules
export const getEventCardRules = () => eventDisplayRules.displayRules.EventCard;
export const getSmallEventCardRules = () => eventDisplayRules.displayRules.SmallEventCard;
export const getEventDetailPageRules = () => eventDisplayRules.displayRules.EventDetailPage;
export const getCommonRules = () => eventDisplayRules.displayRules.commonRules;
