import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../ui/Button';
import { AnimatedLogo } from './AnimatedLogo';

export const LandingHeader: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed w-full top-0 z-50 transition-colors duration-300 ${isScrolled ? 'bg-purple-200 bg-opacity-10 backdrop-blur-sm' : 'bg-transparent'}`}>
      <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-center h-16">
          {/* Logo section - now left aligned */}
          <div className="flex-shrink-0">
            <Link to="/" className="transform hover:scale-105 transition-transform">
              <AnimatedLogo isWhite={!isScrolled} />
            </Link>
          </div>

          {/* Login button - pushed to the right */}
          <div className="ml-auto">
            <Button
              as={Link}
              to="/login"
              variant="outline"
              className={`px-4 sm:px-6 py-2 text-sm sm:text-base transition-colors whitespace-nowrap text-purple-600 border-purple-600 hover:bg-purple-50`}
            >
              Login
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};