import React from 'react';
import { SEO } from '../../../components/SEO';
import { BlogCard } from '../components/BlogCard';
import { blogPosts } from '../data/blogPosts';

const BlogPage = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <SEO 
                title="Blog | EventAtEase" 
                description="Read the latest updates, tips, and insights about event planning and management"
            />
            
            {/* Hero Section */}
            <div className="bg-white border-b">
                <div className="container mx-auto px-4 py-16">
                    <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4">
                        EventAtEase Blog
                    </h1>
                    <p className="text-xl text-gray-600 max-w-2xl">
                        Discover the latest insights, tips, and trends in event planning and management.
                        Stay updated with our expert advice and success stories.
                    </p>
                </div>
            </div>

            {/* Blog Posts Grid */}
            <div className="container mx-auto px-4 py-12">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {blogPosts.map((post) => (
                        <BlogCard key={post.id} post={post} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BlogPage;
