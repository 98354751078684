import { Event, OfflineRegistration } from '../types/Event';
import { db } from '../config/firebase';
import {
  doc,
  setDoc,
  Timestamp,
  collection,
  runTransaction,
  arrayUnion,
  increment,
  getDoc,
  getDocs,
  query,
  where,
  limit,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';
import { FirebaseError } from 'firebase/app';
import { addEventToUserRegistrations } from './userService';
import { Attendee } from '../types/Attendee';
import { User } from '../types/User';
import { EventRegistration } from '../types/EventRegistration';
import { createRegistrationNotification, createPaymentNotification } from './notificationService';

export const addEvent = async (event: Event): Promise<string> => {
  try {
    const eventsRef = doc(db, 'events', event.id);
    
    // Create timestamps first
    const now = Timestamp.now();
    
    // Prepare the event data for Firestore
    const eventData = {
      ...event,
      date: event.date, // Already a Timestamp
      fromDate: event.fromDate, // Already a Timestamp or undefined
      toDate: event.toDate, // Already a Timestamp or undefined
      createdAt: now,
      updatedAt: now,
      registrationTypes: event.registrationTypes || [], // Ensure registrationTypes is included
      stats: event.stats || { totalEarnings: 0, platformEarnings: 0, transactionCount: 0 }
    };

    // Log the complete event data before adding to Firestore
    console.log('Event data to be added to Firestore:', JSON.stringify({
      ...eventData,
      createdAt: 'timestamp',
      updatedAt: 'timestamp',
      date: 'timestamp',
      fromDate: 'timestamp',
      toDate: 'timestamp'
    }, null, 2));

    // Add the event to Firestore using setDoc
    await setDoc(eventsRef, eventData);
    
    console.log('Event added with ID:', event.id);
    return event.id;
  } catch (error) {
    if (error instanceof FirebaseError) {
      console.error('Firebase Error Code:', error.code);
      console.error('Firebase Error Message:', error.message);
    } else {
      console.error('Unknown error:', error);
    }
    throw error;
  }
};

export const addEventAndUpdateUser = async (event: Event, userId: string) => {
  const eventRef = doc(collection(db, 'events'), event.id);
  const userRef = doc(db, 'users', userId);

  try {
    await runTransaction(db, async (transaction) => {
      transaction.set(eventRef, event);
      transaction.update(userRef, {
        eventsCreated: arrayUnion(event.id)
      });
    });

    return event.id;
  } catch (error) {
    console.error('Error in transaction:', error);
    throw error;
  }
};

export const registerUserForEvent = async (eventId: string, userId: string, registrationType?: string, amount?: number): Promise<void> => {
  const eventRef = doc(db, 'events', eventId);

  try {
    // Get event data first to ensure we have the title
    const eventDoc = await getDoc(eventRef);
    if (!eventDoc.exists()) {
      throw new Error("Event not found.");
    }
    const eventData = eventDoc.data() as Event;
    const eventTitle = eventData.title;

    // Now proceed with the transaction
    await runTransaction(db, async (transaction) => {
      const freshEventDoc = await transaction.get(eventRef);
      if (!freshEventDoc.exists()) {
        throw new Error("Event not found.");
      }

      const freshEventData = freshEventDoc.data() as Event;
      if (freshEventData.attendeeCount >= freshEventData.maxCapacity) {
        throw new Error("Event is at full capacity.");
      }

      if (freshEventData.attendees.some((attendee) => attendee.userId === userId)) {
        throw new Error("User already registered for this event.");
      }

      const attendeeData = {
        userId,
        registrationType: registrationType || 'regular',
        amount: amount || 0,
        checkedIn: false
      };

      // Log the attendee data before updating
      console.log('Adding attendee data:', JSON.stringify(attendeeData, null, 2));

      // Update event document with new attendee
      transaction.update(eventRef, {
        attendees: arrayUnion(attendeeData),
        attendeeCount: increment(1),
        updatedAt: Timestamp.now(),
      });

      // Create event registration document
      const registrationRef = doc(collection(db, 'eventRegistrations'));
      const registrationData = {
        eventId,
        userId,
        registrationType: registrationType || 'regular',
        amount: amount || 0,
        registrationDate: Timestamp.now(),
        status: 'confirmed'
      };

      // Log the registration data before updating
      console.log('Creating registration data:', JSON.stringify(registrationData, null, 2));

      transaction.set(registrationRef, registrationData);

      // Add event to user's registrations
      await addEventToUserRegistrations(userId, eventId);
    });

    // Create notifications after successful registration
    await createRegistrationNotification(userId, eventId, eventTitle, registrationType || 'regular');
    if (amount && amount > 0) {
      await createPaymentNotification(userId, eventId, eventTitle, amount);
    }

    console.log(`User ${userId} successfully registered for event ${eventId}`);
  } catch (error) {
    console.error('Error in registerUserForEvent:', error);
    throw error;
  }
};

export const getEventAttendees = async (eventId: string): Promise<Attendee[]> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    const eventSnap = await getDoc(eventRef);

    if (!eventSnap.exists()) {
      throw new Error('Event not found');
    }

    const eventData = eventSnap.data() as Event;
    const attendeeEntries = eventData.attendees || [];

    const attendeePromises = attendeeEntries.map(async (attendeeEntry) => {
      const userRef = doc(db, 'users', attendeeEntry.userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data() as User;
        return {
          ...userData,
          id: attendeeEntry.userId,
          registrationType: attendeeEntry.registrationType,
          checkedIn: attendeeEntry.checkedIn || false,
        } as Attendee;
      } else {
        return null;
      }
    });

    const attendees = await Promise.all(attendeePromises);
    return attendees.filter((attendee): attendee is Attendee => attendee !== null);
  } catch (error) {
    console.error('Error fetching attendees:', error);
    throw error;
  }
};

export const updateAttendeeCheckInStatus = async (
  eventId: string,
  userId: string
): Promise<void> => {
  const eventRef = doc(db, 'events', eventId);
  const eventRegistrationsRef = collection(db, 'eventRegistrations');

  try {
    await runTransaction(db, async (transaction) => {
      const eventDoc = await transaction.get(eventRef);

      if (!eventDoc.exists()) {
        throw new Error('Event not found');
      }

      const eventData = eventDoc.data() as Event;
      const attendees = eventData.attendees || [];

      const attendeeIndex = attendees.findIndex((attendee) => attendee.userId === userId);

      if (attendeeIndex === -1) {
        throw new Error('Attendee not found');
      }

      // Toggle the checkedIn status
      const newCheckedInStatus = !attendees[attendeeIndex].checkedIn;
      attendees[attendeeIndex].checkedIn = newCheckedInStatus;

      transaction.update(eventRef, {
        attendees: attendees,
        updatedAt: Timestamp.now(),
      });

      const eventRegistrationQuery = query(
        eventRegistrationsRef,
        where('eventId', '==', eventId),
        where('userId', '==', userId),
        limit(1)
      );

      const eventRegistrationSnapshot = await getDocs(eventRegistrationQuery);

      if (!eventRegistrationSnapshot.empty) {
        const eventRegistrationDoc = eventRegistrationSnapshot.docs[0];
        transaction.update(eventRegistrationDoc.ref, {
          checkedIn: newCheckedInStatus,
          checkInTime: newCheckedInStatus ? Timestamp.now() : null,
        });
      }
    });

    console.log(`Check-in status updated for user ${userId} in event ${eventId}`);
  } catch (error) {
    console.error('Error updating check-in status:', error);
    throw error;
  }
};

/**
 * Update event with booking confirmation PDF URL
 */
export const updateEventBookingConfirmationPdf = async (eventId: string, pdfUrl: string): Promise<void> => {
  try {
    const eventRef = doc(db, 'events', eventId);
    await updateDoc(eventRef, {
      bookingConfirmationPdfUrl: pdfUrl,
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating event with booking confirmation PDF:', error);
    throw error;
  }
};

export const addOfflineRegistration = async (
  eventId: string,
  registration: Omit<OfflineRegistration, 'timestamp'>
): Promise<void> => {
  try {
    console.log('Adding offline registration for event:', eventId);
    console.log('Registration data:', registration);

    const eventRef = doc(db, 'events', eventId);
    const registrationWithTimestamp: OfflineRegistration = {
      ...registration,
      timestamp: Timestamp.now()
    };

    console.log('Registration with timestamp:', registrationWithTimestamp);

    await updateDoc(eventRef, {
      offlineRegistrations: arrayUnion(registrationWithTimestamp),
      'stats.totalEarnings': increment(registration.amountPaid),
      'stats.transactionCount': increment(1),
      updatedAt: serverTimestamp()
    });

    console.log('Successfully added offline registration');
  } catch (error) {
    console.error('Error adding offline registration:', error);
    throw error;
  }
};