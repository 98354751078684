import React, { createContext, useContext, useState, ReactNode } from 'react';

interface TabsContextType {
  activeTab: string;
  setActiveTab: (value: string) => void;
}

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsProps {
  children: ReactNode;
  defaultValue?: string;
  value?: string;
  onValueChange?: (value: string) => void;
  className?: string;
}

interface TabProps {
  value: string;
  children: ReactNode;
  className?: string;
}

interface TabPanelProps {
  value: string;
  children: ReactNode;
  className?: string;
}

export const Tabs: React.FC<TabsProps> = ({
  children,
  defaultValue,
  value,
  onValueChange,
  className = '',
}) => {
  const [localActiveTab, setLocalActiveTab] = useState(defaultValue || '');
  const activeTab = value !== undefined ? value : localActiveTab;
  const setActiveTab = (newValue: string) => {
    if (value === undefined) {
      setLocalActiveTab(newValue);
    }
    onValueChange?.(newValue);
  };

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div className={`w-full ${className}`}>
        {children}
      </div>
    </TabsContext.Provider>
  );
};

export const TabList: React.FC<{ children: ReactNode; className?: string }> = ({ 
  children, 
  className = ''
}) => {
  return (
    <div className={`flex space-x-2 border-b border-[#e5dbff] ${className}`}>
      {children}
    </div>
  );
};

export const Tab: React.FC<TabProps> = ({ value, children, className = '' }) => {
  const context = useContext(TabsContext);
  if (!context) throw new Error('Tab must be used within Tabs');

  const { activeTab, setActiveTab } = context;
  const isActive = activeTab === value;

  const baseClassName = "px-4 py-2 text-sm font-medium transition-colors";
  const activeClassName = "border-b-2 border-[#9f85ff] text-[#4a3880]";
  const inactiveClassName = "text-[#9f85ff] hover:text-[#4a3880]";

  return (
    <button
      onClick={() => setActiveTab(value)}
      className={`${baseClassName} ${isActive ? activeClassName : inactiveClassName} ${className}`}
    >
      {children}
    </button>
  );
};

export const TabPanels: React.FC<{ children: ReactNode; className?: string }> = ({ 
  children, 
  className = ''
}) => {
  return <div className={`mt-2 ${className}`}>{children}</div>;
};

export const TabPanel: React.FC<TabPanelProps> = ({ value, children, className = '' }) => {
  const context = useContext(TabsContext);
  if (!context) throw new Error('TabPanel must be used within Tabs');

  const { activeTab } = context;
  if (activeTab !== value) return null;

  return <div className={className}>{children}</div>;
};