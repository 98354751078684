import { getFunctions, httpsCallable } from 'firebase/functions';
import { app } from '../config/firebase';

export interface AadharVerificationResponse {
  success: boolean;
  message: string;
  requestId?: string;
}

export interface RequestOTPParams {
  aadharNumber: string;
  name: string;
}

export interface VerifyOTPParams {
  requestId: string;
  otp: string;
  userId: string;
}

export class VerificationService {
  private static instance: VerificationService;
  private functions = getFunctions(app, 'us-central1');
  private lastRequestTime: number = 0;
  private readonly MIN_REQUEST_INTERVAL = 1000; // Minimum 1 second between requests

  private constructor() {}

  static getInstance(): VerificationService {
    if (!VerificationService.instance) {
      VerificationService.instance = new VerificationService();
    }
    return VerificationService.instance;
  }

  private async throttleRequest(): Promise<void> {
    const now = Date.now();
    const timeSinceLastRequest = now - this.lastRequestTime;
    
    if (timeSinceLastRequest < this.MIN_REQUEST_INTERVAL) {
      await new Promise(resolve => 
        setTimeout(resolve, this.MIN_REQUEST_INTERVAL - timeSinceLastRequest)
      );
    }
    
    this.lastRequestTime = Date.now();
  }

  async requestAadharOTP(params: RequestOTPParams): Promise<AadharVerificationResponse> {
    await this.throttleRequest();
    const requestOTPFunction = httpsCallable<
      typeof params,
      AadharVerificationResponse
    >(this.functions, 'requestAadharOTP');
    
    try {
      const result = await requestOTPFunction(params);
      console.log('OTP Request Result:', result.data); // Add logging
      return result.data;
    } catch (error: any) {
      console.error('Error in requestAadharOTP:', error);
      return {
        success: false,
        message: error.message || 'Failed to send OTP'
      };
    }
  }

  async verifyAadharOTP(params: VerifyOTPParams): Promise<AadharVerificationResponse> {
    await this.throttleRequest();
    const verifyOTPFunction = httpsCallable<
      typeof params,
      AadharVerificationResponse
    >(this.functions, 'verifyAadharOTP');
    
    try {
      const result = await verifyOTPFunction(params);
      console.log('OTP Verify Result:', result.data); // Add logging
      return result.data;
    } catch (error: any) {
      console.error('Error in verifyAadharOTP:', error);
      return {
        success: false,
        message: error.message || 'Failed to verify OTP'
      };
    }
  }
}

// Export a singleton instance
export const verificationService = VerificationService.getInstance();
