import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { FcGoogle } from 'react-icons/fc';
import AnimatedBackground from '../../components/AnimatedBackground';
import { SEO } from '../../components/SEO';
import { Checkbox } from '../../components/ui/Checkbox';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

interface LocationState {
  from?: string;
}

export const LoginPage: React.FC = () => {
  const { signInWithGoogle, error, user, customUser, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [acceptedTerms, setAcceptedTerms] = React.useState(() => {
    // Initialize from localStorage
    return localStorage.getItem('termsAccepted') === 'true';
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);

  // Store the previous location in localStorage when component mounts
  useEffect(() => {
    const from = (location.state as LocationState)?.from;
    if (from && from !== '/login' && from !== '/signup' && from !== '/first-signin') {
      localStorage.setItem('redirectAfterAuth', from);
    }
  }, [location]);

  // Get redirect path with fallback to stored path or default
  const getRedirectPath = () => {
    const storedPath = localStorage.getItem('redirectAfterAuth');
    const statePath = (location.state as LocationState)?.from;
    
    // Clear stored path after getting it
    localStorage.removeItem('redirectAfterAuth');
    
    return statePath || storedPath || '/attendee/home';
  };

  // Check for existing authentication and redirect accordingly
  useEffect(() => {
    const checkAuthAndRedirect = async () => {
      try {
        // If already logged in
        if (!loading && user) {
          try {
            const userDoc = await getDoc(doc(db, 'users', user.uid));
            
            if (!userDoc.exists()) {
              navigate('/first-signin', { 
                state: { from: getRedirectPath() },
                replace: true 
              });
              return;
            }

            const userData = userDoc.data();
            if (!userData.phone || !userData.phoneVerified || !userData.gender || !userData.dateOfBirth) {
              navigate('/first-signin', { 
                state: { from: getRedirectPath() },
                replace: true 
              });
              return;
            }

            // User is fully authenticated and has complete profile
            navigate(getRedirectPath(), { replace: true });
          } catch (error) {
            console.error('Error checking user data:', error);
          }
        }
      } finally {
        setIsCheckingAuth(false);
      }
    };

    checkAuthAndRedirect();
  }, [user, loading, navigate, getRedirectPath]);

  const handleGoogleSignIn = async () => {
    if (!acceptedTerms) {
      alert('Please accept the terms and conditions to continue');
      return;
    }

    setIsLoading(true);
    try {
      const result = await signInWithGoogle();
      
      // Store acceptance of terms
      localStorage.setItem('termsAccepted', 'true');
      
      // The useEffect will handle the redirect
    } catch (err) {
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTermsAcceptance = (checked: boolean) => {
    setAcceptedTerms(checked);
    if (checked) {
      localStorage.setItem('termsAccepted', 'true');
    } else {
      localStorage.removeItem('termsAccepted');
    }
  };

  // Show loading state while checking authentication
  if (isCheckingAuth || loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div 
          data-testid="loading-spinner"
          className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"
        />
      </div>
    );
  }

  // If user is already authenticated, don't render the login page
  if (user && customUser) {
    return null;
  }

  return (
    <>
      <SEO title="Login" description="Log in to your EventAtEase account" />
      <AnimatedBackground />
      <div className="auth-container flex flex-col items-center justify-center min-h-screen relative z-10">
        <div className="bg-white bg-opacity-80 p-8 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center text-purple-800">Login</h2>
          
          <div className="mb-4 flex items-start space-x-2">
            <Checkbox 
              id="terms" 
              checked={acceptedTerms}
              onChange={(event) => handleTermsAcceptance(event.target.checked)}
            />
            <label htmlFor="terms" className="text-sm text-gray-600">
              I accept the <Link to="/terms" className="text-purple-600 hover:text-purple-800 underline" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>
            </label>
          </div>

          <Button
            onClick={handleGoogleSignIn}
            icon={<FcGoogle className="text-xl" />}
            className="w-full mt-4 bg-purple-600 hover:bg-purple-700 text-white disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!acceptedTerms || isLoading}
          >
            {isLoading ? 'Signing in...' : 'Sign in with Google'}
          </Button>
          
          {error && (
            <p className="error mt-4 text-red-500 text-sm text-center">
              {error}
            </p>
          )}
        </div>
      </div>
    </>
  );
};