import { useState, useCallback, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Event } from '../../../types/Event';
import { SearchCriteria } from '../../../types/search';

export const useEvents = (
  searchCriteria: SearchCriteria,
  bookmarkedEvents: Set<string>,
  user: any
) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [baseEvents, setBaseEvents] = useState<Event[]>([]);

  const fetchBaseEvents = useCallback(async () => {
    try {
      const eventsRef = collection(db, 'events');
      const baseQuery = query(
        eventsRef,
        where('isPublic', '==', true)
      );

      const snapshot = await getDocs(baseQuery);
      if (!snapshot?.docs) {
        throw new Error('No docs found in snapshot');
      }

      const allEvents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      } as Event));

      setBaseEvents(allEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
      setBaseEvents([]);
    } finally {
      setLoading(false);
    }
  }, []);

  // Filter events based on search criteria
  useEffect(() => {
    let filtered = [...baseEvents];

    if (searchCriteria.searchTerm) {
      const searchTermLower = searchCriteria.searchTerm.toLowerCase();
      filtered = filtered.filter(event => 
        event.title.toLowerCase().includes(searchTermLower) ||
        event.description?.toLowerCase().includes(searchTermLower) ||
        event.location?.toLowerCase().includes(searchTermLower)
      );
    }

    setEvents(filtered);
  }, [baseEvents, searchCriteria.searchTerm]);

  // Fetch events on mount
  useEffect(() => {
    fetchBaseEvents();
  }, [fetchBaseEvents]);

  return { events, loading, fetchEvents: fetchBaseEvents };
};