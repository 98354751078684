// src/pages/organizer/ManageAttendeesPage.tsx

import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Input } from "../../components/ui/Input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../components/ui/Table";
import { Badge } from "../../components/ui/Badge";
import { Checkbox } from "../../components/ui/Checkbox";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../components/ui/DropdownMenu";
import { SearchIcon, MoreVerticalIcon, MessageCircleIcon, UserCheckIcon, UserXIcon, DownloadIcon, CheckIcon, ChevronDownIcon } from "lucide-react";
import { Attendee } from '../../types/Attendee';
import { Event, OfflineRegistration } from '../../types/Event';
import { getEventAttendees, updateAttendeeCheckInStatus } from '../../services/eventService';
import { useToast } from '../../hooks/useToast';
import { ManageOfflineAttendees } from '../../components/organizer/ManageOfflineAttendees';
import { NotificationDialog } from '../../components/organizer/NotificationDialog';
import { Timestamp } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/Select";
import { Tabs, TabList, Tab } from "../../components/ui/tabs";

interface ManageAttendeesPageProps {
  eventTitle: string;
  attendees: Attendee[];
  event: Event;
  organizerId: string;
}

type AttendeeStatus = 'not_checked_in' | 'checked_in' | 'cancelled';

export const ManageAttendeesPage: React.FC<ManageAttendeesPageProps> = ({ 
  eventTitle, 
  attendees: initialAttendees,
  event,
  organizerId
}) => {
  const { id: eventId } = useParams();
  const { showToast } = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAttendees, setSelectedAttendees] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<'online' | 'offline'>('online');
  const [attendees, setAttendees] = useState<Attendee[]>(initialAttendees);
  const [eventState, setEvent] = useState(event);
  const [showNotificationDialog, setShowNotificationDialog] = useState(false);
  const [offlineRegistrations, setOfflineRegistrations] = useState<OfflineRegistration[]>(
    eventState?.offlineRegistrations || []
  );

  const refreshEventData = async () => {
    if (!eventId) return;
    try {
      const eventDoc = await getDoc(doc(db, 'events', eventId));
      if (eventDoc.exists()) {
        const eventData = eventDoc.data() as Event;
        setEvent(eventData);
        setOfflineRegistrations(eventData.offlineRegistrations || []);
      }
    } catch (error) {
      console.error('Error fetching event data:', error);
      showToast({
        title: 'Error',
        description: 'Failed to refresh event data',
        variant: 'destructive',
      });
    }
  };

  useEffect(() => {
    const fetchAttendees = async () => {
      if (!eventId) return;
      try {
        const fetchedAttendees = await getEventAttendees(eventId);
        if (fetchedAttendees) {
          setAttendees(fetchedAttendees);
        }
      } catch (error) {
        console.error('Error fetching attendees:', error);
        showToast({
          title: 'Error',
          description: 'Failed to fetch attendees',
          variant: 'destructive',
        });
      }
    };

    fetchAttendees();
  }, [eventId, showToast]);

  const filteredAttendees = useMemo(() => {
    if (!searchTerm.trim()) return attendees;
    
    const query = searchTerm.toLowerCase().trim();
    return attendees?.filter((attendee) =>
      (attendee.displayName ?? '').toLowerCase().includes(query) ||
      (attendee.email ?? '').toLowerCase().includes(query)
    ) || [];
  }, [attendees, searchTerm]);

  const handleSelectAll = () => {
    if (selectedAttendees.length === filteredAttendees.length) {
      setSelectedAttendees([]);
    } else {
      setSelectedAttendees(filteredAttendees.map(a => a.uid));
    }
  };

  const handleSelectAttendee = (id: string) => {
    if (selectedAttendees.includes(id)) {
      setSelectedAttendees(selectedAttendees.filter(a => a !== id));
    } else {
      setSelectedAttendees([...selectedAttendees, id]);
    }
  };

  const handleStatusChange = async (attendeeId: string, status: AttendeeStatus) => {
    if (!eventId) return;
    try {
      // Only call the update function when changing to checked_in status
      if (status === 'checked_in') {
        await updateAttendeeCheckInStatus(eventId, attendeeId);
      }
      
      setAttendees(prev => 
        prev.map(attendee => 
          attendee.uid === attendeeId ? { ...attendee, checkedIn: status === 'checked_in' } : attendee
        )
      );

      showToast({
        title: "Success",
        description: `Attendee status updated to ${status.replace('_', ' ')}`,
        variant: "default",
      });
    } catch (error) {
      console.error('Error updating attendee status:', error);
      showToast({
        title: 'Error',
        description: 'Failed to update attendee status',
        variant: 'destructive',
      });
    }
  };

  const handleSendNotification = async (message: string) => {
    // TODO: Implement notification sending logic
    console.log('Sending notification to:', selectedAttendees);
    console.log('Message:', message);
    
    showToast({
      title: 'Success',
      description: 'Notification sent successfully',
      variant: 'default',
    });
  };

  if (!eventId) {
    return <div>Error: Event ID is missing</div>;
  }

  return (
    <div className="container mx-auto p-6 space-y-6">
      <div className="grid grid-cols-1 gap-6">
        <Tabs defaultValue="online" className="w-full" onValueChange={(value) => setActiveTab(value as 'online' | 'offline')}>
          <TabList className="grid w-full grid-cols-2 mb-4">
            <Tab value="online">Online Attendees</Tab>
            <Tab value="offline">Offline Attendees</Tab>
          </TabList>

          {activeTab === 'online' && (
            <Card className="bg-[#f3f0ff]">
              <CardHeader className="border-b border-[#e5dbff] pb-6">
                <div className="flex justify-between items-center">
                  <CardTitle className="text-2xl font-semibold text-[#4a3880]">Online Attendees</CardTitle>
                  <div className="flex items-center space-x-2">
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="border-[#9f85ff] text-[#4a3880] hover:bg-[#f3f0ff]"
                    >
                      <DownloadIcon className="h-4 w-4 mr-2" />
                      Export
                    </Button>
                    <Button 
                      variant="outline" 
                      size="sm"
                      className="border-[#9f85ff] text-[#4a3880] hover:bg-[#f3f0ff]"
                      onClick={() => setShowNotificationDialog(true)}
                      disabled={selectedAttendees.length === 0}
                    >
                      <MessageCircleIcon className="h-4 w-4 mr-2" />
                      Message Selected
                    </Button>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="pt-6">
                <div className="rounded-lg border border-[#e5dbff] overflow-hidden">
                  <div className="p-4 border-b border-[#e5dbff] bg-[#f3f0ff]">
                    <div className="relative">
                      <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                      <Input
                        placeholder="Search attendees..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="pl-10 w-full max-w-sm focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                      />
                    </div>
                  </div>

                  <div className="overflow-x-auto w-full">
                    <Table className="w-full table-fixed">
                      <TableHeader>
                        <TableRow className="bg-[#f3f0ff]">
                          <TableHead className="w-[5%] py-4 text-[#4a3880] font-semibold">
                            <div className="flex justify-center items-center px-4">
                              <Checkbox
                                checked={selectedAttendees.length === filteredAttendees.length}
                                onChange={handleSelectAll}
                                aria-label="Select all attendees"
                              />
                            </div>
                          </TableHead>
                          <TableHead className="w-[20%] py-4 text-[#4a3880] font-semibold text-center">Name</TableHead>
                          <TableHead className="w-[25%] py-4 text-[#4a3880] font-semibold text-center">Email</TableHead>
                          <TableHead className="w-[20%] py-4 text-[#4a3880] font-semibold text-center">Registration Type</TableHead>
                          <TableHead className="w-[15%] py-4 text-[#4a3880] font-semibold text-center">Status</TableHead>
                          <TableHead className="w-[15%] py-4 text-[#4a3880] font-semibold text-center">Notifications</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {filteredAttendees.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={5} className="text-center py-8 text-gray-500">
                              {searchTerm.trim() 
                                ? "No matching attendees found" 
                                : "No online attendees yet"
                              }
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredAttendees.map((attendee) => (
                            <TableRow key={attendee.uid} className="hover:bg-[#f9f7ff]">
                              <TableCell className="py-4">
                                <div className="flex justify-center items-center px-4">
                                  <Checkbox
                                    checked={selectedAttendees.includes(attendee.uid)}
                                    onChange={() => handleSelectAttendee(attendee.uid)}
                                    aria-label={`Select ${attendee.name}`}
                                  />
                                </div>
                              </TableCell>
                              <TableCell className="py-4 font-medium text-center text-black">
                                {attendee.name || 'Anonymous'}
                              </TableCell>
                              <TableCell className="py-4 text-center text-black">{attendee.email}</TableCell>
                              <TableCell className="py-4 text-center text-black capitalize">
                                {attendee.registrationType}
                              </TableCell>
                              <TableCell className="py-4 text-center">
                                <DropdownMenu>
                                  <DropdownMenuTrigger>
                                    <Button
                                      variant="outline"
                                      size="sm"
                                      className="w-[140px] border-[#9f85ff] text-[#4a3880] hover:bg-[#f3f0ff]"
                                    >
                                      <Badge 
                                        variant={attendee.checkedIn ? "success" : "secondary"}
                                        className={attendee.checkedIn ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800"}
                                      >
                                        {attendee.checkedIn ? "Checked In" : "Not Checked In"}
                                      </Badge>
                                      <ChevronDownIcon className="ml-2 h-4 w-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent className="min-w-[140px]">
                                    <DropdownMenuItem 
                                      onClick={() => handleStatusChange(attendee.uid, 'not_checked_in')}
                                      disabled={!attendee.checkedIn}
                                    >
                                      <span className="flex items-center">
                                        <Badge variant="secondary" className="bg-gray-100 text-gray-800">
                                          Not Checked In
                                        </Badge>
                                      </span>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem 
                                      onClick={() => handleStatusChange(attendee.uid, 'checked_in')}
                                      disabled={attendee.checkedIn}
                                    >
                                      <span className="flex items-center">
                                        <UserCheckIcon className="mr-2 h-4 w-4 text-green-600" />
                                        <Badge variant="success" className="bg-green-100 text-green-800">
                                          Check In
                                        </Badge>
                                      </span>
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                              <TableCell className="py-4 text-center">
                                <Button
                                  variant="outline"
                                  size="sm"
                                  className="border-[#9f85ff] text-[#4a3880] hover:bg-[#f3f0ff]"
                                  onClick={() => {
                                    setSelectedAttendees([attendee.uid]);
                                    setShowNotificationDialog(true);
                                  }}
                                >
                                  <MessageCircleIcon className="h-4 w-4 mr-2" />
                                  Message
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}

          {activeTab === 'offline' && (
            <ManageOfflineAttendees 
              eventId={eventId} 
              event={eventState} 
              organizerId={organizerId}
              registrationTypes={eventState?.registrationTypes}
              offlineRegistrations={offlineRegistrations}
              onRegistrationAdded={refreshEventData}
            />
          )}
        </Tabs>
      </div>

      {showNotificationDialog && (
        <NotificationDialog
          isOpen={showNotificationDialog}
          onClose={() => setShowNotificationDialog(false)}
          onSend={handleSendNotification}
          title={selectedAttendees.length === 1 ? "Send Message to Attendee" : "Send Message to Selected Attendees"}
          recipientCount={selectedAttendees.length}
        />
      )}
    </div>
  );
};
