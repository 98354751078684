import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from "../ui/Button";
import { BellIcon, UserIcon, CalendarIcon, PlusIcon } from 'lucide-react';
import { Notifications } from '../Notifications';
import { useNotifications } from '../../contexts/NotificationContext';
import { useAuth } from '../../contexts/AuthContext';
import { motion } from 'framer-motion';
import { AnimatedLogo } from './AnimatedLogo';

interface UserProfile {
  id: string;
  name: string;
  email: string;
  profilePicture: string;
  isOrganizer: boolean;
}

interface HeaderProps {
  user: UserProfile | null;
}

export const AuthenticatedHeader: React.FC<HeaderProps> = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showNotifications, setShowNotifications] = useState(false);
  const { notifications, getUnreadCount } = useNotifications();
  const unreadCount = getUnreadCount();
  const { user: authUser } = useAuth();
  const notificationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setShowNotifications(false);
  }, [location]);

  const handleCreateEvent = () => {
    navigate('/organizer/create-event');
  };

  const handleProfileClick = () => {
    if (authUser) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  return (
    <header className="bg-white shadow-sm relative z-[9999]">
      <div className="container mx-auto px-4 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <Link to="/attendee/home">
              <AnimatedLogo />
            </Link>
          </div>
          <div className="flex items-center space-x-1 sm:space-x-4">
            {user?.isOrganizer && (
              <Button onClick={handleCreateEvent} className="bg-purple-600 hover:bg-purple-700 text-white p-2 rounded-full">
                <PlusIcon className="h-5 w-5" />
              </Button>
            )}
            <div className="relative" ref={notificationRef}>
              <Button 
                onClick={() => setShowNotifications(!showNotifications)}
                className="relative p-2 rounded-full bg-purple-600 hover:bg-purple-700 text-white"
              >
                <BellIcon className="h-5 w-5" />
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-xs rounded-full h-5 w-5 flex items-center justify-center text-white border-2 border-purple-600">
                    {unreadCount}
                  </span>
                )}
              </Button>
              <div 
                className={`absolute right-0 mt-2 transform transition-all duration-200 ease-in-out ${
                  showNotifications ? 'opacity-100 translate-y-0 z-[10000]' : 'opacity-0 -translate-y-2 pointer-events-none'
                }`}
              >
                <Notifications onClose={() => setShowNotifications(false)} />
              </div>
            </div>
            <Button onClick={handleProfileClick} className="p-2 rounded-full bg-purple-600 hover:bg-purple-700 text-white">
              <UserIcon className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};