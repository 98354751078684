import React, { useState } from 'react';

interface EventDescriptionProps {
  description: string;
}

export const EventDescription: React.FC<EventDescriptionProps> = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 300; // Characters to show initially
  const shouldShowReadMore = description.length > maxLength;
  
  const displayText = isExpanded 
    ? description 
    : description.slice(0, maxLength) + (shouldShowReadMore ? '...' : '');

  return (
    <div className="flex-grow">
      <h2 className="text-2xl font-semibold mb-2 text-purple-800">About this event</h2>
      <div className={`transition-all duration-300 ${isExpanded ? '' : 'max-h-[200px] overflow-hidden'}`}>
        <p className="text-gray-700 whitespace-pre-wrap">{displayText}</p>
      </div>
      {shouldShowReadMore && (
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-purple-600 hover:text-purple-800 font-medium mt-2 focus:outline-none"
        >
          {isExpanded ? 'Show less' : 'Show more'}
        </button>
      )}
    </div>
  );
};