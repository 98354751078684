import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { CalendarIcon } from '../../utils/icons';

export const AnimatedLogo: React.FC<{ isWhite?: boolean }> = ({ isWhite = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  const textColorClass = isWhite ? "text-white" : "text-purple-600";

  return (
    <motion.div
      className="flex items-center space-x-2"
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      initial={false}
    >
      <motion.div
        animate={isHovered ? { rotate: 360, scale: 1.1 } : { rotate: 0, scale: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut" }}
      >
        <CalendarIcon className={`h-6 w-6 ${textColorClass}`} />
      </motion.div>
      <div className="relative">
        <motion.span className={`text-2xl ${textColorClass} inline-flex items-baseline`}>
          <motion.span
            className="font-bold"
            animate={isHovered ? { y: -2 } : { y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Event
          </motion.span>
          <motion.span
            className="font-normal mx-[1px]"
            animate={isHovered ? { y: 2, opacity: 0.7 } : { y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            At
          </motion.span>
          <motion.span
            className="font-bold"
            animate={isHovered ? { y: -2 } : { y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Ease
          </motion.span>
        </motion.span>
        <motion.div
          className="absolute bottom-0 left-0 right-0 h-[2px] bg-gradient-to-r from-transparent via-purple-400 to-transparent"
          initial={{ scaleX: 0, opacity: 0 }}
          animate={isHovered ? { scaleX: 1, opacity: 1 } : { scaleX: 0, opacity: 0 }}
          transition={{ duration: 0.7 }}
        />
      </div>
    </motion.div>
  );
};
