import React from 'react';
import { motion } from 'framer-motion';
import { MusicIcon, HeartPulseIcon, GraduationCapIcon } from "lucide-react";

interface TabFilterProps {
  onCategoryChange: (category: string) => void;
}

export const TabFilter: React.FC<TabFilterProps> = ({ onCategoryChange }) => {
  const [activeTab, setActiveTab] = React.useState('entertainment');

  const tabs = [
    { id: 'entertainment', label: 'Entertainment', icon: MusicIcon },
    { id: 'fitness', label: 'Fitness', icon: HeartPulseIcon },
    { id: 'learning', label: 'Learning & Skills', icon: GraduationCapIcon }
  ];

  const handleTabChange = (tabId: string) => {
    setActiveTab(tabId);
    const selectedTab = tabs.find(tab => tab.id === tabId);
    onCategoryChange?.(selectedTab?.label || '');
  };

  return (
    <div className="w-full bg-white/50 backdrop-blur-sm rounded-lg shadow-sm mt-4 mb-6 p-2">
      <div className="flex justify-start sm:justify-center overflow-x-auto no-scrollbar scrollbar-hide px-2">
        <div className="flex space-x-1 sm:space-x-2 min-w-full sm:min-w-0 justify-between sm:justify-start">
          {tabs.map((tab) => {
            const Icon = tab.icon;
            return (
              <button
                key={tab.id}
                onClick={() => handleTabChange(tab.id)}
                className={`
                  relative flex-shrink-0 px-3 sm:px-6 py-2.5 sm:py-3 text-xs sm:text-sm font-medium 
                  transition-all flex items-center space-x-1.5 sm:space-x-2.5 rounded-lg
                  flex-1 sm:flex-initial justify-center
                  ${activeTab === tab.id 
                    ? 'bg-purple-600 text-white shadow-md shadow-purple-200' 
                    : 'text-gray-600 hover:bg-purple-50 hover:text-purple-600'
                  }
                `}
              >
                <Icon className={`w-4 h-4 sm:w-5 sm:h-5 ${activeTab === tab.id ? 'text-white' : 'text-purple-500'}`} />
                <span className="truncate">{tab.label}</span>
                {activeTab === tab.id && (
                  <motion.div
                    layoutId="active-tab-indicator"
                    className="absolute inset-0 bg-purple-600 rounded-lg -z-10"
                    initial={false}
                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                  />
                )}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
