import { useCallback } from 'react';
import { Event } from '../types/Event';
import { Timestamp } from 'firebase/firestore';

interface ValidationError {
  field: string;
  message: string;
}

const MINIMUM_PRICE = 5; // Minimum price in INR

export const useEventValidation = () => {
  const validateEvent = useCallback((event: Partial<Event>): ValidationError[] => {
    const errors: ValidationError[] = [];

    if (!event.title) {
      errors.push({ field: 'title', message: 'Event title is required.' });
    }

    if (!event.description) {
      errors.push({ field: 'description', message: 'Event description is required.' });
    } else {
      // Check for phone numbers in description using regex
      const phoneRegex = /(\+?\d{1,4}[-.\s]?\(?\d{1,3}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})/g;
      if (phoneRegex.test(event.description)) {
        errors.push({ field: 'description', message: 'Phone numbers are not allowed in the event description.' });
      }

      // Check for email addresses in description using regex
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
      if (emailRegex.test(event.description)) {
        errors.push({ field: 'description', message: 'Email addresses are not allowed in the event description.' });
      }
    }

    if (event.isOnline === undefined) {
      errors.push({ field: 'isOnline', message: 'Please select whether the event is online or in-person.' });
    }

    if (event.isSingleDay === undefined) {
      errors.push({ field: 'isSingleDay', message: 'Please select whether the event is single day or multi-day.' });
    }

    const validateDates = (event: Partial<Event>): ValidationError[] => {
      const errors: ValidationError[] = [];
      const now = Timestamp.now();

      if (event.isSingleDay) {
        if (!event.date) {
          errors.push({ field: 'date', message: 'Event date is required' });
        } else {
          // Create timestamps for event start time and current time
          const eventDate = event.date.toDate();
          const eventStartTime = event.startTime ? new Date(event.startTime) : new Date();
          
          // Set the event date hours and minutes from startTime
          eventDate.setHours(eventStartTime.getHours());
          eventDate.setMinutes(eventStartTime.getMinutes());
          
          if (eventDate.getTime() < now.toDate().getTime()) {
            errors.push({ field: 'date', message: 'Event start time cannot be in the past' });
          }
        }
      } else {
        if (!event.fromDate) {
          errors.push({ field: 'fromDate', message: 'Start date is required' });
        }
        if (!event.toDate) {
          errors.push({ field: 'toDate', message: 'End date is required' });
        }
        if (event.fromDate && event.toDate && event.fromDate.toMillis() > event.toDate.toMillis()) {
          errors.push({ field: 'dateRange', message: 'End date must be after start date' });
        }
        if (event.fromDate && event.fromDate.toMillis() < now.toMillis()) {
          errors.push({ field: 'fromDate', message: 'Start date cannot be in the past' });
        }
      }

      if (event.startTime && event.endTime) {
        const startTime = new Date(event.startTime);
        const endTime = new Date(event.endTime);
        if (startTime >= endTime) {
          errors.push({ field: 'time', message: 'End time must be after start time' });
        }
      }

      return errors;
    };

    errors.push(...validateDates(event));

    if (event.isRepeatedEvent && (!event.repeatedDaysOfWeek || event.repeatedDaysOfWeek.length === 0)) {
      errors.push({ field: 'repeatedDaysOfWeek', message: 'Please select at least one day for repeated event.' });
    }

    if (event.isFree === undefined) {
      errors.push({ field: 'isFree', message: 'Please select whether the event is free or paid.' });
    }

    if (!event.isFree) {
      const price = typeof event.price === 'string' ? parseFloat(event.price) : event.price;
      if (!price || isNaN(price) || price < MINIMUM_PRICE) {
        errors.push({ field: 'price', message: `Please enter a valid price (minimum ₹${MINIMUM_PRICE})` });
      }
      
      if (!event.registrationTypes || event.registrationTypes.length === 0) {
        errors.push({ field: 'registrationTypes', message: 'Please add at least one registration type for the paid event.' });
      } else {
        // Check if all registration types have valid prices
        event.registrationTypes.forEach((type, index) => {
          const typePrice = typeof type.price === 'string' ? parseFloat(type.price) : type.price;
          if (!typePrice || isNaN(typePrice) || typePrice < MINIMUM_PRICE) {
            errors.push({ field: `registrationTypes[${index}].price`, message: `Please enter a valid price (minimum ₹${MINIMUM_PRICE}) for registration type "${type.name || `Type ${index + 1}`}"` });
          }
        });
      }
    }

    if (!event.category) {
      errors.push({ field: 'category', message: 'Please select a category for the event.' });
    }

    return errors;
  }, []);

  return { validateEvent };
};