import React, { useEffect, useState, useRef, useCallback } from 'react';
import { motion, useAnimation, useInView, AnimatePresence } from 'framer-motion';
import { Button } from '../components/ui/Button';
import clsx from 'clsx';
import { FaRocket, FaCalendarPlus, FaChevronRight, FaCalendarAlt, FaSearch, FaUsers, FaTicketAlt, FaComments, FaChartLine, FaMapMarkerAlt, FaHeart, FaStar, FaVideo, FaChartBar, FaCreditCard, FaBell, FaPalette, FaHeadset, FaMobile, FaClipboardList, FaTasks, FaMoneyBillWave, FaCommentSlash, FaBullhorn, FaSyncAlt, FaCheck } from 'react-icons/fa';
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';
import styles from './Landingv3.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase';
import { collection, addDoc } from 'firebase/firestore';

const pulse = {
  initial: { opacity: 0.7 },
  animate: {
    opacity: 1,
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse" as const
    }
  }
};

const buttonHoverAnimation = {
  rest: { scale: 1 },
  hover: { 
    scale: 1.05,
    transition: {
      duration: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 10
    }
  }
};

const typewriterParent = {
  animate: {
    transition: {
      staggerChildren: 0.3
    }
  }
};

const typewriterChild = {
  initial: {
    opacity: 0,
    y: 20
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      type: "spring",
      damping: 12,
      stiffness: 100
    }
  }
};

const cardVariants = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

interface CardProps {
  title: string;
  description: string;
  icon: any;
}

const cards: CardProps[] = [
  {
    title: "Seamless Planning",
    description: "Intuitive planning tools and automation",
    icon: FaRocket
  },
  {
    title: "Smart Analytics",
    description: "Real-time insights and analytics",
    icon: FaChartLine
  },
  {
    title: "Guest Experience",
    description: "Enhanced attendee interactions",
    icon: FaHeart
  },
  {
    title: "Ticketing & RSVP",
    description: "Easy ticket management system",
    icon: FaTicketAlt
  },
  {
    title: "Marketing Tools",
    description: "Integrated promotion solutions",
    icon: FaMobile
  },
  {
    title: "Support & Help",
    description: "24/7 dedicated assistance",
    icon: FaHeadset
  }
];

const Card: React.FC<CardProps> = ({ title, description, icon: Icon }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      controls.start({
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { duration: 0.5, ease: "easeOut" }
      });
    }
  }, [isInView, controls]);

  return (
    <motion.div
      ref={ref}
      className={styles.card}
      initial={{ opacity: 0, y: 50, scale: 0.9 }}
      animate={controls}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <Icon />
      <h3>{title}</h3>
      <p>{description}</p>
    </motion.div>
  );
};

const CardSlideshow: React.FC = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className={styles.cardsContainer}>
        <div 
          className={styles.slideshow}
          style={{
            transform: `translateX(-${currentIndex * (100 / cards.length)}%)`,
            width: `${cards.length * 100}%`,
            display: 'flex',
            justifyContent: 'center',
            background: 'transparent',
          }}
        >
          {cards.map((card, index) => (
            <div 
              key={index} 
              style={{ 
                flex: `0 0 ${100 / cards.length}%`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 1rem',
                background: 'transparent',
              }}
            >
              <Card {...card} />
            </div>
          ))}
        </div>
      </div>
      <button 
        className={styles.exploreButton}
        onClick={() => navigate('/attendee/home')}
      >
        Explore Features
      </button>
    </>
  );
};

const slides = [
  {
    title: "A Mountain of Tasks",
    description: "Tasks that lead to endless nights. Drowning in Details? Let EventAtEase Be Your Lifeline.",
    solution: "From Chaos to Control: Conquer Complexity. Make Event Management Easy with EventAtEase",
    image: "/images/organizer-overwhelmed.svg"
  },
  {
    title: "Missed Another Amazing Event?",
    description: "Another Weekend Wasted? Stop the Heartbreak. Start Your Journey to always remembered time",
    solution: "Unforgettable Experiences, At Your Fingertips. Find Your Tribe. Find Your Passion. Find Your Event.",
    image: "/images/attendee-sad.svg"
  },
  {
    title: "Event Communication Breakdown?",
    description: "The Dreaded Payment Glitch? EventAtEase to the Rescue!",
    solution: "Seamless Payments. Crystal-Clear Communication. Connect with Confidence using EventAtEase.",
    image: "/images/communication-breakdown.svg"
  }
];

const Slideshow = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState<'next' | 'prev'>('next');

  const nextSlide = () => {
    setDirection('next');
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setDirection('prev');
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToSlide = (index: number) => {
    setDirection(index > currentSlide ? 'next' : 'prev');
    setCurrentSlide(index);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 5000); // Auto advance every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
    <div className={styles.slideshowContainer}>
      <motion.div
        key={currentSlide}
        initial={{ opacity: 0, x: direction === 'next' ? 50 : -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: direction === 'next' ? -50 : 50 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className={styles.slide}
      >
        <div className={styles.slideContent}>
          <h3 className={styles.slideTitle}>{slides[currentSlide].title}</h3>
          <p className={styles.slideDescription}>{slides[currentSlide].description}</p>
          <img src={slides[currentSlide].image} alt={slides[currentSlide].title} className={styles.slideImage} />
          <p className={styles.slideDescription}>{slides[currentSlide].solution}</p>
          <Button
            variant="primary"
            size="lg"
            className="mt-4 bg-gradient-to-r from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800 text-white px-6 py-2 rounded-[100px] transform transition-all hover:scale-105 text-sm"
          >
            Get Started
          </Button>
        </div>
      </motion.div>

      <button 
        onClick={prevSlide} 
        className={clsx(styles.navButton, styles.prevButton)}
        aria-label="Previous slide"
      >
        <FaChevronRight className="w-6 h-6 text-purple-600 transform rotate-180" />
      </button>
      
      <button 
        onClick={nextSlide} 
        className={clsx(styles.navButton, styles.nextButton)}
        aria-label="Next slide"
      >
        <FaChevronRight className="w-6 h-6 text-purple-600" />
      </button>

      <div className={styles.progressContainer}>
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={clsx(
              styles.progressDot,
              currentSlide === index && styles.active
            )}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

// Problem Solution Card Types
interface ProblemSolutionCard {
  id: number;
  problemTitle: string;
  problemDescription: string;
  problemIcon: any;
  solutionTitle: string;
  solutionDescription: string;
  solutionIcon: any;
}

const problemSolutionCards: ProblemSolutionCard[] = [
  {
    id: 1,
    problemTitle: "Drowning in Event Details?",
    problemDescription: "Manual scheduling, endless spreadsheets, and constant back-and-forth emails making you lose sleep?",
    problemIcon: FaClipboardList,
    solutionTitle: "Smart Event Management",
    solutionDescription: "One dashboard for all your events. Automated scheduling, real-time updates, and seamless communication.",
    solutionIcon: FaTasks
  },
  {
    id: 2,
    problemTitle: "Payment Headaches?",
    problemDescription: "Chasing payments, managing refunds, and dealing with multiple payment platforms causing stress?",
    problemIcon: FaMoneyBillWave,
    solutionTitle: "Secure Payment Hub",
    solutionDescription: "Integrated payment system with automated tracking, instant refunds, and comprehensive financial reports.",
    solutionIcon: FaCreditCard
  },
  {
    id: 3,
    problemTitle: "Communication Chaos?",
    problemDescription: "Lost messages, delayed updates, and confused attendees leading to event day disasters?",
    problemIcon: FaCommentSlash,
    solutionTitle: "Unified Communication",
    solutionDescription: "Real-time notifications, automated reminders, and a central hub for all event communications.",
    solutionIcon: FaComments
  },
  {
    id: 4,
    problemTitle: "Marketing Struggles?",
    problemDescription: "Having trouble reaching your target audience and filling seats for your amazing events?",
    problemIcon: FaBullhorn,
    solutionTitle: "Smart Promotion Tools",
    solutionDescription: "Built-in marketing tools, social media integration, and targeted promotion features to boost attendance.",
    solutionIcon: FaChartLine
  }
];

const ProblemSolutionCard: React.FC<{ card: ProblemSolutionCard; index: number }> = ({ card, index }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const cardRef = useRef(null);
  const isInView = useInView(cardRef, { once: true, margin: "-100px" });

  const entranceVariants = {
    hidden: (index: number) => ({
      opacity: 0,
      x: index === 0 ? -100 : 0,
      y: index === 1 ? 100 : 0,
      rotate: index === 1 ? 180 : 0,
      scale: index === 2 ? 0.5 : 1,
    }),
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        duration: 1,
        bounce: 0.3,
      },
    },
  };

  const ProblemIcon = card.problemIcon;
  const SolutionIcon = card.solutionIcon;

  return (
    <motion.div
      ref={cardRef}
      custom={index}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      variants={entranceVariants}
      className={styles.problemSolutionCard}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <motion.div
        className={styles.cardInner}
        animate={{ rotateY: isFlipped ? 180 : 0 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
      >
        <div className={styles.cardFront}>
          <div className={styles.cardLabel}>Problem</div>
          <div className={styles.cardIcon}>
            <ProblemIcon />
          </div>
          <h3>{card.problemTitle}</h3>
          <p>{card.problemDescription}</p>
          <div className={styles.flipHint}>
            <motion.div
              animate={{ 
                rotateY: [0, 180],
                scale: [1, 1.1, 1]
              }}
              transition={{ 
                duration: 2,
                repeat: Infinity,
                repeatDelay: 2
              }}
              className={styles.flipIcon}
            >
              <FaSyncAlt />
            </motion.div>
            <span className={clsx(styles.flipToSeeText, "hidden md:block")}>Flip to see solution</span>
          </div>
          <div className={styles.arrowIcon}>
            <FaChevronRight />
          </div>
        </div>
        <div className={styles.cardBack}>
          <div className={styles.cardLabel}>Solution</div>
          <div className={styles.cardIcon}>
            <SolutionIcon />
          </div>
          <h3>{card.solutionTitle}</h3>
          <p>{card.solutionDescription}</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

const TypewriterText: React.FC<{ text: string }> = ({ text }) => {
  const characters = Array.from(text);
  
  return (
    <span className={styles.typewriterContainer}>
      {characters.map((char, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.1,
            delay: index * 0.05,
            ease: "easeIn"
          }}
        >
          {char}
        </motion.span>
      ))}
    </span>
  );
};

interface TestimonialProps {
  id: number;
  name: string;
  role: string;
  photoUrl: string;
  quote: string;
  fullStory: string;
  eventImages?: string[];
  highlightQuote?: string;
  featureHighlight: string;
}

const testimonials: TestimonialProps[] = [
  {
    id: 1,
    name: "Sarah Chen",
    role: "Event Organizer",
    photoUrl: "/testimonials/sarah.jpg",
    quote: "EventAtEase transformed how I manage corporate events. The automated scheduling feature saved me 15 hours per event!",
    fullStory: "As a corporate event planner, I used to spend countless hours coordinating schedules and managing RSVPs. EventAtEase's automated system changed everything. Now I can focus on creating memorable experiences instead of drowning in spreadsheets.",
    highlightQuote: "15 hours saved per event",
    featureHighlight: "Automated Scheduling",
    eventImages: ["/events/corporate-1.jpg", "/events/corporate-2.jpg"]
  },
  {
    id: 2,
    name: "Marcus Rodriguez",
    role: "Wedding Planner",
    photoUrl: "/testimonials/marcus.jpg",
    quote: "The real-time collaboration tools made coordinating with vendors and couples seamless. My clients love the transparency!",
    fullStory: "Wedding planning requires perfect coordination between multiple vendors, venues, and the couple. EventAtEase's real-time collaboration dashboard has become my command center.",
    highlightQuote: "Perfect vendor coordination",
    featureHighlight: "Real-time Collaboration"
  },
  {
    id: 3,
    name: "Emma Thompson",
    role: "Charity Event Coordinator",
    photoUrl: "/testimonials/emma.jpg",
    quote: "The donation tracking and budget management tools helped us raise 50% more funds than last year!",
    fullStory: "For non-profit events, every dollar counts. EventAtEase's financial tools gave us clear insights and helped optimize our fundraising strategy.",
    highlightQuote: "50% increase in fundraising",
    featureHighlight: "Financial Analytics"
  }
];

const TestimonialCard: React.FC<{ testimonial: TestimonialProps; onClick: () => void }> = ({ testimonial, onClick }) => {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [controls, isInView]);

  return (
    <motion.div
      ref={ref}
      className={styles.testimonialCard}
      onClick={onClick}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
      }}
      whileHover={{
        scale: 1.02,
        boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
        background: "linear-gradient(135deg, rgba(124, 58, 237, 0.05) 0%, rgba(91, 33, 182, 0.05) 100%)"
      }}
    >
      <div className={styles.testimonialHeader}>
        <img src={testimonial.photoUrl} alt={testimonial.name} className={styles.testimonialPhoto} />
        <div className={styles.testimonialInfo}>
          <h4>{testimonial.name}</h4>
          <p>{testimonial.role}</p>
        </div>
      </div>
      <p className={styles.testimonialQuote}>{testimonial.quote}</p>
      <span className={styles.testimonialFeature}>{testimonial.featureHighlight}</span>
    </motion.div>
  );
};

const TestimonialModal: React.FC<{ testimonial: TestimonialProps; onClose: () => void }> = ({ testimonial, onClose }) => {
  return (
    <motion.div 
      className={styles.modalOverlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div 
        className={styles.modalContent}
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
      >
        <button className={styles.modalClose} onClick={onClose}>×</button>
        {testimonial.highlightQuote && (
          <div className={styles.highlightQuote}>"{testimonial.highlightQuote}"</div>
        )}
        <div className={styles.modalHeader}>
          <img src={testimonial.photoUrl} alt={testimonial.name} />
          <div>
            <h3>{testimonial.name}</h3>
            <p>{testimonial.role}</p>
          </div>
        </div>
        <p className={styles.modalStory}>{testimonial.fullStory}</p>
        {testimonial.eventImages && (
          <div className={styles.modalImages}>
            {testimonial.eventImages.map((img, index) => (
              <img key={index} src={img} alt={`Event ${index + 1}`} />
            ))}
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

interface FormData {
  name: string;
  email: string;
  message: string;
  countryCode: string;
  phoneNumber: string;
  isCustomCountryCode: boolean;
}

const initialFormData: FormData = {
  name: '',
  email: '',
  message: '',
  countryCode: '+91',
  phoneNumber: '',
  isCustomCountryCode: false,
};

interface ValidationError {
  field: string;
  message: string;
}

const validateForm = (data: FormData): ValidationError[] => {
  const errors: ValidationError[] = [];

  // Validate name
  if (data.name.length < 2) {
    errors.push({ field: 'name', message: 'Name must be at least 2 characters long' });
  }
  if (data.name.length > 100) {
    errors.push({ field: 'name', message: 'Name must not exceed 100 characters' });
  }

  // Validate email
  const emailRegex = /^[^@]+@[^@]+\.[^@]+$/;
  if (!emailRegex.test(data.email)) {
    errors.push({ field: 'email', message: 'Please enter a valid email address' });
  }

  // Validate message
  if (data.message.length < 10) {
    errors.push({ field: 'message', message: 'Message must be at least 10 characters long' });
  }
  if (data.message.length > 1000) {
    errors.push({ field: 'message', message: 'Message must not exceed 1000 characters' });
  }

  // Validate phone number and country code together
  if (data.phoneNumber) {
    if (!/^[0-9]{10}$/.test(data.phoneNumber)) {
      errors.push({ field: 'phoneNumber', message: 'Phone number must be exactly 10 digits' });
    }
    if (!data.countryCode) {
      errors.push({ field: 'countryCode', message: 'Country code is required when phone number is provided' });
    } else if (!/^\+[0-9]{1,3}$/.test(data.countryCode)) {
      errors.push({ field: 'countryCode', message: 'Invalid country code format' });
    }
  }

  return errors;
};

const successVariants = {
  hidden: { 
    opacity: 0,
    y: 20,
    scale: 0.95
  },
  visible: { 
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.4,
      ease: "easeOut"
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    scale: 0.95,
    transition: {
      duration: 0.3
    }
  }
};

const Landingv3: React.FC = () => {
  const [currentGroup, setCurrentGroup] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [liveEvents, setLiveEvents] = useState(1234);
  const [selectedTestimonial, setSelectedTestimonial] = useState<TestimonialProps | null>(null);
  const controls = useAnimation();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  
  const cardsPerGroup = 4;
  const totalGroups = Math.ceil(cards.length / cardsPerGroup);

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const scrollPosition = container.scrollTop;
    const containerHeight = container.clientHeight;
    const newGroup = Math.round(scrollPosition / containerHeight);
    
    if (newGroup !== currentGroup && newGroup >= 0 && newGroup < totalGroups) {
      setCurrentGroup(newGroup);
    }
  }, [currentGroup, totalGroups]);

  const getCardClassName = (index: number) => {
    const cardPosition = index % cardsPerGroup;
    const cardGroup = Math.floor(index / cardsPerGroup);
    
    if (cardGroup === currentGroup) {
      return `${styles.card} ${styles.active}`;
    } else if (cardGroup === currentGroup - 1) {
      return `${styles.card} ${styles.prev}`;
    } else if (cardGroup === currentGroup + 1) {
      return `${styles.card} ${styles.next}`;
    }
    return styles.card;
  };

  useEffect(() => {
    controls.start("animate");
  }, [controls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLiveEvents(prev => prev + Math.floor(Math.random() * 3));
    }, 5000);

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(curr => (curr === cards.length - 4) ? 0 : curr + 1);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const [formErrors, setFormErrors] = useState<ValidationError[]>([]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.tagName === 'SELECT') {
      if (value === 'custom') {
        setFormData(prev => ({
          ...prev,
          isCustomCountryCode: true,
          countryCode: '+'
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          isCustomCountryCode: false,
          countryCode: value
        }));
      }
    } else {
      // Handle custom input
      let sanitizedValue = value;
      
      // If user tries to delete the '+', keep it
      if (!sanitizedValue.startsWith('+')) {
        sanitizedValue = '+' + sanitizedValue.replace(/[^0-9]/g, '');
      } else {
        // If there's already a '+', only allow numbers after it
        sanitizedValue = '+' + sanitizedValue.slice(1).replace(/[^0-9]/g, '');
      }

      setFormData(prev => ({
        ...prev,
        countryCode: sanitizedValue
      }));
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only keep the first 10 digits
    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
    setFormData(prev => ({
      ...prev,
      phoneNumber: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');
    setFormErrors([]);

    // Validate form
    const errors = validateForm(formData);
    if (errors.length > 0) {
      setFormErrors(errors);
      setIsSubmitting(false);
      return;
    }

    const submissionData = {
      ...formData,
      timestamp: new Date().toISOString(),
    };

    // Only include countryCode if phoneNumber is present
    const { isCustomCountryCode, countryCode, ...baseData } = submissionData;
    const dataToSubmit = submissionData.phoneNumber 
      ? { ...baseData, countryCode } 
      : baseData;

    try {
      await addDoc(collection(db, 'landing-page-contacts'), dataToSubmit);
      setSubmitStatus('success');
      setFormData(initialFormData);
      
      // Reset form after 3 seconds
      setTimeout(() => {
        setSubmitStatus('idle');
      }, 3000);
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
    
    setIsSubmitting(false);
  };

  return (
    <div className="relative">
      <div className="relative h-screen overflow-hidden">
        {/* Video Background */}
        <div
          className={clsx(
            "absolute inset-0 z-0 transition-transform duration-300",
            isScrolled ? "scale-95 -translate-y-[5%]" : "scale-100"
          )}
        >
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full object-cover"
          >
            <source src="/videos/events-montage.mp4" type="video/mp4" />
          </video>
          {/* Gradient Overlay */}
          <motion.div
            initial="initial"
            animate="animate"
            variants={pulse}
            className="absolute inset-0 bg-gradient-to-br from-purple-600/80 via-purple-500/70 to-white/90 backdrop-blur-[2px]"
          />
        </div>

        {/* Hero Content */}
        <div className="container mx-auto h-full relative z-1 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col items-center justify-center h-full text-center text-white space-y-4 sm:space-y-8 mx-auto max-w-5xl pt-12 sm:pt-0">
            <div className="space-y-2 sm:space-y-3 mt-4 sm:mt-0">
              <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-100 to-purple-200 drop-shadow-lg">
                Don't Just Dream It.
              </h1>
              <h1 className="text-2xl sm:text-4xl md:text-5xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white via-purple-100 to-purple-200 drop-shadow-lg">
                Don't Just Discuss It
              </h1>
              <div className="mt-4 sm:mt-6">
                <motion.div
                  variants={typewriterParent}
                  initial="initial"
                  animate={controls}
                  className="overflow-hidden"
                >
                  <motion.h1 
                    className="text-4xl sm:text-7xl md:text-8xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-purple-200"
                  >
                    {["Do", "It.", "Be", "There."].map((word, i) => (
                      <motion.span
                        key={i}
                        variants={typewriterChild}
                        className="inline-block mx-1"
                      >
                        {word}
                      </motion.span>
                    ))}
                  </motion.h1>
                </motion.div>
              </div>
            </div>

            <p className="text-lg sm:text-2xl text-white/90 font-light tracking-wide">
              Where extraordinary events ignite.
            </p>

            <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-6 mt-4 sm:mt-8">
              <motion.div
                initial="rest"
                whileHover="hover"
                variants={buttonHoverAnimation}
                className="w-full sm:w-auto"
              >
                <Button
                  size="lg"
                  variant="primary"
                  className="rounded-full bg-purple-600 hover:bg-purple-700 px-8 py-3 text-lg w-full sm:w-auto"
                  icon={<FaRocket className="w-4 h-4" />}
                  onClick={() => navigate('/attendee/home')}
                >
                  <span>Explore Events Now</span>
                </Button>
              </motion.div>

              <motion.div
                initial="rest"
                whileHover="hover"
                variants={buttonHoverAnimation}
                className="w-full sm:w-auto"
              >
                <Button
                  size="lg"
                  variant="outline"
                  className="rounded-full bg-white/10 backdrop-blur-sm text-white hover:bg-white hover:text-purple-600 px-8 py-3 text-lg border-2 border-white w-full sm:w-auto"
                  icon={<FaCalendarPlus className="w-4 h-4" />}
                  onClick={() => navigate('/organizer/create-event')}
                >
                  <span>Create Your Event</span>
                </Button>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Transform Your Events Section */}
      <section className={styles.transformSection}>
        <div className={styles.transformContent}>
          <motion.div
            className={styles.transformHeader}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
         
            
            <motion.div 
              className={styles.titleSection}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <h2 className={styles.sectionTitle}>
                <span>Experience the future of events</span>
        
              </h2>
            </motion.div>
          </motion.div>

          <motion.div 
            className={styles.transformFeatures}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.6, duration: 0.8 }}
          >
            <div className={styles.featureItem}>
              <FaRocket className={styles.featureIcon} />
              <h3>Streamlined Planning</h3>
              <p>Automate repetitive tasks and focus on creating exceptional experiences</p>
            </div>
            <div className={styles.featureItem}>
              <FaChartLine className={styles.featureIcon} />
              <h3>Real-time Analytics</h3>
              <p>Make data-driven decisions with comprehensive event insights</p>
            </div>
            <div className={styles.featureItem}>
              <FaUsers className={styles.featureIcon} />
              <h3>Enhanced Engagement</h3>
              <p>Create interactive experiences that keep attendees connected</p>
            </div>
            <div className={styles.featureItem}>
              <FaTicketAlt className={styles.featureIcon} />
              <h3>Smart Ticketing</h3>
              <p>Seamless registration and attendance management system</p>
            </div>
            <div className={styles.featureItem}>
              <FaBullhorn className={styles.featureIcon} />
              <h3>Marketing Tools</h3>
              <p>Promote your events effectively across multiple channels</p>
            </div>
            <div className={styles.featureItem}>
              <FaHeadset className={styles.featureIcon} />
              <h3>24/7 Support</h3>
              <p>Expert assistance whenever you need it, around the clock</p>
            </div>
          </motion.div>

          <motion.div 
            className={styles.transformCTA}
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8, duration: 0.5 }}
          >
            <Button 
              size="lg"
              variant="primary"
              className="rounded-full bg-purple-600 hover:bg-purple-700 px-8 py-3 text-lg"
              onClick={() => navigate('/attendee/home')}
            >
              Start Transforming Today
            </Button>
          </motion.div>
        </div>
      </section>

      {/* Who Is This For Section */}
      <section className={styles.whoIsThisForSection}>
    
      </section>

      {/* Problem/Solution Cards Section */}
      <section className={styles.problemSolutionSection}>
      <motion.div
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
          className={styles.sectionContent}
        >

            <motion.div 
              className={styles.titleSection}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <h2 className={styles.sectionTitle}>
                <span>Discover the Simplicity of EventAtEase:</span>
                <span className={styles.highlight}>
                  <TypewriterText text="We Deliver the Difference." />
                </span>
              </h2>
            </motion.div>
        </motion.div>
        <WhoIsThisFor />
      </section>

      {/* The EventAtEase Effect Section */}
      <section className={styles.sectionWrapper}>
        <motion.div
          className={styles.sectionContent}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <motion.h2 
            className={styles.sectionTitle}
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ 
              duration: 0.8,
              delay: 0.2
            }}
          >
            The EventAtEase Effect
          </motion.h2>
          <motion.div 
            className={styles.sectionSubtitleWrapper}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ 
              duration: 0.6,
              delay: 0.4
            }}
          >
            <p className={styles.sectionSubtitle}>
              Transforming Ordinary into Extraordinary, One Event at a Time
            </p>
          </motion.div>
        </motion.div>
        
        <div className={styles.testimonialGrid}>
          {testimonials.map((testimonial) => (
            <TestimonialCard
              key={testimonial.id}
              testimonial={testimonial}
              onClick={() => setSelectedTestimonial(testimonial)}
            />
          ))}
        </div>

        {selectedTestimonial && (
          <TestimonialModal
            testimonial={selectedTestimonial}
            onClose={() => setSelectedTestimonial(null)}
          />
        )}
      </section>

      {/* Contact Section */}
      <section className={styles.contactSection}>
        <div className={styles.contactContainer}>
          <div className={styles.contactContent}>
            <motion.div 
              className={styles.contactText}
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6 }}
            >
              <h2 className={styles.sectionTitle}>Have a question?</h2>
              <p className={styles.contactDescription}>
                Transform your event planning journey with EventAtEase. 
                Let's create extraordinary experiences together.
              </p>
              <div className={styles.socialProof}>
                <div className={styles.stat}>
                  <span className={styles.statNumber}>10K+</span>
                  <span className={styles.statLabel}>Events</span>
                  <span className={styles.statLabel}>Planned</span>
                </div>
                <div className={styles.stat}>
                  <span className={styles.statNumber}>98%</span>
                  <span className={styles.statLabel}>Satisfaction</span>
                  <span className={styles.statLabel}>Rate</span>
                </div>
                <div className={styles.stat}>
                  <span className={styles.statNumber}>24/7</span>
                  <span className={styles.statLabel}>Support</span>
                  <span className={styles.statLabel}>Available</span>
                </div>
              </div>
            </motion.div>

            <form onSubmit={handleSubmit} className={styles.contactForm}>
              <AnimatePresence>
                {submitStatus === 'success' ? (
                  <motion.div
                    className={styles.formSuccess}
                    variants={successVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  >
                    <div className={styles.checkmark}>
                      <FaCheck />
                    </div>
                    <div className={styles.successMessage}>
                      Thank you for your message! We'll get back to you soon.
                    </div>
                  </motion.div>
                ) : (
                  <>
                    <div className={styles.formGroup}>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name*"
                        required
                        className={styles.formInput}
                        value={formData.name}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email*"
                        required
                        className={styles.formInput}
                        value={formData.email}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <div className={styles.phoneInputGroup}>
                        {!formData.isCustomCountryCode ? (
                          <select
                            name="countryCode"
                            className={clsx(styles.formInput, styles.countryCode)}
                            value={formData.countryCode}
                            onChange={handleCountryCodeChange}
                            disabled={isSubmitting}
                          >
                            <option value="+91">+91 (IN)</option>
                            <option value="+1">+1 (US)</option>
                            <option value="+44">+44 (UK)</option>
                            <option value="+61">+61 (AU)</option>
                            <option value="+86">+86 (CN)</option>
                            <option value="+81">+81 (JP)</option>
                            <option value="custom">Enter Custom Code</option>
                          </select>
                        ) : (
                          <div className={styles.customCodeContainer}>
                            <input
                              type="text"
                              placeholder="+XX"
                              className={clsx(styles.formInput, styles.countryCode)}
                              value={formData.countryCode}
                              onChange={handleCountryCodeChange}
                              disabled={isSubmitting}
                              minLength={2}
                              maxLength={4}
                            />
                            <button 
                              type="button"
                              className={styles.dropdownArrow}
                              onClick={() => {
                                setFormData(prev => ({
                                  ...prev,
                                  isCustomCountryCode: false,
                                  countryCode: '+91'
                                }));
                              }}
                              aria-label="Switch to country code selection"
                            />
                          </div>
                        )}
                        <input
                          type="tel"
                          inputMode="numeric"
                          pattern="[0-9]{10}"
                          name="phoneNumber"
                          placeholder="Phone Number (Optional)"
                          className={clsx(styles.formInput, styles.phoneNumber)}
                          value={formData.phoneNumber}
                          onChange={handlePhoneNumberChange}
                          disabled={isSubmitting}
                          minLength={10}
                          maxLength={10}
                        />
                      </div>
                    </div>
                    <div className={styles.formGroup}>
                      <textarea
                        name="message"
                        placeholder="Your Message*"
                        required
                        className={styles.formTextarea}
                        value={formData.message}
                        onChange={handleInputChange}
                        disabled={isSubmitting}
                      />
                    </div>
                  </>
                )}
              </AnimatePresence>
              {submitStatus === 'error' && (
                <div className="text-red-500 mb-4">
                  Sorry, there was an error submitting your message. Please try again.
                </div>
              )}
              {formErrors.length > 0 && (
                <div className={styles.errorContainer}>
                  {formErrors.map((error, index) => (
                    <div key={index} className={styles.errorMessage}>
                      {error.message}
                    </div>
                  ))}
                </div>
              )}
              <div className={styles.submitButtonContainer}>
                <Button 
                  type="submit"
                  className={clsx("rounded-full", styles.submitButton)}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Submit'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </section>

     
    </div>
  );
};

const WhoIsThisFor = () => {
  const [activeTab, setActiveTab] = useState<'attendee' | 'organizer'>('attendee');
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);

  const attendeePoints = [
    {
      icon: FaSearch,
      title: "Easy Discovery",
      description: "Find events that match your interests"
    },
    {
      icon: FaTicketAlt,
      title: "Event Variety",
      description: "Concerts, workshops, conferences & more"
    },
    {
      icon: FaChartLine,
      title: "Smart Search",
      description: "AI-powered event recommendations"
    },
    {
      icon: FaBell,
      title: "Stay Updated",
      description: "Real-time notifications & reminders"
    },
    {
      icon: FaCreditCard,
      title: "Easy Payments",
      description: "Multiple secure payment options"
    },

     
    {
      icon: FaCalendarAlt,
      title: "Calendar Sync",
      description: "Sync events with your calendar"
    },
    {
      icon: FaHeart,
      title: "Save Events",
      description: "Bookmark your favorite events"
    },
    {
      icon: FaVideo,
      title: "Live Events",
      description: "Join virtual events seamlessly"
    },
 
  ];

  const organizerPoints = [
    {
      icon: FaRocket,
      title: "Quick Setup",
      description: "Create events in minutes"
    },
    {
      icon: FaCalendarAlt,
      title: "Flexible Events",
      description: "Single or multi-day events"
    },
    {
      icon: FaChartLine,
      title: "AI Tools",
      description: "Enhanced event descriptions & images"
    },
    {
      icon: FaMoneyBillWave,
      title: "Secure Payments",
      description: "Multiple payment methods"
    },
    {
      icon: FaChartBar,
      title: "Analytics",
      description: "Track event performance"
    },
    {
      icon: FaUsers,
      title: "Build Trust",
      description: "Verified organizer status"
    },
    {
      icon: FaPalette,
      title: "Customization",
      description: "Brand your event page"
    },
  
    {
      icon: FaBullhorn,
      title: "Promotion",
      description: "Built-in marketing tools"
    },
    
  ];

  return (
    <motion.section 
      ref={ref}
      className={styles.whoIsThisFor}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { 
          opacity: 1, 
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut"
          }
        }
      }}
    >
      <div className={styles.whoIsThisForContent}>
        

        <div className={styles.tabContainer}>
          <button
            className={clsx(
              styles.tab,
              styles.tabAttendee,
              activeTab === 'attendee' && styles.active
            )}
            onClick={() => setActiveTab('attendee')}
          >
            For Attendees
          </button>
          <button
            className={clsx(
              styles.tab,
              styles.tabOrganizer,
              activeTab === 'organizer' && styles.active
            )}
            onClick={() => setActiveTab('organizer')}
          >
            For Organizers
          </button>
        </div>

        <AnimatePresence mode="wait">
          {activeTab === 'attendee' ? (
            <motion.div
              key="attendee"
              className={styles.featureCardsGrid}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              {attendeePoints.map((point, index) => (
                <motion.div
                  key={index}
                  className={styles.featureCard}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className={styles.featureIconWrapper}>
                    <point.icon className={styles.featureIcon} />
                  </div>
                  <h4 className={styles.featureTitle}>{point.title}</h4>
                  <p className={styles.featureDescription}>{point.description}</p>
                </motion.div>
              ))}
            </motion.div>
          ) : (
            <motion.div
              key="organizer"
              className={styles.featureCardsGrid}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              {organizerPoints.map((point, index) => (
                <motion.div
                  key={index}
                  className={styles.featureCard}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <div className={styles.featureIconWrapper}>
                    <point.icon className={styles.featureIcon} />
                  </div>
                  <h4 className={styles.featureTitle}>{point.title}</h4>
                  <p className={styles.featureDescription}>{point.description}</p>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );
};

export default Landingv3;
