import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../../components/ui/Card";
import { Progress } from "../../../components/ui/Progress";
import { Avatar, AvatarImage, AvatarFallback } from "../../../components/ui/Avatar";
import { UserIcon } from 'lucide-react';

interface EventAttendeesProps {
  attendees: Array<{ id: string; name: string; avatar: string }>;
  maxCapacity: number;
}

export const EventAttendees: React.FC<EventAttendeesProps> = ({ attendees, maxCapacity }) => (
  <Card className="bg-purple-50 mb-6 md:w-64 flex-shrink-0">
    <CardHeader>
      <CardTitle className="text-lg font-semibold text-purple-800">Attendees</CardTitle>
    </CardHeader>
    <CardContent className="space-y-4">
      <div className="flex justify-between">
        <span className="text-sm font-medium">{attendees.length} attending</span>
        <span className="text-sm text-gray-600">/ {maxCapacity} spots</span>
      </div>
      <div className="bg-gray-200 rounded-full h-2.5 h-2">
        <div
          className="bg-purple-600 h-2.5 rounded-full"
          style={{ width: `${(attendees.length / maxCapacity) * 100}%` }}
          role="progressbar"
          aria-valuenow={(attendees.length / maxCapacity) * 100}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
      <div className="flex items-center space-x-2">
        {attendees.slice(0, 5).map((attendee) => (
          <Avatar key={attendee.id} className="w-8 h-8 inline-flex items-center justify-center bg-purple-100">
            {attendee.avatar ? (
              <AvatarImage src={attendee.avatar} alt={attendee.name} className="w-full h-full object-cover" />
            ) : (
              <AvatarFallback className="w-full h-full flex items-center justify-center text-xs font-medium bg-purple-200 text-purple-800">
                {attendee.name ? attendee.name.charAt(0).toUpperCase() : <UserIcon className="w-4 h-4" />}
              </AvatarFallback>
            )}
          </Avatar>
        ))}
        {attendees.length > 5 && (
          <Avatar className="w-8 h-8 inline-flex items-center justify-center bg-purple-200 text-purple-800">
            <AvatarFallback className="text-xs font-medium">
              +{attendees.length - 5}
            </AvatarFallback>
          </Avatar>
        )}
      </div>
    </CardContent>
  </Card>
);