import React from 'react';
import { FaWhatsapp, FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { X as CloseIcon, Copy, Mail } from 'lucide-react';
import { RiTwitterXFill } from 'react-icons/ri';
import { Button } from './ui/Button';
import { toast } from 'sonner';
import { createPortal } from 'react-dom';

interface ShareEventModalProps {
  isOpen: boolean;
  onClose: () => void;
  eventId: string;
  eventTitle: string;
}

export const ShareEventModal: React.FC<ShareEventModalProps> = ({
  isOpen,
  onClose,
  eventId,
  eventTitle,
}) => {
  if (!isOpen) return null;

  const shareUrl = `${window.location.origin}/share/${eventId}`;
  const shareText = `Check out this event: ${eventTitle}`;
  const fullShareText = `${shareText} ${shareUrl}`;

  // Platform-specific sharing URLs
  const shareUrls = {
    whatsapp: {
      mobile: `whatsapp://send?text=${encodeURIComponent(fullShareText)}`,
      web: `https://wa.me/?text=${encodeURIComponent(fullShareText)}`,
    },
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`,
    instagram: `instagram://library?AssetPath=${encodeURIComponent(shareUrl)}`,
    email: `mailto:?subject=${encodeURIComponent(eventTitle)}&body=${encodeURIComponent(fullShareText)}`,
  } as const;

  type SharePlatform = keyof typeof shareUrls;

  const handleShare = async (platform: SharePlatform) => {
    // Try native sharing first
    if (navigator.share && (platform === 'whatsapp' || platform === 'instagram')) {
      try {
        await navigator.share({
          title: eventTitle,
          text: shareText,
          url: shareUrl,
        });
        return;
      } catch (err) {
        console.error('Error sharing:', err);
        // Fall through to platform-specific sharing
      }
    }

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    let urlToOpen: string;
    if (platform === 'whatsapp') {
      urlToOpen = isMobile ? shareUrls.whatsapp.mobile : shareUrls.whatsapp.web;
    } else {
      urlToOpen = shareUrls[platform];
    }

    window.open(urlToOpen, '_blank', 'noopener,noreferrer');
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      toast.success('Link copied to clipboard!');
    } catch (err) {
      console.error('Error copying link:', err);
      toast.error('Failed to copy link');
    }
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const modalContent = (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-[9999] p-4 transition-all duration-300 ease-in-out" 
      onClick={onClose}
    >
      <div 
        className="relative bg-white dark:bg-gray-800 p-6 rounded-2xl max-w-md w-full mx-auto shadow-2xl transform transition-all duration-300 ease-in-out"
        onClick={handleModalClick}
      >
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-100">Share this event</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 transition-colors duration-200"
            aria-label="Close modal"
          >
            <CloseIcon className="h-6 w-6" />
          </button>
        </div>
        
        <div className="mb-8">
          <div className="flex items-center gap-2 p-4 bg-gray-50 dark:bg-gray-700/50 rounded-xl border border-gray-100 dark:border-gray-700">
            <input
              type="text"
              value={shareUrl}
              readOnly
              className="flex-1 bg-transparent border-none focus:outline-none text-gray-700 dark:text-gray-300 text-sm font-medium"
            />
            <Button
              onClick={handleCopyLink}
              variant="outline"
              size="sm"
              className="flex items-center gap-2 text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition-all duration-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Copy className="h-4 w-4" />
              Copy
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
          <button
            onClick={() => handleShare('whatsapp')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-[#25D366]/10 group-hover:bg-[#25D366]/20 transition-colors duration-200">
              <FaWhatsapp size={24} className="text-[#25D366]" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">WhatsApp</span>
          </button>

          <button
            onClick={() => handleShare('facebook')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-[#1877F2]/10 group-hover:bg-[#1877F2]/20 transition-colors duration-200">
              <FaFacebookF size={24} className="text-[#1877F2]" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Facebook</span>
          </button>

          <button
            onClick={() => handleShare('twitter')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-[#000000]/10 group-hover:bg-[#000000]/20 transition-colors duration-200">
              <RiTwitterXFill size={24} className="text-black dark:text-white" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">X (Twitter)</span>
          </button>

          <button
            onClick={() => handleShare('linkedin')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-[#0A66C2]/10 group-hover:bg-[#0A66C2]/20 transition-colors duration-200">
              <FaLinkedinIn size={24} className="text-[#0A66C2]" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">LinkedIn</span>
          </button>

          <button
            onClick={() => handleShare('instagram')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-[#E4405F]/10 group-hover:bg-[#E4405F]/20 transition-colors duration-200">
              <FaInstagram size={24} className="text-[#E4405F]" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Instagram</span>
          </button>

          <button
            onClick={() => handleShare('email')}
            className="flex flex-col items-center gap-3 p-4 rounded-xl hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-all duration-200 group"
          >
            <div className="p-2 rounded-full bg-gray-500/10 group-hover:bg-gray-500/20 transition-colors duration-200">
              <Mail size={24} className="text-gray-500" />
            </div>
            <span className="text-sm font-medium text-gray-700 dark:text-gray-300">Email</span>
          </button>
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};
