import { BlogPost } from '../../types';

export const platformOverviewPost: BlogPost = {
    id: '1',
    title: 'EventAtEase: Your All-in-One Platform for Creating and Discovering Local Events',
    author: 'EventAtEase Team',
    date: '2024-01-15',
    summary: 'Discover how EventAtEase transforms the way local organizers create and manage events while helping participants find experiences that match their interests.',
    imageUrl: `${process.env.PUBLIC_URL}/blog/platform-overview.jpg`,
    readTime: '8 min read',
    slug: 'eventatease-platform-overview',
    content: `In a world where connecting with your community matters more than ever, organizing and discovering local events shouldn't require technical expertise or countless hours of preparation. EventAtEase transforms the way local organizers create workshops, training sessions, community gatherings, and professional meetups, while helping participants easily discover experiences that match their interests.`,
    sections: [
        {
            title: 'Creating Professional Events Made Simple',
            content: `Picture this: Sarah, a yoga instructor, wanted to start offering weekly workshops but struggled with marketing and managing registrations through social media. With EventAtEase, she created her first workshop series in minutes, complete with professional imagery and compelling descriptions generated by our AI tools. The platform guided her through each step, from selecting dates and times to setting up secure payment processing.

Our intelligent event creation system helps organizers like Sarah focus on what matters most—creating valuable experiences for their participants. Here's how EventAtEase streamlines the process:`,
            subsections: [
                {
                    title: 'Smart Event Setup',
                    content: `Unlike basic event platforms that leave you guessing, EventAtEase's intuitive interface asks the right questions at the right time. Whether you're planning a one-time pottery workshop or a recurring professional development seminar, you'll find options tailored to your specific needs. Set your event type, choose between public or private visibility, and specify important details like location, duration, and frequency—all through a conversational interface that feels natural.`
                },
                {
                    title: 'Professional Polish Without the Effort',
                    content: `What sets EventAtEase apart is our commitment to making every event look professional. Our AI-powered description enhancement tool transforms basic event information into engaging, SEO-friendly descriptions that attract participants. Need eye-catching visuals? Our image generation feature creates custom event banners that perfectly capture your event's essence—no graphic design skills required.`
                },
                {
                    title: 'Secure Payments and Instant Payouts with Razorpay',
                    content: `Set up your event as free, fixed-price, or with tiered pricing options. Our integration with Razorpay, India's leading payment gateway, ensures secure transactions for both organizers and attendees. Organizers receive instant payouts directly to their bank accounts, while attendees enjoy a variety of payment options including UPI, net banking, and credit/debit cards. Our transparent pricing structure means you only pay a small platform fee when you successfully host paid events. Free events? They're always free to list.`
                }
            ]
        },
        {
            title: 'Discovering Your Next Experience Made Easy',
            content: `Meet Tom, who recently moved to a new city and wanted to explore local activities. Instead of searching through multiple websites and social media platforms, he used EventAtEase's intelligent filtering system to find exactly what he was looking for. By filtering events by location, skill level, and his interests in photography and cooking, Tom quickly found a beginner-friendly food photography workshop in his neighborhood.`,
            subsections: [
                {
                    title: 'Smart Discovery Features',
                    content: 'Our event marketplace goes beyond basic search functionality:',
                    items: [
                        'Location-aware filtering shows you relevant events nearby',
                        'Skill level indicators help you find experiences matching your expertise',
                        'Calendar integration lets you check event timing against your schedule',
                        'Detailed event pages provide all the information you need in one place',
                        'One-click registration and secure payment processing save you time'
                    ]
                },
                {
                    title: 'Streamlined Registration and Management',
                    content: 'Both organizers and attendees benefit from our automated system that handles:',
                    items: [
                        'Instant registration confirmations',
                        'Automated reminder notifications',
                        'Calendar invites with all event details',
                        'Attendance tracking and participant communication',
                        'Secure payment processing through Razorpay with instant payouts',
                        'Automated refund management for cancellations'
                    ]
                }
            ]
        },
        {
            title: 'The Technology Behind the Simplicity',
            content: `EventAtEase's powerful technology works behind the scenes to make event management effortless. Our platform integrates with popular calendar systems, payment processors, and email providers, ensuring a smooth experience for everyone involved. The intelligent filtering system learns from user behavior to improve event recommendations, while our AI tools help organizers create professional-looking events without technical expertise.`
        },
        {
            title: 'Pricing That Makes Sense',
            content: 'We believe in transparent pricing that aligns with your success. Organizers can:',
            items: [
                'List and manage free events at no cost',
                'Pay a small platform fee only when hosting successful paid events',
                'Access all professional tools, including AI features, without additional charges',
                'Benefit from secure payment processing with competitive transaction fees'
            ]
        },
        {
            title: 'Start Your EventAtEase Journey Today',
            content: `Ready to transform the way you create or discover local events? Take the first step:`,
            subsections: [
                {
                    title: 'For Organizers',
                    content: `Create your first event today and experience how our AI-powered tools and intuitive interface can help you reach new audiences. Sign up now and get your first three months of premium features free.`
                },
                {
                    title: 'For Event Seekers',
                    content: `Browse events in your area and discover experiences that match your interests. Our intelligent filtering system helps you find the perfect activities, whether you're looking for professional development opportunities or fun weekend workshops.`
                }
            ]
        }
    ]
};
