import { Event, OfflineRegistration } from '../types/Event';
import { storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import QRCode from 'qrcode';
import { TDocumentDefinitions, Content, ContentStack, Column } from 'pdfmake/interfaces';

// Initialize pdfMake with fonts
(pdfMake as any).vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfFonts;

// Simple user type for PDF generation
interface PdfUserInfo {
  name: string;
  email: string;
  phone: string;
}

interface PdfOrderInfo {
  id: string;
  amount: number;
  registrationType: string;
}

// Convert registration to user info
const createPdfUserInfo = (registration: OfflineRegistration): PdfUserInfo => ({
  name: registration.name,
  email: registration.email,
  phone: registration.phone
});

// Generate QR code for the ticket
const generateQrCode = async (eventId: string, registration: OfflineRegistration): Promise<string> => {
  const qrData = JSON.stringify({
    eventId,
    name: registration.name,
    phone: registration.phone,
    type: registration.registrationType,
    amount: registration.amountPaid,
    timestamp: registration.timestamp
  });
  return await QRCode.toDataURL(qrData);
};

// Helper function to create text content
const createTextContent = (
  text: string, 
  style?: string, 
  margin?: [number, number, number, number],
  color?: string
): Content => ({
  text,
  ...(style ? { style } : {}),
  ...(margin ? { margin } : {}),
  ...(color ? { color } : {})
});

// Helper function to create a divider line
const createDivider = (color: string = '#E5DBFF'): Content => ({
  canvas: [
    {
      type: 'line',
      x1: 0,
      y1: 0,
      x2: 515,
      y2: 0,
      lineWidth: 1,
      lineColor: color
    }
  ],
  margin: [0, 10, 0, 10]
});

// Generate PDF content
const generatePdfContent = async (
  userInfo: PdfUserInfo,
  event: Event,
  registration: OfflineRegistration,
  qrCodeData: string
): Promise<TDocumentDefinitions> => {
  const eventDate = event.date ? event.date.toDate().toLocaleDateString() : 'TBD';
  const registrationDate = registration.timestamp.toDate().toLocaleDateString();
  
  // Create header with event title and booking confirmation
  const headerStack: ContentStack = {
    stack: [
      {
        columns: [
          {
            width: '*' as any,
            stack: [
              createTextContent('EventAtEase', 'brand', [0, 0, 0, 5], '#4A3880'),
              createTextContent(event.title || 'Event', 'header', [0, 0, 0, 5], '#6B4EAF'),
              createTextContent('Booking Confirmation', 'subheader', [0, 0, 0, 10], '#9F85FF')
            ]
          },
          {
            width: 'auto' as any,
            stack: [
              createTextContent('Registration ID', 'label', [0, 0, 0, 2], '#6B4EAF'),
              createTextContent(registration.timestamp.toDate().getTime().toString().slice(-8), 'value'),
              {
                image: qrCodeData,
                fit: [100, 100],
                margin: [0, 10, 0, 0]
              },
              createTextContent('Scan for Entry', 'note', [0, 5, 0, 0], '#666666')
            ],
            alignment: 'right'
          }
        ] as Column[]
      },
      createDivider('#9F85FF')
    ]
  };

  // Create combined details section with two columns
  const detailsSection: Content = {
    columns: [
      {
        width: '*' as any,
        stack: [
          createTextContent('Event Details', 'sectionHeader', [0, 5, 0, 10], '#4A3880'),
          {
            columns: [
              {
                width: 'auto' as any,
                stack: [
                  createTextContent('Date:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Time:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Location:', 'label', [0, 0, 0, 5], '#6B4EAF')
                ]
              },
              {
                width: '*' as any,
                stack: [
                  createTextContent(eventDate, 'value', [10, 0, 0, 5]),
                  createTextContent(event.startTime || 'TBD', 'value', [10, 0, 0, 5]),
                  createTextContent(event.location || 'Online', 'value', [10, 0, 0, 5])
                ]
              }
            ] as Column[]
          }
        ]
      },
      {
        width: '*' as any,
        stack: [
          createTextContent('Attendee Details', 'sectionHeader', [0, 5, 0, 10], '#4A3880'),
          {
            columns: [
              {
                width: 'auto' as any,
                stack: [
                  createTextContent('Name:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Email:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Phone:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Registration Type:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Amount Paid:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Number of People:', 'label', [0, 0, 0, 5], '#6B4EAF'),
                  createTextContent('Registration Date:', 'label', [0, 0, 0, 5], '#6B4EAF')
                ]
              },
              {
                width: '*' as any,
                stack: [
                  createTextContent(userInfo.name, 'value', [10, 0, 0, 5]),
                  createTextContent(userInfo.email, 'value', [10, 0, 0, 5]),
                  createTextContent(userInfo.phone, 'value', [10, 0, 0, 5]),
                  createTextContent(registration.registrationType, 'value', [10, 0, 0, 5]),
                  createTextContent(`₹${registration.amountPaid}`, 'value', [10, 0, 0, 5]),
                  createTextContent(registration.numberOfPeople.toString(), 'value', [10, 0, 0, 5]),
                  createTextContent(registrationDate, 'value', [10, 0, 0, 5])
                ]
              }
            ] as Column[]
          }
        ]
      }
    ] as Column[]
  };

  // Create notes section with two columns
  const notesSection: ContentStack = {
    stack: [
      createTextContent('Important Information', 'sectionHeader', [0, 10, 0, 10], '#4A3880'),
      {
        columns: [
          {
            width: '*' as any,
            stack: [
              createTextContent('Event Guidelines:', 'note', [0, 0, 0, 5], '#6B4EAF'),
              createTextContent('• Please arrive 15 minutes before the event start time', 'noteItem'),
              createTextContent('• This ticket is non-transferable', 'noteItem'),
              createTextContent('• Ticket is valid only for the specified date and time', 'noteItem'),
              createTextContent('• Proof of identity may be required for entry', 'noteItem'),
              createTextContent('• Entry is subject to security screening', 'noteItem')
            ]
          },
          {
            width: '*' as any,
            stack: [
              createTextContent('Additional Information:', 'note', [0, 0, 0, 5], '#6B4EAF'),
              createTextContent('• No refunds or exchanges will be permitted', 'noteItem'),
              createTextContent('• Generated on-site by event organizer', 'noteItem'),
              createTextContent('• Serves as validation mechanism for walk-in attendees', 'noteItem'),
              createTextContent('• Attendees must comply with event venue rules', 'noteItem'),
              createTextContent('• Keep digital or printed copy of ticket for verification', 'noteItem'),
              createTextContent('• Check event details and special instructions beforehand', 'noteItem'),
              createTextContent('• For any queries, please contact the event organizer', 'noteItem')
            ]
          }
        ] as Column[]
      }
    ]
  };

  // Create footer with contact and website info
  const footerSection: ContentStack = {
    stack: [
      createDivider('#9F85FF'),
      {
        columns: [
          {
            width: '*' as any,
            stack: [
              createTextContent('Support', 'footerHeader', [0, 5, 0, 2], '#4A3880'),
              createTextContent('support@feyntech.in', 'footerText')
            ]
          },
          {
            width: '*' as any,
            stack: [
              createTextContent('Website', 'footerHeader', [0, 5, 0, 2], '#4A3880'),
              createTextContent('www.eventatease.com', 'footerText')
            ],
            alignment: 'right'
          }
        ] as Column[]
      },
      {
        canvas: [
          {
            type: 'rect',
            x: 0,
            y: 5,
            w: 515,
            h: 2,
            color: '#F3F0FF'
          }
        ]
      },
      {
        text: 'Powered by EventAtEase',
        style: 'footerPowered',
        alignment: 'center',
        margin: [0, 10, 0, 0]
      }
    ]
  };

  const mainContent: Content[] = [
    headerStack,
    createDivider('#9F85FF'),
    {
      margin: [0, 10, 0, 10],
      stack: [detailsSection]
    } as Content,
    createDivider('#9F85FF'),
    {
      margin: [0, 10, 0, 10],
      stack: [notesSection]
    } as Content,
    footerSection
  ];

  return {
    content: mainContent,
    styles: {
      brand: { fontSize: 28, bold: true },
      header: { fontSize: 20, bold: true },
      subheader: { fontSize: 16, bold: true },
      sectionHeader: { fontSize: 14, bold: true },
      label: { fontSize: 11, bold: true },
      value: { fontSize: 11 },
      note: { fontSize: 12, bold: true },
      noteItem: { fontSize: 10, color: '#666666', lineHeight: 1.4 },
      footerHeader: { fontSize: 11, bold: true },
      footerText: { fontSize: 10, color: '#6B4EAF' },
      footerPowered: { fontSize: 9, color: '#9F85FF', italics: true }
    },
    defaultStyle: {
      font: 'Roboto'
    },
    pageMargins: [40, 40, 40, 40],
    background: {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 595,
          h: 20,
          color: '#F3F0FF'
        }
      ]
    }
  };
};

// Upload PDF to storage in organizer's folder
const uploadOfflineRegistrationPdf = async (
  pdfBuffer: Buffer,
  organizerId: string,
  eventId: string,
  registration: OfflineRegistration
): Promise<string> => {
  try {
    // Verify current user matches organizerId
    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser || currentUser.uid !== organizerId) {
      throw new Error('Unauthorized: Current user does not match organizer ID');
    }

    const timestamp = new Date().getTime();
    const fileName = `offline-registration-${registration.phone}-${timestamp}.pdf`;
    const filePath = `organizers/${organizerId}/events/${eventId}/offline-registrations/${fileName}`;
    
    // Create storage reference
    const storageRef = ref(storage, filePath);
    
    // Convert buffer to Blob
    const pdfBlob = new Blob([pdfBuffer], { type: 'application/pdf' });
    
    // Upload with metadata
    const metadata = {
      contentType: 'application/pdf',
      customMetadata: {
        registrationType: registration.registrationType,
        attendeeName: registration.name,
        attendeePhone: registration.phone,
        timestamp: timestamp.toString()
      }
    };
    
    await uploadBytes(storageRef, pdfBlob, metadata);
    
    // Get download URL
    return await getDownloadURL(storageRef);
  } catch (error) {
    console.error('Error uploading offline registration PDF:', error);
    throw error;
  }
};

// Generate and upload PDF for offline registration
export const generateOfflineRegistrationPdf = async (
  event: Event,
  registration: OfflineRegistration,
  organizerId: string
): Promise<string> => {
  try {
    // Create user info for PDF
    const userInfo = createPdfUserInfo(registration);
    
    // Generate QR code
    const qrCodeData = await generateQrCode(event.id, registration);
    
    // Generate PDF content
    const docDefinition = await generatePdfContent(userInfo, event, registration, qrCodeData);
    
    // Generate PDF buffer
    const pdfBuffer = await new Promise<Buffer>((resolve, reject) => {
      try {
        const pdfDoc = pdfMake.createPdf(docDefinition);
        pdfDoc.getBuffer((buffer: Buffer) => {
          resolve(buffer);
        });
      } catch (error) {
        reject(error);
      }
    });

    // Upload PDF to storage
    return await uploadOfflineRegistrationPdf(pdfBuffer, organizerId, event.id, registration);
  } catch (error) {
    console.error('Error generating offline registration PDF:', error);
    throw error;
  }
};
