import { useState, useEffect } from 'react';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { Checkbox } from '../../components/ui/Checkbox';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/Alert';
import { CheckCircle, AlertCircle, ChevronRight, Loader2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { isVerifiedOrganizer } from '../../types/User';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

const OrganizeEventsPage = () => {
  const [acceptedPolicy, setAcceptedPolicy] = useState(false);
  const [kycStatus, setKycStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { customUser, updateCustomUser, refreshUserData } = useAuth();
  const navigate = useNavigate();
  const userIsVerifiedOrganizer = isVerifiedOrganizer(customUser);

  useEffect(() => {
    if (customUser?.aadharVerification?.isVerified) {
      setKycStatus('completed');
    }
  }, [customUser]);

  const handleOrganizerToggle = async () => {
    if (!customUser) return;
    setLoading(true);
    setError(null);

    try {
      const userRef = doc(db, 'users', customUser.uid);
      const editedUser = { ...customUser, isOrganizer: true };

      // Create Razorpay contact if becoming an organizer for the first time
      if (!editedUser.razorpayContactCreated) {
        try {
          const createContact = httpsCallable(getFunctions(), 'createRazorpayContact');
          const result = await createContact();
          console.log('Razorpay contact created:', result.data);
        
          // Update the editedUser with Razorpay contact info
          editedUser.razorpayContactCreated = true;
          if (result.data && (result.data as any).contactId) {
            editedUser.razorpayContactId = (result.data as any).contactId;
          }
        } catch (error) {
          console.error("Error creating Razorpay contact:", error);
          throw new Error("Failed to create Razorpay contact");
        }
      }

      await updateDoc(userRef, editedUser);
      await updateCustomUser(editedUser);
      await refreshUserData();
      navigate('/organizer/dashboard');
    } catch (err) {
      console.error('Error updating user:', err);
      setError('Failed to update organizer status. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleStartKYC = () => {
    navigate('/verify');
  };

  const getKycStatusDisplay = () => {
    switch (kycStatus) {
      case 'completed':
        return (
          <div className="flex items-center text-green-600">
            <CheckCircle className="h-5 w-5 mr-2" />
            <span className="font-medium">KYC Verified</span>
          </div>
        );
      case 'inProgress':
        return (
          <div className="flex items-center text-blue-600">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span className="font-medium">KYC In Progress</span>
          </div>
        );
      default:
        return (
          <Button 
            onClick={handleStartKYC}
            variant="outline" 
            className="border-purple-200 text-purple-700 hover:bg-purple-50"
            disabled={userIsVerifiedOrganizer}
          >
            Complete KYC
            <ChevronRight className="h-4 w-4 ml-2" />
          </Button>
        );
    }
  };

  // If user is already a verified organizer, show different content
  if (userIsVerifiedOrganizer) {
    return (
      <div className="min-h-screen bg-purple-50 py-12 px-4">
        <div className="max-w-2xl mx-auto">
          <Card className="bg-white shadow-lg">
            <CardHeader className="text-center border-b border-gray-100 bg-gradient-to-r from-purple-50 to-pink-50">
              <CardTitle className="text-2xl font-bold text-purple-800 mb-2">
                You're Already an Organizer!
              </CardTitle>
              <CardDescription className="text-gray-600">
                You can manage your events from the dashboard
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-6 space-y-6">
              <div className="flex items-center justify-center space-x-2 text-green-600">
                <CheckCircle className="h-5 w-5" />
                <span className="font-medium">Verified Organizer Account</span>
              </div>
              <div className="flex justify-center">
                <Button 
                  onClick={() => navigate('/organizer/dashboard')}
                  className="bg-purple-600 hover:bg-purple-700 text-white"
                >
                  Go to Dashboard
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-purple-50 py-12 px-4">
      <div className="max-w-2xl mx-auto">
        <Card className="bg-white shadow-lg">
          <CardHeader className="text-center border-b border-gray-100 bg-gradient-to-r from-purple-50 to-pink-50">
            <CardTitle className="text-2xl font-bold text-purple-800 mb-2">
              Become an Organizer
            </CardTitle>
            <CardDescription className="text-gray-600">
              Complete the verification process to start organizing events
            </CardDescription>
          </CardHeader>
          <CardContent className="pt-6">
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <div className="space-y-0.5">
                  <div className="text-sm font-medium">KYC Verification</div>
                  <div className="text-sm text-gray-500">Complete KYC verification to become an organizer</div>
                </div>
                {getKycStatusDisplay()}
              </div>

              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Checkbox 
                    id="terms" 
                    checked={acceptedPolicy}
                    onChange={(e) => setAcceptedPolicy(e.target.checked)}
                    disabled={kycStatus !== 'completed' || loading}
                  />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    I agree to the terms and conditions for organizers
                  </label>
                </div>

                <Button
                  className="w-full"
                  disabled={!acceptedPolicy || kycStatus !== 'completed' || loading}
                  onClick={handleOrganizerToggle}
                >
                  {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Become an Organizer
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default OrganizeEventsPage;
