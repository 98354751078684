import React, { useState, useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/Table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/Select";
import { PlusIcon, FileText, Loader2, Search } from "lucide-react";
import { OfflineRegistration, RegistrationType, Event } from '../../types/Event';
import { Timestamp } from 'firebase/firestore';
import { addOfflineRegistration } from '../../services/eventService';
import { useToast } from '../../hooks/useToast';
import { generateOfflineRegistrationPdf } from '../../services/offlineRegistrationPdfService';
import { Dialog, DialogContent } from "../ui/Dialog";

interface ManageOfflineAttendeesProps {
  eventId: string;
  event: Event;
  organizerId: string;
  registrationTypes?: RegistrationType[];
  offlineRegistrations?: OfflineRegistration[];
  onRegistrationAdded?: () => void;
}

export const ManageOfflineAttendees: React.FC<ManageOfflineAttendeesProps> = ({
  eventId,
  event,
  organizerId,
  registrationTypes = [],
  offlineRegistrations = [],
  onRegistrationAdded
}) => {
  const { showToast } = useToast();
  const [newRegistration, setNewRegistration] = useState<Partial<OfflineRegistration>>({
    name: '',
    email: '',
    phone: '',
    registrationType: '',
    amountPaid: 0,
    numberOfPeople: 1
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (field: keyof OfflineRegistration, value: string | number) => {
    setNewRegistration(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const filteredRegistrations = useMemo(() => {
    if (!searchQuery.trim()) return offlineRegistrations;
    
    const query = searchQuery.toLowerCase().trim();
    return offlineRegistrations.filter(registration => {
      return (
        registration.name?.toLowerCase().includes(query) ||
        registration.email?.toLowerCase().includes(query) ||
        registration.phone?.toLowerCase().includes(query) ||
        registration.registrationType?.toLowerCase().includes(query)
      );
    });
  }, [offlineRegistrations, searchQuery]);

  const handleAddRegistration = async () => {
    if (!newRegistration.name || !newRegistration.phone || !newRegistration.email || 
        !newRegistration.registrationType || !newRegistration.amountPaid || !newRegistration.numberOfPeople) {
      showToast({
        title: "Error",
        description: "Please fill in all fields",
        variant: "destructive",
      });
      return;
    }

    try {
      setIsLoading(true);
      setShowProcessing(true);

      // First generate the PDF and get the URL
      const registrationWithTimestamp: OfflineRegistration = {
        ...newRegistration as Omit<OfflineRegistration, 'timestamp'>,
        timestamp: Timestamp.now()
      };

      const pdfUrl = await generateOfflineRegistrationPdf(
        event,
        registrationWithTimestamp,
        organizerId
      );

      // Add the registration with the PDF URL
      await addOfflineRegistration(eventId, {
        ...registrationWithTimestamp,
        bookingConfirmationUrl: pdfUrl
      });

      // Reset form state
      setNewRegistration({
        name: '',
        email: '',
        phone: '',
        registrationType: '',
        amountPaid: 0,
        numberOfPeople: 1
      });

      // Call the callback to refresh parent component
      if (onRegistrationAdded) {
        onRegistrationAdded();
      }

      showToast({
        title: "Success",
        description: "Offline registration added successfully",
        variant: "default",
      });
    } catch (error) {
      console.error('Error adding offline registration:', error);
      showToast({
        title: "Error",
        description: "Failed to add offline registration",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setShowProcessing(false);
    }
  };

  return (
    <>
      <Dialog open={showProcessing} onOpenChange={setShowProcessing}>
        <DialogContent>
          <div className="sm:max-w-[425px] text-center py-12">
            <div className="flex flex-col items-center gap-4">
              <Loader2 className="h-8 w-8 animate-spin text-[#9f85ff]" />
              <h3 className="font-semibold text-lg text-[#4a3880]">Processing Registration</h3>
              <p className="text-gray-500">Please wait while we process your registration...</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Card className="mt-8 bg-[#f3f0ff]">
        <CardHeader className="border-b border-[#e5dbff] pb-6">
          <CardTitle className="text-2xl font-semibold text-[#4a3880]">Manage Offline Attendees</CardTitle>
        </CardHeader>
        <CardContent className="pt-6">
          <div className="space-y-8">
            <div className="flex flex-col md:flex-row gap-4 p-4">
              <div className="w-full md:flex-1">
                <Input
                  type="text"
                  placeholder="Name"
                  value={newRegistration.name}
                  onChange={(e) => handleInputChange('name', e.target.value)}
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="w-full md:flex-1">
                <Input
                  type="email"
                  placeholder="Email"
                  value={newRegistration.email}
                  onChange={(e) => handleInputChange('email', e.target.value)}
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="w-full md:flex-1">
                <Input
                  type="tel"
                  placeholder="Phone"
                  value={newRegistration.phone}
                  onChange={(e) => handleInputChange('phone', e.target.value)}
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="w-full md:w-32">
                <Input
                  type="number"
                  placeholder="People"
                  value={newRegistration.numberOfPeople || ''}
                  onChange={(e) => handleInputChange('numberOfPeople', parseInt(e.target.value))}
                  min="1"
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="w-full md:flex-1">
                <Input
                  type="text"
                  placeholder="Registration Type"
                  value={newRegistration.registrationType || ''}
                  onChange={(e) => handleInputChange('registrationType', e.target.value)}
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="w-full md:flex-1">
                <Input
                  type="number"
                  placeholder="Amount"
                  value={newRegistration.amountPaid || ''}
                  onChange={(e) => handleInputChange('amountPaid', parseFloat(e.target.value))}
                  className="w-full focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                />
              </div>
              <div className="flex justify-center md:justify-start">
                <Button 
                  onClick={handleAddRegistration} 
                  className="bg-[#9f85ff] hover:bg-[#8b6fff] text-white md:w-10 md:h-10 md:rounded-full md:p-0 px-4 py-2 rounded-lg"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <>
                      <span className="md:hidden">+ Add</span>
                      <span className="hidden md:inline">+</span>
                    </>
                  )}
                </Button>
              </div>
            </div>

            <div className="rounded-lg border border-[#e5dbff] overflow-hidden">
              <div className="p-4 border-b border-[#e5dbff] bg-[#f3f0ff]">
                <div className="relative">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-500" />
                  <Input
                    placeholder="Search attendees..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="pl-10 w-full max-w-sm focus:ring-[#9f85ff] focus:border-[#9f85ff]"
                  />
                </div>
              </div>
              <div className="overflow-x-auto w-full">
                <div className="min-w-[1000px]">
                  <Table>
                    <TableHeader>
                      <TableRow className="bg-[#f3f0ff]">
                        <TableHead className="w-[200px] py-4 text-[#4a3880] font-semibold text-center">Name</TableHead>
                        <TableHead className="w-[150px] py-4 text-[#4a3880] font-semibold text-center">Phone</TableHead>
                        <TableHead className="w-[200px] py-4 text-[#4a3880] font-semibold text-center">Email</TableHead>
                        <TableHead className="w-[150px] py-4 text-[#4a3880] font-semibold text-center"> Type</TableHead>
                        <TableHead className="w-[150px] py-4 text-[#4a3880] font-semibold text-center">Amount Paid</TableHead>
                        <TableHead className="w-[150px] py-4 text-[#4a3880] font-semibold text-center">No. of People</TableHead>
                        <TableHead className="w-[200px] py-4 text-[#4a3880] font-semibold text-center">Registration Date</TableHead>
                        <TableHead className="w-[150px] py-4 text-[#4a3880] font-semibold text-left"> PDF</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filteredRegistrations.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={8} className="text-center py-8 text-gray-500">
                            {searchQuery.trim() 
                              ? "No matching registrations found" 
                              : "No offline registrations yet"
                            }
                          </TableCell>
                        </TableRow>
                      ) : (
                        filteredRegistrations.map((registration, index) => (
                          <TableRow key={index} className="hover:bg-[#f9f7ff]">
                            <TableCell className="py-4 font-medium text-center text-black">{registration.name}</TableCell>
                            <TableCell className="py-4 text-center text-black">{registration.phone}</TableCell>
                            <TableCell className="py-4 text-center text-black">{registration.email}</TableCell>
                            <TableCell className="py-4 text-center text-black">{registration.registrationType}</TableCell>
                            <TableCell className="py-4 text-center text-black">₹{registration.amountPaid}</TableCell>
                            <TableCell className="py-4 text-center text-black">{registration.numberOfPeople}</TableCell>
                            <TableCell className="py-4 text-center text-black">
                              {registration.timestamp.toDate().toLocaleDateString()}
                            </TableCell>
                            <TableCell className="py-4 text-left">
                              {registration.bookingConfirmationUrl ? (
                                <Button 
                                  variant="outline"
                                  size="sm"
                                  onClick={() => window.open(registration.bookingConfirmationUrl, '_blank')}
                                  className="border-[#9f85ff] text-[#4a3880] hover:bg-[#f3f0ff]"
                                >
                                  <FileText className="h-4 w-4 mr-2" />
                                  View
                                </Button>
                              ) : (
                                <span className="text-gray-500">Not available</span>
                              )}
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};
