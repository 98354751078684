import React, { useEffect } from 'react';
import { Greeting } from '../../components/Greeting';
import { SearchBar } from '../../components/ui/SearchBar/SearchBar';
import CategoryScroll from '../../components/CategoryScroll';
import { useAuth } from '../../contexts/AuthContext';
import { useSearchContext } from '../../contexts/SearchContext';
import { SkeletonGrid } from './components/SkeletonLoaders';
import { useResponsiveSpacing } from './hooks/useResponsiveSpacing';
import { useEvents } from './hooks/useEvents';
import { useBookmarks } from './hooks/useBookmarks';
import { useEventCategories } from './hooks/useEventCategories';
import { Event as EventType } from '../../types/Event';
import { TabFilter } from '../../components/TabFilter';
import './CategoryScroll.css';

const ResponsiveHomeLayout: React.FC = () => {
  const { user } = useAuth();
  const { state: searchCriteria } = useSearchContext();
  const spacing = useResponsiveSpacing();
  const { bookmarkedEvents, fetchBookmarkedEvents, onBookmarkToggle } = useBookmarks(user);
  const { categories: eventCategories, loading: categoriesLoading } = useEventCategories();
  const [activeCategory, setActiveCategory] = React.useState<string>('Entertainment');
  
  const memoizedSearchCriteria = React.useMemo(() => ({
    searchTerm: searchCriteria.searchTerm,
    selectedCategories: searchCriteria.selectedCategories,
    dateRange: searchCriteria.dateRange,
    selectedPrices: searchCriteria.selectedPrices,
    selectedLocations: searchCriteria.selectedLocations,
    showBookmarked: searchCriteria.showBookmarked
  }), [searchCriteria]);

  const { events, loading, fetchEvents } = useEvents(
    memoizedSearchCriteria,
    bookmarkedEvents,
    user
  );

  const filteredEventsByCategory = React.useMemo(() => {
    if (!activeCategory) return events;
    return events.filter(event => event.category === activeCategory);
  }, [events, activeCategory]);

  const { popularEvents, upcomingEvents, futureEvents } = 
    React.useMemo(() => {
      // Set cutoff time to 12 hours ago
      const HOURS_IN_PAST = 12;
      const cutoffTime = Date.now() - (HOURS_IN_PAST * 60 * 60 * 1000);
      
      const getEventDate = (event: EventType) => {
        if (event.isSingleDay && event.date) {
          const timestamp = event.date.toDate().getTime();
          return timestamp;
        }
        const timestamp = event.fromDate ? event.fromDate.toDate().getTime() : 0;
        return timestamp;
      };

      const filteredEvents = filteredEventsByCategory;
      const futureEvents = filteredEvents.filter(event => {
        const eventDate = getEventDate(event);
        return eventDate > cutoffTime;
      });

      const upcomingEvents = futureEvents
        .filter(event => {
          const eventDate = getEventDate(event);
          return eventDate <= (Date.now() + 24 * 60 * 60 * 1000); // Next 24 hours
        })
        .sort((a, b) => getEventDate(a) - getEventDate(b));

      const popularEvents = [...filteredEvents]
        .sort((a, b) => (b.attendeeCount || 0) - (a.attendeeCount || 0))
        .slice(0, 10);

      return {
        popularEvents,
        upcomingEvents,
        futureEvents
      };
    }, [filteredEventsByCategory]);

  useEffect(() => {
    fetchBookmarkedEvents();
  }, [fetchBookmarkedEvents]);

  if (loading || categoriesLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <SkeletonGrid />
      </div>
    );
  }

  return (
    <div 
      className="min-h-screen bg-gradient-to-b from-purple-50 to-white"
      style={{ '--header-height': `${spacing.headerHeight}px` } as any}
    >
      <div 
        className="container mx-auto"
        style={{ 
          maxWidth: spacing.contentMaxWidth,
          padding: `${spacing.contentPadding.y}px ${spacing.contentPadding.x}px`
        }}
      >
        <header 
          className="py-4"
          style={{ 
            minHeight: Math.min(spacing.headerHeight * 0.7, 100),
            marginBottom: spacing.typography.headingMargin * 0.5
          }}
        >
          <Greeting user={user} />
          <div style={{ maxWidth: spacing.contentMaxWidth, margin: '0 auto', width: '100%', padding: '0 16px' }}>
            <SearchBar />
            
            <TabFilter onCategoryChange={setActiveCategory} />
            
            {loading ? (
              <SkeletonGrid />
            ) : filteredEventsByCategory.length === 0 ? (
              <div className="text-center p-8 bg-white rounded-lg shadow-sm mt-6">
                <p className="text-gray-600 text-lg">No events found in this category.</p>
                <p className="text-gray-500 mt-2">Try selecting a different category or check back later.</p>
              </div>
            ) : (
              <main style={{ paddingTop: spacing.sectionGap * 0.5 }}>
                {popularEvents.length > 0 && (
                  <section 
                    style={{ marginBottom: spacing.categorySpacing.marginBottom * 0.6 }}
                    data-testid="category-popular-events"
                  >
                    <CategoryScroll
                      title="Popular Events"
                      events={popularEvents}
                      onBookmarkToggle={onBookmarkToggle}
                      bookmarkedEvents={bookmarkedEvents}
                      userId={user?.uid || ''}
                    />
                  </section>
                )}

                {upcomingEvents.length > 0 && (
                  <section 
                    style={{ marginBottom: spacing.categorySpacing.marginBottom * 0.6 }}
                    data-testid="category-upcoming-events"
                  >
                    <CategoryScroll
                      title="Upcoming Events"
                      events={upcomingEvents}
                      onBookmarkToggle={onBookmarkToggle}
                      bookmarkedEvents={bookmarkedEvents}
                      userId={user?.uid || ''}
                    />
                  </section>
                )}

                {eventCategories.map(category => {
                  const categoryEvents = futureEvents.filter((event: EventType) => 
                    category.events.includes(event.id)
                  );

                  return categoryEvents.length > 0 ? (
                    <section 
                      key={category.id}
                      style={{ 
                        marginBottom: spacing.categorySpacing.marginBottom * 0.6,
                        padding: `${spacing.categorySpacing.padding * 0.75}px`
                      }}
                      data-testid={`category-${category.name.toLowerCase().replace(/\s+/g, '-')}`}
                    >
                      <CategoryScroll
                        title={category.name}
                        events={categoryEvents}
                        onBookmarkToggle={onBookmarkToggle}
                        bookmarkedEvents={bookmarkedEvents}
                        userId={user?.uid || ''}
                      />
                    </section>
                  ) : null;
                })}

                {filteredEventsByCategory.length === 0 && (
                  <div className="text-center p-8 bg-white rounded-lg shadow-sm">
                    <p className="text-gray-600 text-lg">No upcoming events found.</p>
                    <p className="text-gray-500 mt-2">Try adjusting your search filters or check back later.</p>
                  </div>
                )}
              </main>
            )}
          </div>
        </header>
      </div>
    </div>
  );
};

export default ResponsiveHomeLayout;