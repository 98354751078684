import React from 'react';
import { Card, CardContent } from "../../../components/ui/Card";
import { CalendarIcon } from "lucide-react";
import { Event } from '../../../types/Event';
import { format } from 'date-fns';

interface EventDateInfoProps {
  event: Event;
}

const formatTime = (time: string | undefined | null): string => {
  if (!time) return 'Time not available';
  if (time.includes('AM') || time.includes('PM')) return time;
  const [hours, minutes] = time.split(':');
  const hour = parseInt(hours);
  if (isNaN(hour)) return time;
  const period = hour >= 12 ? 'PM' : 'AM';
  const displayHour = hour % 12 || 12;
  return `${displayHour}:${minutes} ${period}`;
};

export const EventDateInfo: React.FC<EventDateInfoProps> = ({ event }) => {
  console.log('Event:', {
    isSingleDay: event.isSingleDay,
    date: event.date,
    fromDate: event.fromDate,
    toDate: event.toDate,
    isRepeatedEvent: event.isRepeatedEvent,
    repeatedDays: event.repeatedDaysOfWeek
  });

  // Helper function to check if a value is a Timestamp or has toDate method
  const isTimestamp = (value: any): boolean => {
    return value && typeof value.toDate === 'function';
  };

  // Pre-validate all date values
  const hasValidDate = event.date && isTimestamp(event.date);
  const hasValidDateRange = event.fromDate && event.toDate && isTimestamp(event.fromDate) && isTimestamp(event.toDate);
  const repeatedDays = event.repeatedDaysOfWeek || [];

  console.log('Date validation:', {
    hasValidDate,
    hasValidDateRange,
    date: event.date,
    fromDate: event.fromDate,
    toDate: event.toDate,
    isSingleDay: event.isSingleDay
  });

  let content;
  if (event.isSingleDay && hasValidDate) {
    const date = event.date!.toDate();
    content = (
      <div data-testid="single-day-content">
        <p>{format(date, 'EEEE, MMMM d, yyyy')}</p>
        <p>{formatTime(event.startTime)} - {formatTime(event.endTime)}</p>
      </div>
    );
  } else if (!event.isSingleDay && hasValidDateRange) { 
    content = (
      <div data-testid="date-range-content">
        <p>From: {format(event.fromDate!.toDate(), 'EEEE, MMMM d, yyyy')}</p>
        <p>To: {format(event.toDate!.toDate(), 'EEEE, MMMM d, yyyy')}</p>
        <p>Daily Time: {formatTime(event.startTime)} - {formatTime(event.endTime)}</p>
        {event.isRepeatedEvent && repeatedDays.length > 0 && (
          <p>Every {repeatedDays.join(', ')}</p>
        )}
      </div>
    );
  } else {
    content = (
      <div data-testid="no-date-content">
        No date information available
      </div>
    );
  }

  return (
    <Card>
      <CardContent className="p-4">
        <div className="flex items-center mb-2">
          <CalendarIcon className="w-6 h-6 text-purple-600 mr-2" />
          <h3 className="text-lg font-semibold text-purple-800">Date & Time</h3>
        </div>
        <div className="text-sm text-gray-600">
          {content}
        </div>
      </CardContent>
    </Card>
  );
};