import { useState, useCallback, useEffect } from 'react';

// Design system constants
const GOLDEN_RATIO = 1.618;
const BASE_SPACING = 8; // Base unit for spacing (8px grid)
const MIN_CONTENT_WIDTH = 320; // Minimum content width
const MAX_CONTENT_WIDTH = 1400; // Maximum content width
const OPTIMAL_LINE_LENGTH = 75; // Optimal characters per line

export const useResponsiveSpacing = () => {
  const getResponsiveSpacing = useCallback(() => {
    const vh = window.innerHeight;
    const vw = window.innerWidth;
    
    // Responsive scale factor based on viewport size
    const scaleFactor = Math.max(0.8, Math.min(1.2, vw / 1440));
    
    // Dynamic spacing calculations
    const baseUnit = BASE_SPACING * scaleFactor;
    
    return {
      // Existing spacing calculations (modified)
      headerHeight: vh / GOLDEN_RATIO / 2,
      sectionSpacing: vh / GOLDEN_RATIO / 4,
      contentMaxWidth: Math.min(MAX_CONTENT_WIDTH, Math.max(MIN_CONTENT_WIDTH, vw * 0.95)), // Increased from 0.9 to 0.95
      
      // New spacing parameters
      gridGap: baseUnit * 2, // Gap between grid items
      sectionGap: baseUnit * 4, // Gap between major sections
      categorySpacing: {
        gap: vw <= 768 ? baseUnit : baseUnit * 3, // Reduced gap for mobile
        padding: vw <= 768 ? baseUnit / 2 : baseUnit * 2, // Minimal padding for mobile
        marginBottom: baseUnit * 6, // Bottom margin for sections
      },
      contentPadding: {
        // Minimal padding for mobile
        x: vw <= 768 ? baseUnit / 2 : Math.max(baseUnit * 2, vw * 0.05), // Further reduced padding for mobile
        y: baseUnit * 3, // Vertical padding
      },
      cardSpacing: {
        gap: vw <= 768 ? baseUnit / 2 : baseUnit * 2, // Minimal gap for mobile
        padding: vw <= 768 ? baseUnit / 2 : baseUnit * 2, // Minimal padding for mobile
      },
      typography: {
        maxWidth: `${OPTIMAL_LINE_LENGTH}ch`, // Optimal reading width
        headingMargin: baseUnit * 3, // Margin for headings
      }
    };
  }, []);

  const [spacing, setSpacing] = useState(getResponsiveSpacing());

  useEffect(() => {
    const handleResize = () => {
      setSpacing(getResponsiveSpacing());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getResponsiveSpacing]);

  return spacing;
}; 