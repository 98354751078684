import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardContent, CardFooter } from "../../../components/ui/Card";
import { Badge } from "../../../components/ui/Badge";
import { Button } from "../../../components/ui/Button";
import { AlertTriangleIcon } from "../../../utils/icons";
import { useEventDetail } from '../../../hooks/useEventDetail';
import { EventHeader } from './EventHeader';
import { EventLocationInfo } from './EventLocationInfo';
import { EventDateInfo } from './EventDateInfo';
import { EventDescription } from './EventDescription';
import { EventAttendees } from './EventAttendees';
import { Timestamp } from 'firebase/firestore';
import { getImageUrl } from '../../../utils/firebaseUtils';
import { isEventBookmarked, toggleEventBookmark } from '../../../services/bookmarkService';
import { useAuth } from '../../../contexts/AuthContext';
import { EventRating } from './EventRating';
import { Event } from '../../../types/Event';
import EventMetaTags from '../../../components/common/EventMetaTags';
import { useOrganizerDetails } from '../../../hooks/useOrganizerDetails';
import { SEO } from '../../../components/SEO';
import RegistrationStatus from './RegistrationStatus';
import EventEndedStatus from './EventEndedStatus';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { Feedback, RegistrationOption } from './types';
import {DEFAULT_EVENT_IMAGE, formatTime} from './helpers';
import {SkeletonLoader} from './SkeletonLoader';
import { QuantitySpinner } from '../../../components/common/QuantitySpinner';
import { Event as EventType } from '../../../types/Event';




export const EventDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { event, imageUrl: eventImageUrl, attendees, isLoading } = useEventDetail(id);
  const { organizer, organizerAverageRating, organizerEvents, isLoading: organizerLoading } = useOrganizerDetails(event?.organizerId);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_EVENT_IMAGE);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<string | null>(null);
  const [isAttendeeState, setIsAttendeeState] = useState(false);
  const [registrationQuantities, setRegistrationQuantities] = useState<Record<string, number>>({});
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (eventImageUrl) {
      getImageUrl(eventImageUrl)
        .then(url => {
          setImageUrl(url);
        })
        .catch((error) => {
          console.error('Error fetching image URL:', error);
          setImageUrl(DEFAULT_EVENT_IMAGE);
        });
    } else {
      setImageUrl(DEFAULT_EVENT_IMAGE);
    }
  }, [eventImageUrl]);

  useEffect(() => {
    const fetchBookmarkState = async () => {
      if (user?.uid && id) {
        const bookmarked = await isEventBookmarked(user.uid, id);
        setIsBookmarked(bookmarked);
      }
    };
    fetchBookmarkState();
  }, [user?.uid, id]);

  useEffect(() => {
    const checkAttendeeStatus = async () => {
      if (user?.uid && event?.id) {
        try {
          const attendeeDocRef = doc(db, 'events', event.id, 'attendees', user.uid);
          const attendeeDoc = await getDoc(attendeeDocRef);
          setIsAttendeeState(attendeeDoc.exists());
        } catch (error) {
          console.error('Error checking attendee status:', error);
          setIsAttendeeState(false);
        }
      } else {
        setIsAttendeeState(false);
      }
    };
    checkAttendeeStatus();
  }, [user?.uid, event?.id]);

  useEffect(() => {
    if (event && hasRegistrationTypes(event)) {
      const initialQuantities = event.registrationTypes.reduce((acc, type) => ({
        ...acc,
        [type.name]: 0
      }), {});
      setRegistrationQuantities(initialQuantities);
    }
  }, [event]);

  useEffect(() => {
    // If URL is /event/id pattern, redirect to /id
    if (location.pathname.startsWith('/event/')) {
      const eventId = location.pathname.split('/').pop();
      navigate(`/${eventId}`, { replace: true });
    }
  }, [location, navigate]);

  const handleBookmarkToggle = async () => {
    if (!user?.uid || !id) return;

    try {
      // Optimistically update UI
      setIsBookmarked(prev => !prev);
      
      // Perform actual toggle in background
      await toggleEventBookmark(user.uid, id, !isBookmarked);
    } catch (error) {
      // Revert on error
      setIsBookmarked(prev => !prev);
      console.error('Failed to toggle bookmark:', error);
    }
  };

  const handleRate = async (rating: number) => {
    // This will be implemented later when you create the rating service
    console.log('Rating:', rating);
  };

  // Calculate rating and total ratings from feedback
  const calculateRating = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    if (!feedbackArray || feedbackArray.length === 0) return 0;
    
    const sum = feedbackArray.reduce((acc: number, curr: Feedback) => acc + curr.rating, 0);
    return sum / feedbackArray.length;
  };

  const getTotalRatings = (event: Event | null) => {
    if (!event) return 0;
    const feedbackArray = event.feedback as Feedback[] | undefined;
    return feedbackArray?.length || 0;
  };

  const isUserAttendee = () => {
    return isAttendeeState;
  };

  const handleQuantityChange = (typeName: string, newQuantity: number) => {
    setRegistrationQuantities(prev => ({
      ...prev,
      [typeName]: newQuantity
    }));
  };

  const calculateTotal = () => {
    if (!event || !hasRegistrationTypes(event)) return 0;
    return Object.entries(registrationQuantities).reduce((total, [typeName, qty]) => {
      const price = getRegistrationPrice(event, typeName) || 0;
      return total + (price * qty);
    }, 0);
  };

  const handleRegister = () => {
    if (!event) return;
    if (!user) {
      navigate('/login', { state: { from: location.pathname } });
      return;
    }
    if (event.isCancelled) return;
    if (event.maxCapacity && event.attendeeCount >= event.maxCapacity) return;

    const formatDate = (date: Timestamp | null | undefined): string => {
      if (!date) return 'Date not available';
      return date.toDate().toLocaleDateString();
    };

    const registrationQuantity = (event as EventType).category === 'Entertainment' && !event.isOnline ? quantity : 1;

    navigate(`/${id}/register`, { 
      state: { 
        eventTitle: event.title,
        eventDate: event.isSingleDay 
          ? formatDate(event.date)
          : `${formatDate(event.fromDate)} - ${formatDate(event.toDate)}`,
        eventTime: event.isSingleDay && event.startTime && event.endTime
          ? `${event.startTime} - ${event.endTime}`
          : '',
        eventLocation: event.isOnline 
          ? 'Online Event' 
          : event.location || 'Location not specified',
        eventDescription: event.description,
        organizer: event.organizerId,
        isFree: event.isFree,
        price: event.isFree ? 0 : (hasRegistrationTypes(event) ? calculateTotal() : (Number(event.price) || 0) * registrationQuantity),
        pricePerTicket: event.isFree ? 0 : (hasRegistrationTypes(event) ? calculateTotal() / Object.values(registrationQuantities).reduce((a, b) => a + b, 0) : Number(event.price) || 0),
        quantity: registrationQuantity,
        maxCapacity: event.maxCapacity,
        currentAttendees: event.attendeeCount,
        meetingLink: event.meetingLink,
        skill: event.skill,
        categories: event.categories,
        tags: event.tags,
        category: (event as EventType).category,
        isOnline: event.isOnline,
        googleCalendarEventId: (event as EventType).googleCalendarEventId,
        registrationTypes: hasRegistrationTypes(event) 
          ? event.registrationTypes.map(type => ({
              name: type.name,
              price: type.price,
              quantity: registrationQuantities[type.name] || 0
            }))
          : undefined
      } 
    });
  };

  // Type guard to check if event has registration types
  const hasRegistrationTypes = (event: Event): event is Event & { registrationTypes: RegistrationOption[] } => {
    return !!(event as any).registrationTypes?.length;
  };

  // Get registration types safely
  const getRegistrationTypes = (event: Event | null): RegistrationOption[] => {
    if (!event || !hasRegistrationTypes(event)) return [];
    return event.registrationTypes;
  };

  // Get registration price safely
  const getRegistrationPrice = (event: Event | null, typeName: string): number | undefined => {
    if (!event || !hasRegistrationTypes(event)) return undefined;
    return event.registrationTypes.find(t => t.name === typeName)?.price;
  };

  // Helper to check if a value is a Timestamp
  const isTimestamp = (value: any): boolean => {
    return value && typeof value.toDate === 'function';
  };



  // Helper to check if event has ended
  const hasEventEnded = (event: Event | null): boolean => {
    if (!event) return false;
    
    const now = new Date();
    
    if (event.isSingleDay && event.date && isTimestamp(event.date)) {
      const eventDate = event.date.toDate();
      if (event.endTime) {
        // Parse the end time using the formatTime helper
        const timeStr = formatTime(event.endTime);
        const [time, period] = timeStr.split(' ');
        const [hours, minutes] = time.split(':');
        let hour = parseInt(hours);
        
        // Convert to 24-hour format
        if (period === 'PM' && hour !== 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;
        
        eventDate.setHours(hour, parseInt(minutes));
      } else {
        // If no end time, set to end of day
        eventDate.setHours(23, 59, 59);
      }
      return now > eventDate;
    }
    
    // For multi-day events
    if (!event.isSingleDay && event.toDate && isTimestamp(event.toDate)) {
      const endDate = event.toDate.toDate();
      if (event.endTime) {
        // Parse the end time similar to above
        const timeStr = formatTime(event.endTime);
        const [time, period] = timeStr.split(' ');
        const [hours, minutes] = time.split(':');
        let hour = parseInt(hours);
        
        if (period === 'PM' && hour !== 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;
        
        endDate.setHours(hour, parseInt(minutes));
      } else {
        endDate.setHours(23, 59, 59);
      }
      return now > endDate;
    }
    
    return false;
  };

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!event) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Card className="max-w-3xl mx-auto p-6">
          <div className="text-center text-gray-600">Event not found</div>
        </Card>
      </div>
    );
  }

  return (
    <>
      <SEO 
        title={event?.title || 'Event Details'} 
        description={`${event.title} - ${event.description || 'Join this exciting event'} | Organized by ${organizer?.name || 'Event Organizer'}`}
      />
      <EventMetaTags event={event} />
      <Card className="max-w-3xl mx-auto overflow-hidden">
        {event.isCancelled && (
          <div className="bg-red-500 text-white py-2 px-4 flex items-center justify-center">
            <AlertTriangleIcon className="w-5 h-5 mr-2" />
            <span className="text-sm font-semibold">Event Cancelled</span>
          </div>
        )}
        
        <EventHeader 
          title={event.title} 
          imageUrl={imageUrl} 
          onImageError={() => setImageUrl(DEFAULT_EVENT_IMAGE)}
          isBookmarked={isBookmarked}
          onBookmarkToggle={user ? handleBookmarkToggle : undefined}
          eventId={id || ''}
          bookmarkAriaLabel="Toggle bookmark"
        />

        <div className="bg-purple-50 p-4 flex flex-wrap justify-center gap-2">
          <Badge variant={event.isFree ? "secondary" : "default"} className="bg-purple-100 text-purple-800">
            {event.isFree ? 'Free' : `₹${event.price}`}
          </Badge>
          <Badge variant="outline" className="border-purple-200 text-purple-800">
            {event.isOnline ? 'Online' : 'In-person'}
          </Badge>
          {event.skill && (
            <Badge variant="outline" className="border-purple-200 text-purple-800">
              {event.skill}
            </Badge>
          )}
          {event.categories?.map((category, index) => (
            <Badge key={index} variant="outline" className="border-purple-200 text-purple-800">
              {category}
            </Badge>
          ))}
        </div>

        <CardContent className="p-4 md:p-6 space-y-4 md:space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-4 md:mb-6">
            <EventLocationInfo 
              event={event} 
              isAttendee={isUserAttendee()} 
            />
            <EventDateInfo event={event} />
          </div>

          <div className="flex flex-col lg:flex-row gap-4 md:gap-6">
            <EventDescription description={event?.description || ''} />
            <EventAttendees attendees={attendees} maxCapacity={event?.maxCapacity} />
          </div>

          {event.feedback && event.feedback.length > 0 && (
            <EventRating 
              rating={calculateRating(event)}
              totalRatings={getTotalRatings(event)}
              hasAttended={false}
              onRate={handleRate}
            />
          )}

          {/* Registration Types Section */}
          {event && !event.isFree && hasRegistrationTypes(event) && !event.isCancelled && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold mb-4">Registration Options</h3>
              <div className="space-y-4">
                {getRegistrationTypes(event).map((type: RegistrationOption) => (
                  <div key={type.name} 
                       className="p-4 border rounded-lg hover:bg-gray-50">
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">{type.name}</span>
                        {type.description && (
                          <div className="relative inline-block">
                            <button
                              type="button"
                              onMouseEnter={() => setShowTooltip(type.name)}
                              onMouseLeave={() => setShowTooltip(null)}
                              className="text-gray-500 hover:text-gray-700"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                              </svg>
                            </button>
                            {showTooltip === type.name && (
                              <div className="absolute z-10 w-48 p-2 mt-1 text-sm text-white bg-gray-900 rounded-lg shadow-lg -left-20 top-6">
                                {type.description}
                                <div className="absolute w-2 h-2 bg-gray-900 transform rotate-45 -top-1 left-24"></div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <span className="font-semibold">₹{type.price}</span>
                    </div>
                    {(event as EventType).category === 'Entertainment' && !event.isOnline ? (
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => handleQuantityChange(type.name, Math.max(0, registrationQuantities[type.name] - 1))}
                            className="w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-800 hover:bg-purple-200"
                            disabled={registrationQuantities[type.name] === 0}
                          >
                            -
                          </button>
                          <span className="w-8 text-center">{registrationQuantities[type.name]}</span>
                          <button
                            onClick={() => handleQuantityChange(type.name, registrationQuantities[type.name] + 1)}
                            className="w-8 h-8 flex items-center justify-center rounded-full bg-purple-100 text-purple-800 hover:bg-purple-200"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-between items-center">
                        <input
                          type="radio"
                          id={type.name}
                          name="registrationType"
                          value={type.name}
                          checked={selectedType === type.name}
                          onChange={(e) => {
                            setSelectedType(e.target.value);
                            // Reset quantities when switching types
                            setRegistrationQuantities(prev => ({
                              ...prev,
                              [e.target.value]: 1
                            }));
                          }}
                          className="w-4 h-4 text-purple-600"
                        />
                        <label htmlFor={type.name} className="flex-grow ml-3 cursor-pointer">
                          Select
                        </label>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </CardContent>

        <CardFooter className="bg-purple-50 p-4 md:p-6 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 sm:gap-0">
          <div 
            className={`flex items-center gap-3 ${!event?.isCancelled ? 'cursor-pointer hover:opacity-80' : 'opacity-50 cursor-not-allowed'} transition-opacity bg-purple-50 rounded-lg py-2 px-4`}
            onClick={() => !event?.isCancelled && navigate(`/organizer/${event?.organizerId}`)}
          >
            <div className="flex flex-col">
              <span className="text-sm text-gray-600">Organized by</span>
              <span className="text-purple-600 font-medium text-base md:text-lg truncate max-w-[200px]">{organizer?.name || 'Unknown Organizer'}</span>
            </div>
          </div>

          <div className="flex flex-col items-end gap-2">
            {isUserAttendee() && (event as EventType)?.bookingConfirmationPdfUrl && (
              <Button
                onClick={() => window.open((event as EventType).bookingConfirmationPdfUrl, '_blank')}
                className="w-full sm:w-auto"
                variant="outline"
              >
                Download Ticket
              </Button>
            )}
            {event && !event.isFree && hasRegistrationTypes(event) && (
              <div className="text-lg font-semibold">
                Total: ₹{(event as EventType).category === 'Entertainment' && !event.isOnline 
                  ? calculateTotal()
                  : (getRegistrationPrice(event, selectedType) || 0)}
              </div>
            )}
            <Button
              onClick={handleRegister}
              disabled={
                !user ||
                event.isCancelled ||
                isUserAttendee() ||
                hasEventEnded(event) ||
                (event.maxCapacity && event.attendeeCount >= event.maxCapacity) ||
                ((event as EventType).category === 'Entertainment' && !event.isOnline 
                  ? Object.values(registrationQuantities).every(qty => qty === 0)
                  : hasRegistrationTypes(event) && !selectedType)
              }
              className="w-full sm:w-auto"
            >
              {event.isCancelled 
                ? 'Event Cancelled' 
                : isUserAttendee() 
                  ? 'Already Registered'
                  : hasEventEnded(event)
                    ? 'Event Ended'
                    : 'Register Now'
              }
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default EventDetailPage;