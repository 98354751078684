import { BaseModel } from './BaseModel';
import { 
  Timestamp, 
  setDoc, 
  doc, 
  query, 
  where, 
  getDocs, 
  QueryDocumentSnapshot 
} from 'firebase/firestore';
import { CustomUser, FundAccount } from '../types/User';

export class User extends BaseModel implements CustomUser {
  static collectionName = 'users';

  constructor(
    public id: string,
    public name: string,
    public email: string,
    public gender: string,
    public dateOfBirth: Date,
    public eventsAttended: string[],
    public eventsCreated: string[],
    public bankAccountDetails: {
      accountNumber: string;
      bankName: string;
      ifscCode: string;
      isVerified: boolean;
    },
    public upiDetails: string,
    public upiVerified: boolean,
    public subscribers: string[],
    public subscribedTo: string[],
    public isOrganizer: boolean,
    public profilePicture: string,
    public phone: string,
    public phoneVerified: boolean,
    public createdAt: Date,
    public lastLogin: Date,
    public bookmarkedEvents: string[],
    public razorpayContactCreated: boolean = false,
    public razorpayContactId?: string,
    public fundAccounts: FundAccount[] = [],
    public maxFundAccounts: number = 3
  ) {
    super(id);
  }

  // Create a new user
  static async create(userData: Omit<CustomUser, 'id'>): Promise<User> {
    const docRef = doc(User.getCollectionRef());
    await setDoc(docRef, { 
      ...userData, 
      createdAt: new Date(),
      razorpayContactCreated: false 
    });
    return new User(
      docRef.id,
      userData.name,
      userData.email,
      userData.gender,
      userData.dateOfBirth,
      userData.eventsAttended,
      userData.eventsCreated,
      userData.bankAccountDetails,
      userData.upiDetails,
      userData.upiVerified,
      userData.subscribers,
      userData.subscribedTo,
      userData.isOrganizer,
      userData.profilePicture,
      userData.phone,
      userData.phoneVerified,
      userData.createdAt,
      userData.lastLogin,
      userData.bookmarkedEvents,
      userData.razorpayContactCreated,
      userData.razorpayContactId,
      userData.fundAccounts,
      userData.maxFundAccounts
    );
  }

  // Find a user by email
  static async findByEmail(email: string): Promise<User | null> {
    const q = query(this.getCollectionRef(), where('email', '==', email));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return null;
    const doc = querySnapshot.docs[0];
    const data = doc.data() as CustomUser;
    return new User(
      doc.id,
      data.name,
      data.email,
      data.gender,
      data.dateOfBirth instanceof Timestamp ? data.dateOfBirth.toDate() : new Date(data.dateOfBirth),
      data.eventsAttended,
      data.eventsCreated,
      data.bankAccountDetails,
      data.upiDetails,
      data.upiVerified,
      data.subscribers,
      data.subscribedTo,
      data.isOrganizer,
      data.profilePicture,
      data.phone,
      data.phoneVerified,
      data.createdAt instanceof Timestamp ? data.createdAt.toDate() : new Date(data.createdAt),
      data.lastLogin instanceof Timestamp ? data.lastLogin.toDate() : new Date(data.lastLogin),
      data.bookmarkedEvents,
      data.razorpayContactCreated,
      data.razorpayContactId,
      data.fundAccounts,
      data.maxFundAccounts
    );
  }

  // Convert to JSON
  public toJSON(): CustomUser {
    return {
      name: this.name,
      email: this.email,
      gender: this.gender,
      dateOfBirth: this.dateOfBirth,
      eventsAttended: this.eventsAttended,
      eventsCreated: this.eventsCreated,
      bankAccountDetails: this.bankAccountDetails,
      upiDetails: this.upiDetails,
      upiVerified: this.upiVerified,
      subscribers: this.subscribers,
      subscribedTo: this.subscribedTo,
      isOrganizer: this.isOrganizer,
      profilePicture: this.profilePicture,
      phone: this.phone,
      phoneVerified: this.phoneVerified,
      createdAt: this.createdAt,
      lastLogin: this.lastLogin,
      bookmarkedEvents: this.bookmarkedEvents,
      razorpayContactCreated: this.razorpayContactCreated,
      razorpayContactId: this.razorpayContactId,
      fundAccounts: this.fundAccounts,
      maxFundAccounts: this.maxFundAccounts
    };
  }

  // Save or update user data
  async save(): Promise<void> {
    const docRef = doc(User.getCollectionRef(), this.id);
    const data = this.toJSON();
    // Remove undefined fields
    Object.keys(data).forEach(key => (data as any)[key] === undefined && delete (data as any)[key]);
    await setDoc(docRef, data, { merge: true });
  }

  // Add methods to handle bookmarking
  async toggleBookmark(eventId: string): Promise<void> {
    const index = this.bookmarkedEvents.indexOf(eventId);
    if (index > -1) {
      this.bookmarkedEvents.splice(index, 1);
    } else {
      this.bookmarkedEvents.push(eventId);
    }
    await this.save();
  }

  isEventBookmarked(eventId: string): boolean {
    return this.bookmarkedEvents.includes(eventId);
  }
}
