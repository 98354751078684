import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import { Badge } from "../../components/ui/Badge";
import { Avatar, AvatarFallback } from "../../components/ui/Avatar";
import { CalendarCheck, Star, Share2, Users, ArrowRight, Ticket } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getImageUrl } from '../../utils/firebaseUtils';
import defaultEventImage from '../../assets/images/default-event-image.png';

interface RegistrationType {
  name: string;
  price: number;
  quantity: number;
}

interface LocationState {
  eventTitle: string;
  eventDate: string;
  eventImage: string;
  attendeeCount: number;
  eventId: string;
  isFree: boolean;
  price: number;
  pricePerTicket: number;
  quantity: number;
  category?: string;
  registrationTypes?: RegistrationType[];
}

export const RegistrationConfirmationScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, customUser } = useAuth();
  const state = location.state as LocationState;
  const [imageUrl, setImageUrl] = useState<string>(defaultEventImage);

  useEffect(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });

    if (state?.eventImage) {
      getImageUrl(state.eventImage)
        .then(url => setImageUrl(url))
        .catch(() => setImageUrl(defaultEventImage));
    }
  }, [state?.eventImage]);

  if (!state || !user) {
    navigate('/attendee/home');
    return null;
  }

  const { eventTitle, eventDate, attendeeCount, eventId, isFree, price, pricePerTicket, quantity, category, registrationTypes } = state;
  const userName = customUser?.displayName || user.displayName || 'Attendee';

  const handleImageError = () => {
    setImageUrl(defaultEventImage);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4 py-8 max-w-4xl"
    >
      <Card className="w-full">
        <CardHeader className="text-center">
          <CardTitle className="text-2xl font-bold text-purple-600">
            Registration Confirmed! 🎉
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex flex-col items-center space-y-4">
            <div className="relative w-32 h-32 rounded-full overflow-hidden border-4 border-purple-200">
              <img
                src={imageUrl}
                alt={eventTitle}
                className="w-full h-full object-cover"
                onError={handleImageError}
              />
            </div>
            <h2 className="text-xl font-semibold text-gray-800">{eventTitle}</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center gap-2 text-gray-600">
              <CalendarCheck className="w-5 h-5 text-purple-500" />
              <span>{eventDate}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Users className="w-5 h-5 text-purple-500" />
              <span>{attendeeCount} attendee{attendeeCount !== 1 ? 's' : ''}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Ticket className="w-5 h-5 text-purple-500" />
              <div className="space-y-1">
                {category === 'Entertainment' && registrationTypes ? (
                  <div className="space-y-2">
                    <span className="font-medium">Ticket Details:</span>
                    {registrationTypes
                      .filter(type => type.quantity > 0)
                      .map((type, index) => (
                        <div key={index} className="text-sm text-gray-500">
                          {type.name}: {type.quantity} × ₹{type.price} = ₹{type.price * type.quantity}
                        </div>
                      ))
                    }
                    <div className="text-sm font-medium text-purple-600">
                      Total: ₹{price}
                    </div>
                  </div>
                ) : (
                  <>
                    <span>{quantity} ticket{quantity !== 1 ? 's' : ''}</span>
                    {!isFree && quantity > 1 && (
                      <div className="text-sm text-gray-500">
                        (₹{pricePerTicket} × {quantity} = ₹{price})
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="text-center space-y-2">
            <p className="text-gray-600">
              Thank you for registering, {userName}! We've sent you a confirmation email with all the details.
            </p>
            <Badge variant="outline" className="bg-purple-50">
              Ticket ID: {eventId}-{user.uid.slice(0, 8)}
            </Badge>
          </div>
        </CardContent>

        <CardFooter className="flex flex-col sm:flex-row justify-center gap-4 pt-6">
          <Button
            onClick={() => navigate(`/${eventId}`)}
            variant="outline"
            className="w-full sm:w-auto"
          >
            View Event Details
          </Button>
          <Button
            onClick={() => navigate('/attendee/home')}
            className="w-full sm:w-auto bg-purple-600 text-white hover:bg-purple-700"
          >
            Back to Events <ArrowRight className="ml-2 h-4 w-4" />
          </Button>
        </CardFooter>
      </Card>
    </motion.div>
  );
};
