import pdfMake from 'pdfmake/build/pdfmake';
import * as vfs from 'pdfmake/build/vfs_fonts';
import QRCode from 'qrcode';
import { User } from '../types/User';
import { Event, SkillLevel } from '../types/Event';
import { storage } from '../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getTicketTemplate, getInvoiceTemplate, formatEventDate, formatCurrency } from '../templates/pdfTemplates';
import { Timestamp } from 'firebase/firestore';
import { updateEventBookingConfirmationPdf } from './eventService';

// Define a simpler type for PDF generation
interface RegistrationOption {
  name: string;
  price: number;
  quantity: number;
}

interface PdfOrderInfo {
  id: string;
  amount: number;
  ticketId?: string;
  currency?: string;
  quantity?: number;
  pricePerTicket?: number;
  registrationTypes?: RegistrationOption[];
}

// Define a type that combines both Event and EventState
type EventLike = {
  id?: string;
  urlFriendlyId?: string;
  title?: string;
  eventTitle?: string;
  description?: string;
  eventDescription?: string;
  isOnline?: boolean;
  location?: string;
  eventLocation?: string;
  date?: any;
  eventDate?: string;
  startTime?: string;
  eventTime?: string;
  endTime?: string;
  isFree?: boolean;
  price?: number | string;
  pricePerTicket?: number;
  quantity?: number;
  meetingLink?: string;
  skill?: SkillLevel;
  status?: 'completed' | 'cancelled' | 'upcoming';
  isCancelled?: boolean;
  category?: string;
  registrationTypes?: RegistrationOption[];
  stats?: {
    totalEarnings: number;
    platformEarnings: number;
    transactionCount: number;
  };
};

// Initialize pdfMake with fonts
if (typeof window !== 'undefined') {
  // Browser environment
  (window as any).pdfMake = pdfMake;
  (window as any).pdfMake.vfs = (vfs as any).pdfMake?.vfs;
  pdfMake.fonts = {
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.2.7/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
  };
} else if (process.env.NODE_ENV === 'test') {
  // Mock environment for testing
  (pdfMake as any).vfs = {};
} else {
  // Node.js environment
  (pdfMake as any).vfs = (vfs as any).pdfMake?.vfs;
}

const generateQrCodeDataUrl = async (data: string): Promise<string> => {
  try {
    return await QRCode.toDataURL(data);
  } catch (err) {
    console.error('Error generating QR code:', err);
    return ''; // Return empty string if QR generation fails
  }
};

// Helper function to normalize event data
const normalizeEventData = (event: EventLike): Event => {
  const now = Timestamp.now();
  
  // Handle date conversion
  let eventDate: Timestamp | null = null;
  if (event.date) {
    try {
      console.log('Input date:', event.date);
      console.log('Date type:', typeof event.date);
      console.log('Is Timestamp:', event.date instanceof Timestamp);
      console.log('Has toDate:', 'toDate' in event.date);
      console.log('Has seconds:', 'seconds' in event.date);
      console.log('Has nanoseconds:', 'nanoseconds' in event.date);
      console.log('Date object properties:', Object.getOwnPropertyNames(event.date));
      
      // Handle the case where event.date is already a Timestamp
      if (event.date instanceof Timestamp) {
        console.log('Using instanceof Timestamp branch');
        eventDate = event.date;
      } else if (event.date instanceof Date) {
        console.log('Using instanceof Date branch');
        eventDate = Timestamp.fromDate(event.date);
      } else if (typeof event.date === 'string') {
        console.log('Using string branch');
        const parsedDate = new Date(event.date);
        if (!isNaN(parsedDate.getTime())) {
          eventDate = Timestamp.fromDate(parsedDate);
        }
      } else if (typeof event.date === 'object' && event.date !== null) {
        // Handle Timestamp-like objects
        if ('seconds' in event.date && 'nanoseconds' in event.date) {
          console.log('Using Timestamp-like object branch (seconds/nanoseconds)');
          const seconds = Number(event.date.seconds);
          const nanoseconds = Number(event.date.nanoseconds);
          
          console.log('Converted seconds:', seconds);
          console.log('Converted nanoseconds:', nanoseconds);
          
          if (!isNaN(seconds) && !isNaN(nanoseconds)) {
            try {
              eventDate = new Timestamp(seconds, nanoseconds);
              console.log('Successfully created Timestamp:', eventDate);
            } catch (error) {
              console.error('Error creating Timestamp:', error);
            }
          } else {
            console.error('Invalid seconds or nanoseconds:', { seconds, nanoseconds });
          }
        } else if ('toDate' in event.date && typeof event.date.toDate === 'function') {
          console.log('Using Timestamp-like object branch (toDate)');
          try {
            const dateObj = event.date.toDate();
            if (dateObj instanceof Date && !isNaN(dateObj.getTime())) {
              eventDate = Timestamp.fromDate(dateObj);
              console.log('Successfully converted using toDate:', eventDate);
            }
          } catch (error) {
            console.error('Error converting toDate:', error);
          }
        }
      }
      
      console.log('Resulting eventDate:', eventDate);
      if (eventDate) {
        console.log('eventDate.toDate():', eventDate.toDate());
      }
    } catch (error) {
      console.error('Error converting date:', error);
      eventDate = null;
    }
  } else {
    console.log('No date provided');
  }
  
  const event_data = {
    id: event.id || '',
    urlFriendlyId: event.urlFriendlyId || '',
    title: event.title || event.eventTitle || '',
    description: event.description || event.eventDescription || '',
    isOnline: event.isOnline || false,
    location: event.location || event.eventLocation || '',
    locationLink: '',
    isSingleDay: true,
    date: eventDate,
    startTime: event.startTime || event.eventTime || '',
    endTime: event.endTime || '',
    isRepeatedEvent: false,
    repeatedDaysOfWeek: [],
    isFree: event.isFree || false,
    price: event.price || 0,
    pricePerTicket: event.pricePerTicket || 0,
    quantity: event.quantity || 0,
    imageUrl: '',
    meetingLink: event.meetingLink || '',
    skill: event.skill || 'beginner',
    isPublic: true,
    status: event.status || 'upcoming',
    attendeeCount: 0,
    attendees: [],
    organizerId: '',
    createdAt: now,
    updatedAt: now,
    tags: [],
    categories: [],
    maxCapacity: 0,
    feedback: [],
    isCancelled: event.isCancelled || false,
    stats: event.stats || {
      totalEarnings: 0,
      platformEarnings: 0,
      transactionCount: 0
    }
  };
  
  return event_data;
};

export const generateBookingConfirmationPdf = async (
  user: User,
  eventData: EventLike,
  orderInfo: PdfOrderInfo
): Promise<Buffer> => {
  console.log('Input eventData:', eventData);
  console.log('Input eventData.date:', eventData.date);
  console.log('Input eventData.date type:', typeof eventData.date);
  console.log('Input eventData.date instanceof Timestamp:', eventData.date instanceof Timestamp);
  
  // First normalize the event data
  const event = normalizeEventData(eventData);
  
  console.log('Normalized event:', event);
  console.log('Normalized event.date:', event.date);
  console.log('Normalized event.date type:', typeof event.date);
  console.log('Normalized event.date instanceof Timestamp:', event.date instanceof Timestamp);
  
  // Format the date after normalization
  const formattedDate = formatEventDate(event.date, event.startTime);
  console.log('Formatted date:', formattedDate);
  
  // Generate QR code data with the formatted date
  const qrCodeData = `${event.id}
Event: ${event.title}
Ticket ID: ${orderInfo.ticketId || orderInfo.id}
Date: ${formattedDate}`;
  console.log('QR code data:', qrCodeData);
  
  const qrCodeDataUrl = await generateQrCodeDataUrl(qrCodeData);

  const documentDefinition = getTicketTemplate(user, event, orderInfo, qrCodeDataUrl);

  return new Promise<Buffer>((resolve, reject) => {
    try {
      const pdfDoc = pdfMake.createPdf(documentDefinition);
      if (!pdfDoc || typeof pdfDoc.getBuffer !== 'function') {
        throw new Error('PDF generation failed: invalid PDF document');
      }
      
      pdfDoc.getBuffer((buffer: Buffer | null) => {
        if (!buffer) {
          reject(new Error('PDF generation failed: empty buffer'));
        } else {
          resolve(buffer);
        }
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const generateInvoicePdf = async (
  transaction: any,
  eventData: EventLike,
  user: User,
  organizer: User
): Promise<Buffer> => {
  const event = normalizeEventData(eventData);
  
  const qrCodeData = `Invoice ID: ${transaction.id}\nEvent: ${event.title}\nAmount: ${formatCurrency(
    event.price
  )}`;
  const qrCodeDataUrl = await generateQrCodeDataUrl(qrCodeData);

  const documentDefinition = getInvoiceTemplate(transaction, event, user, organizer, qrCodeDataUrl);

  return new Promise<Buffer>((resolve, reject) => {
    try {
      const pdfDoc = pdfMake.createPdf(documentDefinition);
      pdfDoc.getBuffer((buffer: Buffer) => {
        resolve(buffer);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadPdfToStorage = async (
  pdfBuffer: Buffer,
  userId: string,
  eventId: string,
  documentType: 'booking-confirmation' | 'invoice'
): Promise<string> => {
  try {
    // Validate inputs
    if (!pdfBuffer || pdfBuffer.length === 0) {
      throw new Error('Invalid PDF buffer');
    }
    if (!userId || !eventId) {
      throw new Error('Missing required parameters: userId or eventId');
    }

    const timestamp = new Date().getTime();
    const fileName = `${documentType}-${timestamp}.pdf`;
    // Store PDFs in user's directory
    const filePath = `users/${userId}/documents/${eventId}/${fileName}`;
    const storageRef = ref(storage, filePath);
    
    // Validate file size (5MB limit)
    if (pdfBuffer.length > 5 * 1024 * 1024) {
      throw new Error('PDF file size exceeds 5MB limit');
    }
    
    // Convert Buffer to Blob for upload
    // Use Uint8Array for better browser compatibility
    const uint8Array = new Uint8Array(pdfBuffer);
    const pdfBlob = new Blob([uint8Array], { type: 'application/octet-stream' });
    
    console.log('Uploading PDF:', {
      userId,
      eventId,
      documentType,
      fileSize: pdfBuffer.length,
      filePath,
      contentType: pdfBlob.type
    });
    
    // Upload PDF to Firebase Storage with metadata
    const metadata = {
      contentType: 'application/pdf',
      customMetadata: {
        userId,
        eventId,
        documentType,
        timestamp: timestamp.toString()
      }
    };
    
    await uploadBytes(storageRef, pdfBlob, metadata);
    console.log('PDF uploaded successfully to:', filePath);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);
    console.log('PDF download URL:', downloadURL);

    // Update event with booking confirmation PDF URL if it's a booking confirmation
    if (documentType === 'booking-confirmation') {
      await updateEventBookingConfirmationPdf(eventId, downloadURL);
    }

    return downloadURL;
  } catch (error) {
    console.error('Error uploading PDF:', error);
    if (error instanceof Error) {
      throw new Error(`Failed to upload PDF: ${error.message}`);
    }
    throw new Error('Failed to upload PDF: Unknown error');
  }
};
