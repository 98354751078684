import React from 'react';
import { useParams } from 'react-router-dom';
import { SEO } from '../../../components/SEO';
import { BlogPostContent } from '../components/BlogPostContent';
import { blogPosts } from '../data/blogPosts';

const BlogPostPage = () => {
    const { slug } = useParams();
    const post = blogPosts.find(p => p.slug === slug);

    if (!post) {
        return <div>Post not found</div>;
    }

    return (
        <div className="min-h-screen bg-gray-50">
            <SEO 
                title={`${post.title} | EventAtEase Blog`}
                description={post.summary}
            />
            
            {/* Hero Image */}
            <div className="w-full h-[400px] relative overflow-hidden bg-gray-200">
                <img 
                    src={post.imageUrl}
                    alt={post.title}
                    className="w-full h-full object-cover object-top"
                    onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.onerror = null; // Prevent infinite loop
                        console.error('Failed to load image:', post.imageUrl);
                        target.src = `${process.env.PUBLIC_URL}/placeholder-blog.jpg`; // You can add a placeholder image
                    }}
                />
                <div className="absolute inset-0 bg-black bg-opacity-40" />
            </div>

            {/* Post Content */}
            <div className="container mx-auto px-4 py-12">
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg -mt-20 relative z-10 p-8">
                    {/* Post Meta */}
                    <div className="flex items-center gap-4 text-sm text-gray-600 mb-8">
                        <span>{new Date(post.date).toLocaleDateString()}</span>
                        <span>•</span>
                        <span>{post.readTime}</span>
                        <span>•</span>
                        <span>By {post.author}</span>
                    </div>

                    <BlogPostContent 
                        title={post.title}
                        content={post.content}
                        sections={post.sections}
                    />
                </div>
            </div>
        </div>
    );
};

export default BlogPostPage;
