import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Card, CardContent, CardFooter, CardHeader } from "../../components/ui/Card"
import { Button } from "../../components/ui/Button"
import { Textarea } from "../../components/ui/Textarea"
import { Label } from "../../components/ui/Label"
import { RadioGroup, RadioGroupItem } from "../../components/ui/RadioGroup"
import { Checkbox } from "../../components/ui/Checkbox"
import { Send, AlertTriangle } from 'lucide-react'
import { Event } from '../../models/Event'
import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { useToast } from "../../components/ui/use-toast"

export const SendUpdateScreen: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [event, setEvent] = useState<Event | null>(null)
  const [message, setMessage] = useState('')
  const [urgency, setUrgency] = useState('normal')
  const [notificationMethods, setNotificationMethods] = useState({
    email: false,
    push: false,
  })

  const { toast } = useToast()

  useEffect(() => {
    const fetchEvent = async () => {
      if (id) {
        const fetchedEvent = await Event.findById(id)
        if (fetchedEvent) {
          setEvent(fetchedEvent)
        }
      }
    }
    fetchEvent()
  }, [id])

  const handleSendUpdate = async () => {
    if (!message.trim() || !event?.id) {
      return;
    }

    try {
      // Add message to the messages subcollection of the event
      await addDoc(collection(db, 'events', event.id, 'messages'), {
        content: message.trim(),
        type: 'UPDATE',
        urgency,
        notificationMethods,
        createdAt: new Date(),
        senderId: event.organizerId,
        recipientIds: [], // Will be populated by cloud function
      });
      
      // Clear the form
      setMessage('');
      setUrgency('normal');
      setNotificationMethods({ email: false, push: false });
      
      toast({
        description: "Update sent successfully to all attendees",
        variant: "default",
      });
    } catch (error) {
      console.error('Error sending update:', error);
      toast({
        description: "Failed to send update. Please try again.",
        variant: "destructive",
      });
    }
  }

  if (!event) {
    return <div>Loading...</div>
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-md mx-auto">
        <CardHeader>
          <h2 className="text-2xl font-bold text-purple-800">Send Update to Attendees</h2>
        </CardHeader>
        <CardContent className="space-y-4">
          <div>
            <Label htmlFor="message" className="text-purple-700">Message</Label>
            <Textarea
              id="message"
              placeholder="Type your update message here..."
              className="h-32 mt-1"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div>
            <Label className="text-purple-700">Urgency</Label>
            <RadioGroup 
              value={urgency} 
              onChange={(value: string) => setUrgency(value)}
              className="flex space-x-4 mt-1"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="normal" />
                <Label htmlFor="normal">Normal</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="urgent" />
                <Label htmlFor="urgent">Urgent</Label>
              </div>
            </RadioGroup>
          </div>
          <div>
            <Label className="text-purple-700">Notification Methods</Label>
            <div className="flex space-x-4 mt-1">
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="email" 
                  checked={notificationMethods.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                    setNotificationMethods(prev => ({ ...prev, email: e.target.checked }))
                  }
                />
                <Label htmlFor="email">Email</Label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox 
                  id="push" 
                  checked={notificationMethods.push}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                    setNotificationMethods(prev => ({ ...prev, push: e.target.checked }))
                  }
                />
                <Label htmlFor="push">Push</Label>
              </div>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex-col items-start space-y-4">
          <div className="flex items-center text-sm text-gray-500">
            <AlertTriangle className="h-4 w-4 mr-1 text-yellow-500" />
            This will notify {event.attendeeCount} attendees of "{event.title}"
          </div>
          <Button 
            className="w-full bg-purple-600 hover:bg-purple-700 text-white"
            onClick={handleSendUpdate}
          >
            <Send className="h-4 h-4 mr-2" />
            Send Update
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}