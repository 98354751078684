import React from 'react';
import { BlogSection, BlogSubSection } from '../types';

interface BlogPostContentProps {
    title: string;
    content?: string;
    sections?: BlogSection[];
}

export const BlogPostContent: React.FC<BlogPostContentProps> = ({ title, content, sections }) => {
    const renderSubSection = (subsection: BlogSubSection) => (
        <div className="mb-6">
            <h3 className="text-2xl font-semibold text-gray-800 mb-3">{subsection.title}</h3>
            <div className="prose prose-lg max-w-none">
                <p className="text-gray-600">{subsection.content}</p>
                {subsection.items && (
                    <ul className="list-disc list-inside mt-2">
                        {subsection.items.map((item, index) => (
                            <li key={index} className="text-gray-600 mb-1">{item}</li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );

    const renderSection = (section: BlogSection) => (
        <div className="mb-10">
            {section.title && (
                <h2 className="text-3xl font-bold text-gray-900 mb-4">{section.title}</h2>
            )}
            <div className="prose prose-lg max-w-none">
                <p className="text-gray-600 mb-6">{section.content}</p>
            </div>
            {section.subsections && (
                <div className="ml-4">
                    {section.subsections.map((subsection, index) => (
                        <div key={index}>{renderSubSection(subsection)}</div>
                    ))}
                </div>
            )}
        </div>
    );

    return (
        <article className="max-w-4xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-8">{title}</h1>
            {content && (
                <div className="prose prose-lg max-w-none mb-8">
                    <p className="text-gray-600">{content}</p>
                </div>
            )}
            {sections && sections.map((section, index) => (
                <div key={index}>{renderSection(section)}</div>
            ))}
        </article>
    );
};
